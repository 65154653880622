@mixin nav-btn {
  margin: 0 1rem;
  padding: 1rem 2rem; // Adjust padding to ensure text is centered
  border: none; // Consider removing the border for a cleaner look
  background-color: rgba(0,0,0,.05);
  color: black;
  border-radius: 6px;
  font-size: 1.4rem;
font-family: "Fellix-Bold";
  font-weight: bold;
  cursor: pointer; // Make it clear the button is clickable
  transition: all 0.3s ease; // Smooth transition for hover effects

  &:hover {
    background-color: darken(#142e4a, 10%); // Darken the button on hover for a subtle effect
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Add a shadow for depth
    transform: translateY(-2px); // Slight raise effect
  }
}

@mixin signup-nav-btn {
  @include nav-btn; // Extend the basic button styles
  background-image: linear-gradient(to right, #142e4a, #142e4a); // Gradient background
  color: #fff;
  border: none; // Remove border for a cleaner look
  font-weight: 800;

  &:hover {
    background-image: linear-gradient(to right, darken(#142e4a, 10%), darken(#142e4a, 10%));
    // Reapply the hover styles from nav-btn mixin
  }
}


@mixin cta-btn {
  width: 22rem;
  font-weight: 700;
  color: #fff;
  background-image: #133a88;
  background-color: $dark-blue;
  border-radius: 4px;
  padding: 1.2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

// ! headings ========================================
@mixin blue-heading {
  text-transform: uppercase;
  color: #4c7bd7;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 1.8rem;
}

@mixin sub-heading {
  color: #333333;
  font-weight: 700;
  font-size: 3.4rem;
  margin: 4rem 0;
}

@mixin heading-content {
  font-size: 1.8rem;
  color: #5d5c6d;
  line-height: 26px;
  font-weight: 500;
}

// ! media queries ========================================
@mixin mobile {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin tablet-landscape-width {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin tablet-landscape-sm {
  @media (min-height: 200px) {
    @content;
  }
}

@mixin tablet-landscape-md {
  @media (min-height: 500px) {
    @content;
  }
}

@mixin big-tablet {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin big-desktop {
  @media (min-width: 1700px) {
    @content;
  }
}

@mixin medium {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width: 1080px) {
    @content;
  }
}
