canvas {
    border-radius: 11px;
}

.race-start-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 500px;
    font-family: 'Fellix-SemiBold';
  }
  
  .race-start-container h1 {
    font-size: 2.3rem;
    margin-bottom: 20px;
    font-family: "QuickStarter";

  }
  
  .race-start-container p {
    font-size: 1.6rem;
    margin-bottom: 30px;
    line-height: 1.5;
    font-family: 'Fellix-SemiBold';

  }
  
  .race-start-container button {
    font-family: "QuickStarter";
    background-color: #ff3b3b;
    color: #fff;
    font-size: 1.5rem;
    border: none;
    border-radius: 5px;
    padding: 20px 30px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .race-start-container button:hover {
    background-color: #ca0000;
  }
  
  


.race-game-page-container {
    
    height: 100vh;
    width: 100vw;
    font-family: "QuickStarter";
    background: #ED213A;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #93291E, #ED213A);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #93291E, #ED213A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  }

  .race-game-wrapper {
    margin: auto;
    max-width: 800px;
    position: relative;
    padding-top: 5px;
  }
  
  .race-game-container {
 
    width: 800px;
    height: 600px;

    border-radius: 12px;
    position: absolute;

  }


  
  .race-question-container {
    max-width: 600px;
    display: flex;
    position: relative;
    transform: translate(0px,0px);
    flex-direction: column;
    align-items: flex-start;    justify-content: center;
    padding: 4px 16px;
    margin: auto;
    margin-bottom: 4px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    font-family: "Fellix-Bold";
    font-size: 2rem;
    
  }

  .rotate-180 {
    transform: rotate(180deg);
  }

  .race-question-box {
    display: flex;
    flex-direction: column;
    padding: 3px 10px;
    font-size: 20px;
    margin: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    background-color: #fff;
    font-family: "Fellix-Bold";
    background-color: rgb(46, 46, 46);
    color: white;
    width: 100px;
    height: 40px;
    text-align: center;
    justify-content: center;
    align-items: center;
   margin-left: 118px;
  }

  .race-key-image {
    margin-left: 27px;
    height: 25px;
  }
  
  .race-option {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    margin: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    background-color: #fff;
    font-family: "Fellix-Bold";
    width: 75px;
    height: 50px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  
  
  .race-option:hover {
    background-color: #f0f0f0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .race-option-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    font-family: "Fellix-Bold";
   
  }

  .race-incorrect {
    background-color: rgb(201, 58, 58) !important;
    color: white;

  }
  
  .race-correct {
    background-color: rgb(28, 201, 77) !important;   
    color: white; 

  }
  

  .race-white-dash {
    width: 5px;
    height: 50px;
    background-color: white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  
.popup-text {
    background-color: white;
    border-radius: 8px;
    height: 55px;
    position: absolute;
    right: 10px;
    top: 260px;
    font-family: "QuickStarter";
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.desktop-only {
    display: block;
  }

  
  .race-score-container {
    position: absolute;
    top: 160px;
    left: 1px;
    font-size: 24px;
    font-family: "QuickStarter";
    background-color: white;
    border-radius: 8px;
    padding: 12px 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    display: none;
  }

  .race-distance-display {
    position: absolute;
   
    top: 160px;
    right: 10px;
    font-size: 14px;
    font-family: "QuickStarter";
    background-color: white;
    border-radius: 8px;
    color:rgb(201, 58, 58);
    padding: 12px 8px;
    padding-top: 25px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    min-width: 210px;
    height: 60px;
  }

  .second-car-img {
    position: absolute;
    right: 5px;
    top: 12px;
width: 20px;
margin-left: 9px;

  }
  
  .race-speed-container {
    position: absolute;
    top: 160px;
    left: 10px;
    font-size: 24px;
    font-family: "QuickStarter";
    background-color: white;
    border-radius: 8px;
    padding: 12px 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }

.race-left-button,
.race-right-button {
  position: absolute;
  bottom: 35px;
  font-size: 18px;
  font-weight: bold;
  padding: 24px 24px;
  background-color: #333;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s;
}

.race-left-button:hover,
.race-right-button:hover {
  background-color: #262626;
}

.race-left-button:active,
.race-right-button:active {
  background-color: #000000;
}

.race-left-button {
  right: 120px;
}

.race-right-button {
  right: 12px;
}

.race-left-arrow {
    right: 145px;
    position: absolute;
    bottom: 3px;

  }

  .race-right-arrow {
    right: 35px;
    position: absolute;
    bottom: 3px;
  }

.race-game-over {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 2rem;
    text-align: center;
    border-radius: 1rem;
    z-index: 1000;
  }
  
  .race-game-over h1 {
    margin-bottom: 1rem;
    font-family: "QuickStarter";
  }
  
  .race-game-over button {
    font-family: "QuickStarter";
    background-color: #ff3b3b;
    color: #fff;
    font-size: 1.5rem;
    border: none;
    border-radius: 5px;
    padding: 20px 30px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  .race-game-over button:hover {
    background-color: #ca0000;
  }
  

  .answer-question-msg {
    position: absolute;
    top: 235px;
    left: 10%;
    width: 80%;
    
    font-size: 18px;
    text-align: center;
    font-family: "QuickStarter";
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }



  @media only screen and (max-width: 767px) {
    /* CSS rules for mobile devices */
    .race-question-container {
        max-width: 600px;
        display: flex;
        position: relative;
        transform: translate(0px,0px);
    }

    .answer-question-msg {
        position: absolute;
        top: 175px;
        left: 10%;
        width: 80%;
        font-size: 18px;
        text-align: center;
        font-family: "QuickStarter";
        background-color: white;
        border-radius: 8px;
        padding: 8px 4px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      }



    .desktop-only {
        display: none;
      }


    .race-game-container {
        width: 100vw;
        height: 100vh;
      }
      
      canvas {
        width: 100%;
        height: 70%;
      }
      
      .race-start-container {
        width: 90%;
        max-width: none;
        font-family: 'Fellix-SemiBold';
      }
      
      .race-start-container h1 {
        font-size: 1.8rem;
      }
      
      .race-start-container p {
        font-size: 1.2rem;
      }
      
      .race-start-container button {
        font-size: 1.2rem;
        padding: 10px 20px;
      }
      
     
      
      .race-left-button {
        bottom: -670px;
        right: 55%;
        padding: 57px;
       }
       
       .race-right-button {
         bottom: -670px;
         right: 10%;
         padding: 57px;

       }

       .race-speed-container {
        position: absolute;
        top: 125px;
        left: 10px;
        font-size: 18px;
        font-family: "QuickStarter";
        background-color: white;
        border-radius: 8px;
        padding: 8px 4px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      }

     
      .race-distance-display {
        top: 125px;
      }

      .race-score-container {
        display: none;
      }

  }

  @media only screen and (max-width: 1023px) {
    /* CSS rules for tablets */
    .race-game-container {
        width: 100%;
        height: 500px;
      }

      .race-question-container {
        align-items: center;
      }

      .race-question-box {
        margin: auto;
      }
    
      canvas {
        width: 100%;
        height: 500px;
      }
    
      .race-start-container {
        width: 80%;
        max-width: 500px;
        font-family: 'Fellix-SemiBold';
      }
    
      .race-start-container h1 {
        font-size: 2.3rem;
      }
    
      .race-start-container p {
        font-size: 1.6rem;
      }
    
      .race-start-container button {
        font-size: 1.5rem;
        padding: 20px 30px;
      }
    
   
  }


  
