.App {
  overflow-x: hidden;
}

.simple-checkout {
  background-color: #eef9fe;
  position: relative;
  margin: 4% -150% 1%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 4rem 1rem;
  min-height: 405px;

  &__discount-wrapper {
    margin: 20px auto;
  }

  &__sub-heading-discount {
    text-align: center;
  }

  &__call-banner {
    color: white;
    padding: 1.5rem 1rem;
    font-size: 2rem;
    font-weight: 800;
    background-image: linear-gradient(-90deg, rgb(57, 106, 252), rgb(41, 72, 255));
    text-align: center;
  }
  &__faq {
    font-weight: 800;
    margin-top: 17px;
    text-align: left;
    align-self: start;
    margin-left: 8rem;
    cursor: pointer;
    background-color: white;
    padding: 8px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 #cbdbdf;
    // &--answer {
    //   animation: slideIn 0.5s forwards;
    //   -webkit-animation: slideIn 0.5s forwards;
    // }
  }

  &__faq-2 {
    font-weight: 800;
    text-decoration: underline;
    margin-top: 10px;
    text-align: center;
    background-color: white;
    padding: 8px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 #cbdbdf;
    cursor: pointer;
  }

  &__faq-3 {
    font-weight: 800;
    text-decoration: underline;
    margin-top: 30px;
    text-align: center;
    background-color: white;
    padding: 8px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 #cbdbdf;
    cursor: pointer;
  }

  &__time {
    width: 100px;
    background-color: white;
    padding: 8px 12px;
    font-weight: 800;
  }

  &__grade {
    width: 168px;
    background-color: white;
    padding: 8px 12px;
    border: 1px solid #d8dadf;
    font-weight: 800;
  }

  &__appointment-dates {
    display: flex;
    flex-direction: column;
    padding: 2rem;

    &--show-more {
      font-weight: 800;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__appointment-date {
    padding: 1rem 2rem;
    border: 2px solid #4182e4;
    color: #4182e4;
    background-color: white;
    text-align: center;
    margin: 1rem 0;
    border-radius: 5px;
    font-weight: 800;
    cursor: pointer;
    &:hover {
      background-color: #4182e4;
      color: white;
    }
  }

  &__time-select-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 12px;
  }

  &__time-select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    width: 230px;
    font-weight: 800;
    &--arrow {
      position: relative;
      display: inline-flex;
    }
    &--arrow:after {
      content: '';
      position: absolute;
      right: 12px;
      top: 12px;
      border-width: 6px 0 6px 6px;
      border-style: solid;
      border-color: transparent #333;
      pointer-events: none;
      transform: rotate(90deg);
    }
  }

  &__grade-select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    width: 230px;
    font-weight: 800;
    &--arrow {
      position: relative;
      display: inline-flex;
      margin-left: 21px;
      margin-right: 2rem;
    }
    &--arrow:after {
      content: '';
      position: absolute;
      right: 12px;
      top: 12px;
      border-width: 6px 0 6px 6px;
      border-style: solid;
      border-color: transparent #333;
      pointer-events: none;
      transform: rotate(90deg);
    }
  }

  &__datetimezone-wrapper {
    position: relative;
    margin-top: 20px;
    margin-bottom: 4rem;
  }
  &__datetimezone-wrapper-2 {
    position: relative;
    margin-top: 20px;
    margin-bottom: 1rem;
  }

  &__other-datetimezone {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 20px;
    right: -70px;
    background-color: white;

    border-radius: 5px;
    z-index: 2;
  }

  &__datetimezone {
    color: #f15b49;
    padding: 7px 12px;
    width: 90px;
    margin: 0px 5px;
    text-align: center;
    font-weight: 800;
    cursor: pointer;
    border-radius: 5px;
    &--other {
      cursor: pointer;
      color: #f15b49;
      font-weight: 800;
      padding: 1rem 2rem;
      border-radius: 5px;
      &:hover {
        background-color: #f15b49;
        color: white;
        box-shadow: 5px 5px 10px 0 #d3d3d3;
      }
    }
  }

  &__section-container {
    max-width: 700px;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 405px;
  }

  &__daytime-identifier-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &__times {
    margin-bottom: 30px;
  }

  &__extra-notes {
    resize: none;
    height: 90px;
    font-size: 1.5rem;
    padding: 1.5rem 2rem;
    border: 1px solid #d8dadf;
    border-radius: 4px;
    margin: 1rem 2rem 1rem;
    max-width: 530px;
    min-width: 530px;
  }

  &__extra-notes-2 {
    resize: none;
    height: 70px;
    font-size: 1.5rem;
    padding: 1.5rem 2rem;
    border: 1px solid #d8dadf;
    border-radius: 4px;
    margin: 1rem 2rem 4rem;
    max-width: 430px;
    min-width: 430px;
  }

  &__ld--row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
  }
  &__ld--label {
    background-color: white;
    color: #333;
    border: 2px solid #f15b49;
    padding: 7px 12px;
    width: 108px;
    margin: 0px 10px;
    text-align: center;
    font-weight: 800;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.3s;

    &:hover {
      background-color: #f15b49;
      color: white;
      padding: 7px 12px;
      width: 108px;
      margin: 0px 10px;
      text-align: center;
      font-weight: 800;
      cursor: pointer;
      border-radius: 4px;
    }
  }

  &__motivated {
    background-color: white;
    color: #333;
    border: 2px solid #f15b49;
    padding: 7px 12px;
    width: 150px;
    height: 50px;
    margin: 0px 10px;
    text-align: center;
    font-weight: 800;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.3s;

    &:hover {
      background-color: #f15b49;
      color: white;
      padding: 7px 12px;
      width: 150px;
      height: 50px;
      margin: 0px 10px;
      text-align: center;
      font-weight: 800;
      cursor: pointer;
      border-radius: 4px;
    }
  }

  &__gets-along {
    background-color: white;
    color: #333;
    border: 2px solid #f15b49;
    padding: 7px 12px;
    width: 210px;
    height: 50px;
    margin: 0px 10px;
    text-align: center;
    font-weight: 800;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.3s;

    &:hover {
      background-color: #f15b49;
      color: white;
      padding: 7px 12px;
      width: 210px;
      height: 50px;
      margin: 0px 10px;
      text-align: center;
      font-weight: 800;
      cursor: pointer;
      border-radius: 4px;
    }
  }

  &__datetime-select {
    margin: 0 3rem;
    min-width: 200px;
    font-size: 20px;
    text-align-last: center;
    border-radius: 8px;
    min-height: 49px;
    border: 1px solid #d8dadf;
    background: white;
  }

  &__review-item-container {
    padding: 12px 8px;
    background-color: white;
    border-radius: 4px;
    margin: 1rem;
    box-shadow: 5px 5px 4px 1px #cbdbdf;
  }

  &__review-identifier {
    font-weight: bold;
  }

  &__modalized {
    background-color: #eef9fe;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    min-height: 337px;
  }

  &__start-container {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 85rem;
    text-transform: uppercase;
    padding: 2rem;

    &--modalize {
      width: 80%;
      margin: auto;
      margin-top: 11%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 85rem;
      text-transform: uppercase;
    }
  }

  &__start-container-backslide {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 85rem;
    text-transform: uppercase;
    animation: fadeIn, slideFromBack;
    -webkit-animation: fadeIn, slideFromBack;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;

    &--modalize {
      width: 80%;
      margin: auto;
      margin-top: 11%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 85rem;
      text-transform: uppercase;
      animation: fadeIn, slideFromBack;
      -webkit-animation: fadeIn, slideFromBack;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
    }
  }

  &__left-side {
    display: flex;
    flex-direction: column;
  }

  &__popout-text {
    font-size: 35px;
    color: #f26522;
    font-family: 'GothamBold';
    font-weight: bolder;
  }

  &__heading {
    margin-top: 1rem;
    margin-bottom: 0.4rem;
    font-family: 'GothamBold';
    font-size: 18px;
  }

  &__heading-2 {
    margin-top: 50px;
    margin-bottom: 0.4rem;
    font-family: 'GothamBold';
    font-size: 18px;
  }

  &__sub-heading {
    max-width: 500px;
  }

  &__right-side {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__date {
    color: #f26522;
  }

  &__flavor-text2 {
    text-transform: none;
  }

  &__start-button {
    display: flex;
    flex-direction: row;

    justify-content: center;
    background: linear-gradient(180deg, #f26931, #f15b49, #ee3f7a);
    color: white;
    padding: 2rem 1rem;
    margin: 0.5rem 0;
    border-radius: 4px;
    width: 100%;
    text-transform: none;
    cursor: pointer;
    box-shadow: 5px 5px 4px 1px #cbdbdf;
    &--text {
      margin-left: 1rem;
      font-weight: bold;
      font-size: 25px;
    }
  }

  &__slider-container {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    &--modalize {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      margin-top: 6.5%;
    }
  }

  &__slider-container-backslide {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    animation: fadeIn, slideFromBack;
    -webkit-animation: fadeIn, slideFromBack;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    &--modalize {
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: fadeIn, slideFromBack;
      -webkit-animation: fadeIn, slideFromBack;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      margin: auto;
      margin-top: 6.5%;
    }
  }

  &__slider-container-frontslide {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    animation: fadeIn, slideFromFront;
    -webkit-animation: fadeIn, slideFromFront;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    &--modalize {
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: fadeIn, slideFromFront;
      -webkit-animation: fadeIn, slideFromFront;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      margin: auto;
      margin-top: 6.5%;
    }
  }

  &__slider-query {
    margin-bottom: 4.5rem;
    font-size: 25px;
  }

  &__slider {
    -webkit-appearance: none;
    width: 63%;

    height: 15px;
    border-radius: 10px;
    background: linear-gradient(90deg, #f26931, #f15b49, #ee3f7a);
    box-shadow: 0 2px 10px 0 #858f92;
    outline: none;
    opacity: 1;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    margin: 2rem 0 5.5rem;
    @include mobile {
      min-width: 200px;
      max-width: 200px;
    }
    @include tablet-portrait {
      min-width: 300px;
      max-width: 300px;
    }
    @include desktop {
      min-width: 400px;
      max-width: 400px;
    }
    @include big-desktop {
      min-width: 500px;
      max-width: 500px;
    }
  }

  &__slider-value {
    margin: 40px auto 20px auto;
    text-align: center;
    font-weight: 700;
    font-size: 28px;
  }

  &__slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background: linear-gradient(135deg, rgb(175, 214, 210), #fff, rgb(175, 214, 210));
    box-shadow: $box-shadow;
    cursor: pointer;
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    bottom: 0;
    width: 80%;
  }

  &__button {
    display: flex;
    background: linear-gradient(-90deg, #f26931, #f15b49, #ee3f7a);
    color: white;
    padding: 2rem 4rem;
    font-weight: 700;
    text-transform: uppercase;
    // letter-spacing: 0.1rem;
    border-radius: 5px;
    cursor: pointer;
    margin: 0.75rem 6rem;
    min-width: 269px;
    justify-content: space-around;
  }

  &__button-back {
    display: flex;
    align-items: left;
    align-self: start;
    color: gray;
    background-color: white;
    border: 1px solid gray;
    padding: 10px 20px;
    font-weight: 700;
    // letter-spacing: 0.1rem;
    border-radius: 5px;
    cursor: pointer;
    margin: 0.75rem 6rem;
    margin-left: 8rem;
    min-width: 100px;
    justify-content: space-around;
  }

  &__subject-container {
    display: flex;
    flex-direction: column;
    width: 25%;
    align-items: center;

    &--modalize {
      display: flex;
      flex-direction: column;
      width: 25%;
      align-items: center;
      margin-top: 2%;
    }
  }

  &__subject-container-backslide {
    display: flex;
    flex-direction: column;
    width: 25%;
    align-items: center;
    animation: fadeIn, slideFromBack;
    -webkit-animation: fadeIn, slideFromBack;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;

    &--modalize {
      display: flex;
      flex-direction: column;
      width: 25%;
      align-items: center;
      animation: fadeIn, slideFromBack;
      -webkit-animation: fadeIn, slideFromBack;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      margin-top: 2%;
    }
  }

  &__subject-container-frontslide {
    display: flex;
    flex-direction: column;
    width: 25%;
    align-items: center;
    animation: fadeIn, slideFromFront;
    -webkit-animation: fadeIn, slideFromFront;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;

    &--modalize {
      display: flex;
      flex-direction: column;
      width: 25%;
      align-items: center;
      animation: fadeIn, slideFromFront;
      -webkit-animation: fadeIn, slideFromFront;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      margin-top: 2%;
    }
  }

  &__subject-query {
    align-self: center;
    text-align: center;
    font-weight: 700;
    font-size: 21px;
  }
  &__subject-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 754.156px;
    min-width: 380.5px;
    margin: 0 0 5rem;
  }
  &__subject {
    background-color: #fff;
    border-radius: 7px;
    text-align: center;
    padding: 3rem;
    margin: 5rem 2rem 0;
    width: 15rem;
    height: 13rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &--highlight {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #f6f7f9;
      width: 15rem;
      height: 13rem;
      margin: 5rem 2rem 0;
      border: 4px solid #f26931;
      padding: 2.6rem;
      border-radius: 7px;
      cursor: pointer;
    }
  }

  &__select {
    background-color: #fff;
    border-radius: 7px;
    text-align: center;
    padding: 3rem;
    margin: 5rem 1rem 0;
    width: 9rem;
    height: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &--highlight {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #f6f7f9;
      width: 9rem;
      height: 7rem;
      margin: 5rem 1rem 0;
      border: 4px solid #f26931;
      padding: 2.6rem;
      border-radius: 7px;
      cursor: pointer;
    }

    &--label {
      width: 100%;
    }
  }

  &__secure-text {
    margin: 2rem auto;
    font-size: 2rem;
    padding: 0rem 2rem;
  }

  &__prompt-container {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    &--modalize {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 5%;
    }

    & h2 {
      margin: 2rem auto;
      text-align: center;
    }
  }

  &__prompt-container-backslide {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    animation: fadeIn, slideFromBack;
    -webkit-animation: fadeIn, slideFromBack;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    &--modalize {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      animation: fadeIn, slideFromBack;
      -webkit-animation: fadeIn, slideFromBack;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      margin-top: 5%;
    }
  }

  &__prompt-container-frontslide {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    animation: fadeIn, slideFromFront;
    -webkit-animation: fadeIn, slideFromFront;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    &--modalize {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      animation: fadeIn, slideFromFront;
      -webkit-animation: fadeIn, slideFromFront;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      margin-top: 5%;
    }
  }

  &__prompt {
    margin-bottom: 3rem;
    font-size: 20px;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    text-align: center;
    &--question {
      margin-top: 2.5rem;
    }
    &--popout-text {
      color: #f26522;
    }
    &--blurb {
      @include mobile {
        min-width: 300px;
        max-width: 300px;
      }
      @include tablet-portrait {
        min-width: 600px;
        max-width: 600px;
      }
      @include desktop {
        min-width: 800px;
        max-width: 800px;
      }
      @include big-desktop {
        min-width: 1000px;
        max-width: 1000px;
      }
    }
  }

  &__form-container-backslide {
    animation: fadeIn, slideFromBack;
    -webkit-animation: fadeIn, slideFromBack;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
  }
  &__form-container-frontslide {
    animation: fadeIn, slideFromFront;
    -webkit-animation: fadeIn, slideFromFront;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
  }

  &__form-heading {
    text-align: center;
    width: 50%;
    margin: auto auto 3rem;
  }
  &__form {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
  }

  &__form-span {
    margin: auto;
    font-weight: 500;
    margin-top: 5px;
    text-align: center;
  }

  &__form-input {
    height: 5rem;
    font-size: 1.5rem;
    padding: 1.5rem 2rem;
    border: 1px solid #d8dadf;
    border-radius: 4px;
    margin: 1rem 2rem 1rem;
    max-width: 350px;
    min-width: 350px;

    &--row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 10px 0px 0px;
      width: 370px;
    }

    &--row-2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 30px 0px 0px;
      width: 370px;
    }

    &--heading {
      font-family: 'GothamBold';
      font-size: 18px;
      max-width: 140px;
      min-width: 140px;
      margin-left: 20px;
    }

    &--child-name {
      height: 5rem;
      font-size: 1.5rem;
      padding: 1.5rem 2rem;
      border: 1px solid #d8dadf;
      border-radius: 4px;
      margin: 1rem 2rem 1rem;
      max-width: 170px;
      min-width: 170px;
    }

    &--child-grade {
      height: 5rem;
      font-size: 1.5rem;
      padding: 1.5rem 2rem;
      border: 1px solid #d8dadf;
      border-radius: 4px;
      margin: 1rem 2rem 1rem;
      max-width: 100px;
      min-width: 100px;
    }
  }

  &__form-submit {
    height: 5rem;
    font-size: 1.5rem;
    padding: 1.5rem 2rem;
    border: 1px solid #d8dadf;
    border-radius: 4px;
    margin: 1rem 2rem 1rem;
    max-width: 350px;
    min-width: 350px;
    color: white;
    font-weight: 800;
    background: linear-gradient(-90deg, #f26931, #f15b49, #ee3f7a);
    cursor: pointer;
  }

  &__icon-right {
    margin-right: 8px;
  }

  &__datetime-picker {
    display: flex;
    flex-direction: column;
  }
  &__datetime-picker-backslide {
    display: flex;
    flex-direction: column;

    justify-content: space-around;
    animation: fadeIn, slideFromBack;
    -webkit-animation: fadeIn, slideFromBack;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
  }
  &__datetime-picker-frontslide {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    animation: fadeIn, slideFromFront;
    -webkit-animation: fadeIn, slideFromFront;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
  }
  &__datetime-heading {
    margin-bottom: 1rem;
    align-self: center;
    font-size: 25px;
  }
  &__datetime-subheading {
    margin: auto;
    font-size: 15px;
  }
  &__datetime-select-wrapper {
    text-align: center;
    margin: 5rem 0;
  }
  &__datetime-select {
    margin: 0 3rem;
    min-width: 200px;
    font-size: 20px;
    text-align-last: center;
    border-radius: 8px;
    min-height: 49px;
    border: 1px solid #d8dadf;
    background: white;
  }

  &__end-container {
    display: flex;
    flex-direction: column;
  }
  &__end-container-backslide {
    display: flex;
    flex-direction: column;
    animation: fadeIn, slideFromBack;
    -webkit-animation: fadeIn, slideFromBack;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
  }
  &__end-container-frontslide {
    display: flex;
    flex-direction: column;
    animation: fadeIn, slideFromFront;
    -webkit-animation: fadeIn, slideFromFront;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
  }
  &__end-heading {
    align-self: center;
    margin-bottom: 4rem;
  }
  &__end-textbox {
    width: 100%;
    height: 50px;
    margin-bottom: 4rem;
    border: 1px solid #d8dadf;
    border-radius: 8px;
    padding: 3rem 2.5rem;
    font-size: 2rem;
  }

  &__true-end-container {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &--modalize {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 5%;
    }
  }

  &__true-end-container-backslide {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    animation: fadeIn, slideFromBack;
    -webkit-animation: fadeIn, slideFromBack;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    &--modalize {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      animation: fadeIn, slideFromBack;
      -webkit-animation: fadeIn, slideFromBack;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      margin-top: 5%;
    }
  }

  &__true-end-container-frontslide {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    animation: fadeIn, slideFromFront;
    -webkit-animation: fadeIn, slideFromFront;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    &--modalize {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      animation: fadeIn, slideFromFront;
      -webkit-animation: fadeIn, slideFromFront;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      margin-top: 5%;
    }
  }

  &__true-end {
    margin-bottom: 3rem;
    font-size: 20px;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 80rem;
    &--blurb {
      margin-bottom: 3rem;
    }
    &--popout-text {
      color: #f26522;
    }
  }

  &__dropdown-arrow {
    position: absolute;
    left: 49%;
    top: 43.75%;
  }

  &__drag-drop {
    &--item {
      padding: 2rem;
    }
  }
}

.selected {
  background-color: #f15b49;
  color: white;
  padding: 7px 12px;
  width: 108px;
  margin: 0px 10px;
  text-align: center;
  font-weight: 800;
  cursor: pointer;
  border-radius: 4px;
}

.selected-motivated {
  background-color: #f15b49;
  color: white;
  padding: 7px 12px;
  width: 150px;
  height: 50px;
  margin: 0px 10px;
  text-align: center;
  font-weight: 800;
  cursor: pointer;
  border-radius: 4px;
}
.selected--gets-along {
  background-color: #f15b49;
  color: white;
  padding: 7px 12px;
  width: 210px;
  height: 50px;
  margin: 0px 10px;
  text-align: center;
  font-weight: 800;
  cursor: pointer;
  border-radius: 4px;
}

.selected-dtz {
  background-color: #f15b49;
  color: white;
  padding: 7px 12px;
  width: 90px;
  margin: 0px 5px;
  text-align: center;
  font-weight: 800;
  cursor: pointer;
  border-radius: 4px;
}

.selected-date {
  background-color: #4182e4;
  color: white;
}

.simple-checkout-modal {
  width: 70%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #eef9fe;
  border-radius: 8px;
  position: fixed;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: $box-shadow;
  padding: 3rem;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideFromBack {
  0% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideFromFront {
  0% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}

//make mobile show two subject cards per row
@media (max-width: 867px) {
  #form-button,
  #form-button-no {
    max-width: 269px;
  }
  .simple-checkout {
    &__button-container {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-around;
      position: relative;
      bottom: 0;
      align-items: center;
    }
    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__button {
      margin-bottom: 2rem;
    }
  }
}

@media (max-width: 467px) {
  .simple-checkout {
    &__subject-wrapper {
      justify-content: space-around;
    }
    &__form-input {
      max-width: 300px;
      min-width: 300px;
    }

    &__button-back {
      margin-left: 1rem;
      margin-bottom: 2rem;
    }

    &__faq {
      margin-left: 1rem;
    }

    &__heading-2 {
      max-width: 300px;
    }

    &__sub-heading {
      max-width: 300px;
    }

    &__extra-notes {
      min-width: 300px;
      max-width: 300px;
    }

    &__extra-notes-2 {
      min-width: 300px;
      max-width: 300px;
    }

    &__ld--row {
      flex-direction: column;
    }
    &__ld--label {
      margin-bottom: 20px;
      width: 210px;
      height: 50px;
      line-height: 32px;
      vertical-align: middle;
    }

    &__motivated {
      margin-bottom: 20px;
      width: 210px;
      height: 50px;
      line-height: 32px;
      vertical-align: middle;
    }

    &__faq-3 {
      max-width: 87%;
    }

    &__gets-along-mobile {
      margin-top: 0;
      margin-bottom: 17px;
    }
    &__datetimezone-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      margin-top: 10px;
    }
    &__datetimezone-wrapper-2 {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      margin-top: 10px;
    }

    &__datetimezone {
      margin-bottom: 10px;
      padding: 12px 14px;
      width: 120px;
    }

    &__faq-2 {
      max-width: 87%;
    }
    &__review-item-container {
      width: 90%;
      text-align: center;
      line-height: 1.8;
    }
    &__review-item {
      max-width: 87%;
      text-align: center;
    }
  }
}

// @media (max-width: 1315px) {
//   .simple-checkout {
//     &__subject-wrapper {
//       flex-wrap: wrap;
//       &--modalize {
//         flex-wrap: wrap;
//       }
//     }
//   }
// }

@font-face {
  font-family: GothamBlack;
  src: url('../../assets/Gotham-Black.otf') format('opentype');
}
@font-face {
  font-family: GothamBold;
  src: url('../../assets/Gotham-Bold.otf') format('opentype');
}

// @keyframes slideIn {
//   0% {
//     margin-top: -10px;
//   }
//   100% {
//     margin-top: 0;
//   }
// }
// @-webkit-keyframes slideIn {
//   0% {
//     margin-top: -10px;
//   }
//   100% {
//     margin-top: 0;
//   }
// }
