.memory-game {
  height: 100vh;
  position: relative;
  font-family: "Fellix-SemiBold";
  //   background: linear-gradient(-45deg, #e73c7e, #6947ff, #ff4b2b);
  //   background-size: 340% 170%;
  //   animation: gradient 10s ease infinite;
  overflow: hidden;
  padding: 0;

  &__turns {
    position: relative;
    margin: 20px auto;
    padding: 10px 20px;
    font-family: "Fellix-Bold";
    background-color: white;
    font-size: 2rem;
    width: 125px;
    border-radius: 8px;
    z-index: 100;
  }
  &__settings {
    margin: 2rem;
    &--text {
      text-align: center;
      margin-bottom: 1rem;
      font-size: 3rem;
      font-family: "Fellix-Bold";
    
    }
    &--input-container {
      display: flex;
      flex-direction: column;
      width: 80%;
      overflow: auto;
      margin: auto;
      padding: 20px 0;
    }
    &--input {
      width: 300px;
      padding: 23px 12px;
      text-align: center;
      border-radius: 8px;
      margin: 10px auto;
      font-family: "Fellix-Semibold";
      font-size: 1.8rem;
      transition: 0.2s;

      &:focus {
        outline: none;
        padding: 28px 12px;
        font-size: 1.8rem;

        font-family: "Fellix-Bold";
        background-color: rgb(213, 255, 243);
        border: 2px solid rgb(46, 184, 145);
      }
    }
  }

  &__board {
    padding-top: 30px;
    height: 100%;
  }

  &__space-stars {
    position: fixed;
    top: 50%;
    left: 50%;
    height: 1px;
    width: 1px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: -24vw -44vh 2px 2px #fff, 38vw -4vh 0px 0px #fff,
      -20vw -48vh 1px 2px #fff, -39vw 38vh 3px 1px #fff,
      -42vw -11vh 0px 3px #fff, 12vw 15vh 3px 3px #fff, 42vw 6vh 3px 2px #fff,
      -8vw 9vh 0px 2px #fff, 34vw -38vh 1px 0px #fff, -17vw 45vh 3px 1px #fff,
      22vw -36vh 3px 2px #fff, -42vw 1vh 1px 0px #fff, -20vw -34vh 2px 2px #fff,
      18vw -14vh 0px 0px #fff, -10vw -38vh 1px 2px #fff, -19vw 18vh 3px 1px #fff,
      -32vw -21vh 0px 3px #fff, 12vw 35vh 3px 3px #fff, 32vw 16vh 3px 2px #fff,
      -8vw -19vh 0px 2px #fff, -34vw 12vh 1px 0px #fff, 17vw -15vh 3px 1px #fff,
      32vw 36vh 3px 2px #fff, 2vw -41vh 1px 0px #fff;
    animation: zoom 40s alternate infinite;
  }

  &__wave-wrapper {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #015871;
    z-index: 2;
  }

  &__waves {
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg)
      repeat-x;
    position: absolute;
    z-index: 2;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);

    &:nth-of-type(2) {
      z-index: 2;
      top: -175px;
      animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
        swell 7s ease -1.25s infinite;
      opacity: 1;
    }
  }

  &__dolphin {
    width: 100px;
    position: absolute;
    bottom: 0;
    right: 50%;
    z-index: 1;
    animation-name: dolphin;

    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: dolphin;
    -ms-animation-name: dolphin;
    -ms-animation-duration: 5s;

    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
  }
  &__shark {
    width: 100px;
    position: absolute;
    bottom: 50%;
    left: 0;
    z-index: 1;
    animation-name: shark;

    animation-duration: 9s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: shark;
    -ms-animation-name: shark;
    -ms-animation-duration: 9s;

    -webkit-animation-duration: 9s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
  }

  &__marlin {
    width: 100px;
    position: absolute;
    bottom: 0;
    right: 50%;
    z-index: 1;
  }
  &__shrimp {
    width: 100px;
    position: absolute;
    top: 0%;
    left: 17%;
    z-index: 1;
    animation-name: shrimp;

    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: shrimp;
    -ms-animation-name: shrimp;
    -ms-animation-duration: 10s;

    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
  }

  &__octopus {
    width: 100px;
    position: absolute;
    top: 0%;
    right: 20%;
    z-index: 1;
    animation-name: octopus;

    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: octopus;
    -ms-animation-name: octopus;
    -ms-animation-duration: 5s;

    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
  }

  &__fish {
    width: 100px;
    position: absolute;
    bottom: 20%;
    right: 0;
    z-index: 1;
    animation-name: fish;

    animation-duration: 17s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: fish;
    -ms-animation-name: fish;
    -ms-animation-duration: 17s;

    -webkit-animation-duration: 17s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
  }

  &__rex {
    width: 200px;
    position: absolute;
    bottom: 10%;
    left: 7%;
    z-index: 1;
    animation-name: rex;

    animation-duration: 17s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: rex;
    -ms-animation-name: rex;
    -ms-animation-duration: 17s;

    -webkit-animation-duration: 17s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
  }

  &__terry {
    width: 200px;
    position: absolute;
    top: -2%;
    left: -8%;
    z-index: 1;
    animation-name: terry;

    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: terry;
    -ms-animation-name: terry;
    -ms-animation-duration: 10s;

    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
  }

  &__bronto {
    width: 200px;
    position: absolute;
    top: -4%;
    right: -2%;
    z-index: 1;
    animation-name: bronto;

    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: bronto;
    -ms-animation-name: bronto;
    -ms-animation-duration: 5s;

    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
  }

  &__cleo {
    width: 200px;
    position: absolute;
    bottom: 10%;
    right: 0;
    z-index: 1;
    animation-name: cleo;

    animation-duration: 17s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: cleo;
    -ms-animation-name: cleo;
    -ms-animation-duration: 17s;

    -webkit-animation-duration: 17s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
  }

  &__moon {
    width: 100px;
    position: absolute;
    left: 12%;
    top: 10%;
    z-index: 1;
    animation-name: fish;

    animation-duration: 17s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: fish;
    -ms-animation-name: fish;
    -ms-animation-duration: 17s;

    -webkit-animation-duration: 17s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
  }

  &__earth {
    width: 100px;
    position: absolute;
    bottom: 3%;
    left: 23%;
    z-index: 1;
    animation-name: fish;

    animation-duration: 17s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: fish;
    -ms-animation-name: fish;
    -ms-animation-duration: 17s;

    -webkit-animation-duration: 17s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
  }

  &__satellite {
    width: 100px;
    position: absolute;
    bottom: 14%;
    left: 41%;
    z-index: 1;
    animation-name: fish;

    animation-duration: 17s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: fish;
    -ms-animation-name: fish;
    -ms-animation-duration: 17s;

    -webkit-animation-duration: 17s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
  }

  &__space-ship {
    width: 100px;
    position: absolute;
    bottom: -10%;
    right: 40%;
    z-index: 1;
    animation-name: space-ship;

    animation-duration: 17s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: space-ship;
    -ms-animation-name: space-ship;
    -ms-animation-duration: 17s;

    -webkit-animation-duration: 17s;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
  }

  &__space-bg {
    background: linear-gradient(-45deg, #0e0e0e, #000000, #00000077, #0e0e0e);
    background-size: 340% 170%;
    animation: gradient 10s ease-out infinite;
    overflow: hidden;
  }
  &__ocean-bg {
    background: linear-gradient(-45deg, #7fdbff, #4778ff, #2b6eff);
    background-size: 340% 170%;
    animation: gradient 10s ease infinite;
    overflow: hidden;
  }

  &__dino-bg {
    background: linear-gradient(-45deg, #3ce797, #6947ff, #3ffa8d);
    background-size: 340% 170%;
    animation: gradient 10s ease infinite;
    overflow: hidden;
  }

  &__row-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
  }

  &__new-game {
    padding: 23px 23px;
    font-size: 1.7rem;
    background-color: #5132ed;
    font-family: "Fellix-Bold";
    border: 2px solid white;
    color: white;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.53);
    min-width: 170px;
    max-width: 250px;
    border-radius: 7px;
    text-align: center;
    margin: auto;
    z-index: 9;

    &:hover {
      background-color: #2e10c2;
      font-family: "Fellix-Bold";
      cursor: pointer;
    }
    &:active {
      color: rgb(198, 129, 255);
      border: 2px solid rgb(198, 129, 255);
      font-family: "Fellix-Bold";
      cursor: pointer;
    }
  }

  &__theme {
    padding: 8px 12px;
    background-color: white;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.53);
    width: 70px;
    border-radius: 7px;
    font-family: "Fellix-Bold";
    text-align: center;
    margin: auto;
    z-index: 9;

    &:hover {
      transform: translateY(-2px);
      font-family: "Fellix-Bold";
      cursor: pointer;
    }
  }

  &__card-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    max-width: 1000px;
    margin: auto;
    margin-top: 40px;
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 160px;
    height: 140px;
    // border: 2px solid #fff;
    border-radius: 7px;
    background-color: white;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.53);
    margin: auto;
    z-index: 10;
    margin-bottom: 30px;

    &:hover {
      cursor: pointer;
    }

    & img {
   
      width: 160px;
      height: 140px;
      border-radius: 7px;
      display: block;
      margin: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      background-repeat: no-repeat;
      background-size: auto;
    }
    &--front {
      transform: rotateY(90deg);
      position: absolute;
      transition: all ease-in 0.2s;
    }
    &--back {
      position: absolute;
      transform: rotateY(0deg);
      width: 80px;
      transition: all ease-in 0.2s;
      transition-delay: 0.2s;
    }

    &--word-text {
      font-size: 2.3rem;
      font-family: "Fellix-Bold";
      margin: auto;
    }

    &--front &--flipped {
      transform: rotateY(0deg);
      transition-delay: 0.2s;
    }

    &--front &--back {
      transform: rotateY(90deg);
      transition-delay: 0s;
    }
  }

  @media screen and (max-width: 500px) {
  height: 120vh;

  &__settings {
    margin:0.5rem;
    &--input-container{
    width: 100%;
    }
  }
  
    &__card-grid {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2px;
      margin-bottom: 100px;
    }
    &__card {
      position: relative;
      width: 120px;
      height: 100px;
      margin-bottom: 5px;
      &--back {
        width: 120px;
        height: 100px;
      }

      &--word-text {
        font-size: 1.95rem;
        font-family: "Fellix-Bold";
      }

      & img {
   
        width: 120px;
        height: 100px;
      }
    }
    &__octopus {
      right: 0%;
      top: -4%;
    }
    &__shrimp {
      left: -25%;
      top: -4%;
    }

    &__rex {
      right: 0%;
      bottom: 7%;
      width: 120px;
    }
    &__terry {
      left: -25%;
      top: -2%;
      width: 120px;
    }

    &__bronto {
      right: -4%;
      top: -7%;
      width: 120px;
    }
    &__cleo {
      right: -4%;
      bottom: 5%;
      width: 120px;
    }

    &__moon {
      left: 72%;
    }

    &__earth {
      left: 14%;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes zoom {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(0.7);
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

@keyframes dolphin {
  0% {
    transform: rotate(0deg) translate(-400px, 0px);
  }
  50% {
    transform: rotate(70deg) translate(0px, -130px) scale(1.1);
  }

  100% {
    transform: rotate(140deg) translate(600px, 0px);
  }
}

@-ms-keyframes dolphin {
  0% {
    -ms-transform: rotate(0deg) translate(-400px, 0px);
  }
  50% {
    -ms-transform: rotate(70deg) translate(0px, -130px) scale(1.1);
  }
  100% {
    -ms-transform: rotate(140deg) translate(600px, 0px);
  }
}

@-webkit-keyframes dolphin {
  0% {
    -webkit-transform: rotate(0deg) translate(-400px, 0px);
  }
  50% {
    -webkit-transform: rotate(70deg) translate(0px, -130px) scale(1.1);
  }
  100% {
    -webkit-transform: rotate(140deg) translate(600px, 0px);
  }
}

@keyframes shark {
  0% {
    transform: rotate(0deg) translate(-170px, 0px);
  }
  50% {
    transform: rotate(10deg) translate(0px, 0px) scale(1.7);
  }

  100% {
    transform: rotate(10deg) translate(-170px, 0px);
  }
}

@-ms-keyframes shark {
  0% {
    -ms-transform: rotate(0deg) translate(-170px, 0px);
  }
  50% {
    -ms-transform: rotate(70deg) translate(7px, 0px) scale(1.9);
  }
  100% {
    -ms-transform: rotate(140deg) translate(-170px, 0px);
  }
}

@-webkit-keyframes shark {
  0% {
    -webkit-transform: rotate(0deg) translate(-170px, 0px);
  }
  50% {
    -webkit-transform: rotate(70deg) translate(7px, 0px) scale(1.9);
  }
  100% {
    -webkit-transform: rotate(140deg) translate(-170px, 0px);
  }
}

@keyframes octopus {
  0% {
    transform: rotate(-8deg) translate(-20px, 50px) scale(1);
    opacity: 1;
  }
  50% {
    transform: rotate(-17deg) translate(-30px, 50px) scale(1);
    opacity: 1;
  }

  100% {
    transform: rotate(-8deg) translate(-20px, 50px) scale(1);
    opacity: 1;
  }
}

@-ms-keyframes octopus {
  0% {
    -ms-transform: rotate(0deg) translate(100px, 0px) scale(1);
  }
  50% {
    -ms-transform: rotate(70deg) translate(101px, 1px) scale(1.2);
  }
  100% {
    -ms-transform: rotate(140deg) translate(100px, 0px) scale(1);
  }
}

@-webkit-keyframes octopus {
  0% {
    -webkit-transform: rotate(0deg) translate(100px, 0px) scale(0.5);
  }
  50% {
    -webkit-transform: rotate(70deg) translate(101px, 1px) scale(0.45);
  }
  100% {
    -webkit-transform: rotate(140deg) translate(100px, 0px) scale(0.5);
  }
}

@keyframes fish {
  0% {
    transform: rotate(0deg) translate(-40px, 0px);
  }
  50% {
    transform: rotate(10deg) translate(-10px, 0px) scale(1.2);
  }

  100% {
    transform: rotate(0deg) translate(-40px, 0px);
  }
}

@-ms-keyframes fish {
  0% {
    -ms-transform: rotate(0deg) translate(-40px, 0px);
  }
  50% {
    -ms-transform: rotate(70deg) translate(-10px, 0px) scale(1.2);
  }
  100% {
    -ms-transform: rotate(140deg) translate(-40px, 0px);
  }
}

@-webkit-keyframes fish {
  0% {
    -webkit-transform: rotate(0deg) translate(-40px, 0px);
  }
  50% {
    -webkit-transform: rotate(70deg) translate(-10px, 0px) scale(1.2);
  }
  100% {
    -webkit-transform: rotate(140deg) translate(-40px, 0px);
  }
}

@keyframes shrimp {
  0% {
    transform: rotate(8deg) translate(100px, 0px) scale(0.5);
  }
  50% {
    transform: rotate(17deg) translate(101px, 1px) scale(0.45);
  }

  100% {
    transform: rotate(8deg) translate(100px, 0px) scale(0.5);
  }
}

@-ms-keyframes shrimp {
  0% {
    -ms-transform: rotate(0deg) translate(100px, 0px) scale(0.5);
  }
  50% {
    -ms-transform: rotate(70deg) translate(101px, 1px) scale(0.45);
  }
  100% {
    -ms-transform: rotate(140deg) translate(100px, 0px) scale(0.5);
  }
}

@-webkit-keyframes shrimp {
  0% {
    -webkit-transform: rotate(0deg) translate(100px, 0px) scale(0.5);
  }
  50% {
    -webkit-transform: rotate(70deg) translate(101px, 1px) scale(0.45);
  }
  100% {
    -webkit-transform: rotate(140deg) translate(100px, 0px) scale(0.5);
  }
}

@keyframes rex {
  0% {
    transform: rotate(-10deg) translate(-20px, 0px);
  }
  50% {
    transform: rotate(-30deg) translate(-10px, 0px) scale(1.2);
  }

  100% {
    transform: rotate(-10deg) translate(-20px, 0px);
  }
}

@-ms-keyframes rex {
  0% {
    -ms-transform: rotate(-10deg) translate(-20px, 0px);
  }
  50% {
    -ms-transform: rotate(-30deg) translate(-10px, 0px) scale(1.2);
  }
  100% {
    -ms-transform: rotate(-10deg) translate(-20px, 0px);
  }
}

@-webkit-keyframes rex {
  0% {
    -webkit-transform: rotate(-10deg) translate(-20px, 0px);
  }
  50% {
    -webkit-transform: rotate(-30deg) translate(-10px, 0px) scale(1.2);
  }
  100% {
    -webkit-transform: rotate(-10deg) translate(-20px, 0px);
  }
}

@keyframes bronto {
  0% {
    transform: rotate(-8deg) translate(-20px, 50px) scale(1);
    opacity: 1;
  }
  50% {
    transform: rotate(-17deg) translate(-30px, 50px) scale(1.1);
    opacity: 1;
  }

  100% {
    transform: rotate(-8deg) translate(-20px, 50px) scale(1);
    opacity: 1;
  }
}

@-ms-keyframes bronto {
  0% {
    -ms-transform: rotate(0deg) translate(100px, 0px) scale(1);
  }
  50% {
    -ms-transform: rotate(70deg) translate(101px, 1px) scale(1.1);
  }
  100% {
    -ms-transform: rotate(140deg) translate(100px, 0px) scale(1);
  }
}

@-webkit-keyframes bronto {
  0% {
    -webkit-transform: rotate(0deg) translate(100px, 0px) scale(1);
  }
  50% {
    -webkit-transform: rotate(70deg) translate(101px, 1px) scale(1.1);
  }
  100% {
    -webkit-transform: rotate(140deg) translate(100px, 0px) scale(1);
  }
}

@keyframes cleo {
  0% {
    transform: rotate(0deg) translate(-40px, 0px);
  }
  50% {
    transform: rotate(23deg) translate(-10px, 0px) scale(1.2);
  }

  100% {
    transform: rotate(0deg) translate(-40px, 0px);
  }
}

@-ms-keyframes cleo {
  0% {
    -ms-transform: rotate(0deg) translate(-40px, 0px);
  }
  50% {
    -ms-transform: rotate(70deg) translate(-10px, 0px) scale(1.2);
  }
  100% {
    -ms-transform: rotate(140deg) translate(-40px, 0px);
  }
}

@-webkit-keyframes cleo {
  0% {
    -webkit-transform: rotate(0deg) translate(-40px, 0px);
  }
  50% {
    -webkit-transform: rotate(70deg) translate(-10px, 0px) scale(1.2);
  }
  100% {
    -webkit-transform: rotate(140deg) translate(-40px, 0px);
  }
}

@keyframes terry {
  0% {
    transform: rotate(8deg) translate(100px, 0px) scale(1);
  }
  50% {
    transform: rotate(17deg) translate(101px, 1px) scale(1.1);
  }

  100% {
    transform: rotate(8deg) translate(100px, 0px) scale(1);
  }
}

@-ms-keyframes terry {
  0% {
    -ms-transform: rotate(0deg) translate(100px, 0px) scale(1);
  }
  50% {
    -ms-transform: rotate(70deg) translate(101px, 1px) scale(1.1);
  }
  100% {
    -ms-transform: rotate(140deg) translate(100px, 0px) scale(1);
  }
}

@-webkit-keyframes terry {
  0% {
    -webkit-transform: rotate(0deg) translate(100px, 0px) scale(1);
  }
  50% {
    -webkit-transform: rotate(70deg) translate(101px, 1px) scale(1.1);
  }
  100% {
    -webkit-transform: rotate(140deg) translate(100px, 0px) scale(1);
  }
}

@keyframes space-ship {
  0% {
    transform: rotate(-10deg) translate(10vw, 10vh) scale(0.5);
  }
  50% {
    transform: rotate(-55deg) translate(90vw, -25vh) scale(1);
  }

  100% {
    transform: rotate(-67deg) translate(150vw, -85vh) scale(1.3);
  }
}

@-ms-keyframes space-ship {
  0% {
    -ms-transform: rotate(0deg) translate(100px, 0px) scale(0.5);
  }
  50% {
    -ms-transform: rotate(70deg) translate(101px, 1px) scale(0.45);
  }
  100% {
    -ms-transform: rotate(140deg) translate(100px, 0px) scale(0.5);
  }
}

@-webkit-keyframes space-ship {
  0% {
    -webkit-transform: rotate(0deg) translate(100px, 0px) scale(0.5);
  }
  50% {
    -webkit-transform: rotate(70deg) translate(101px, 1px) scale(0.45);
  }
  100% {
    -webkit-transform: rotate(140deg) translate(100px, 0px) scale(0.5);
  }
}
