.science-page {
  &__subjects-flex-wrapper {
    @include desktop {
      display: flex;
      padding: 0 10rem;
    }

    @include big-desktop {
      padding: 0 25rem;
    }
  }
}

// ! science programs
.science-programs {
  padding: 6rem 4rem;

  @include tablet-portrait {
    padding: 10rem 8rem;
  }

  &__01 {
    @include desktop {
      padding: 5rem 10rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include big-desktop {
      padding: 5rem 25rem 0;
    }
  }

  &__img {
    width: 100%;

    img {
      width: 100%;
      border-radius: 7px;
    }

    @include desktop {
      width: 40%;
      margin-right: 8rem
    }
  }

  &__number {
    @include blue-heading;
    margin: 5rem 0 2rem;

    @include desktop {
      margin-top: 0;
    }

    @include big-desktop {
      font-size: 2.5rem;
    }
  }

  &__header {
    @include sub-heading;
    margin-top: 0;
    margin-bottom: 2rem;

    @include big-desktop {
      font-size: 5rem;
    }
  }

  &__program-content {
    @include big-desktop {
      font-size: 2.5rem;
    }
  }

  &__content {
    @include heading-content;
    line-height: unset;
    font-size: 1.5rem;

    @include desktop {
      width: 50%;
      margin-left: 4rem;
    }
  }
}

// ! subjects
.science-subjects {
  @include desktop {
    width: 90%;
  }

  &__subjects-wrapper {
    padding: 2rem 4rem;

    @include tablet-portrait {
      padding: 2rem 8rem;
    }
  }

  &__subject-card {
    margin-bottom: 5rem;

    @include desktop {
      margin-bottom: 3rem;
    }
  }

  &__subject-card-icon {
    height: 5rem;
    margin: 2rem 0;

    img {
      height: 100%;
    }

    @include big-desktop {
      height: 7rem;
    }
  }

  &__subject-card-heading {
    @include sub-heading;
    font-size: 2rem;
    margin: 1rem 0;

    @include big-desktop {
      font-size: 2.75rem;
    }
  }

  &__subject-card-content {
    @include heading-content;

    @include big-desktop {
      font-size: 2.5rem;
    }
  }

  &__img-wrapper {
    padding: 2rem 4rem;

    @include tablet-portrait {
      padding: 2rem 8rem;
    }

    @include desktop {
      width: 100%;
      padding: 2rem 4rem;
      // margin-top: 4rem;
    }
  }

  &__number {
    @include blue-heading;
    margin-bottom: 4rem;

    @include big-desktop {
      font-size: 2.5rem;
    }
  }

  &__header {
    @include sub-heading;

    @include big-desktop {
      font-size: 5rem;
    }
  }

  &__img-container {
    position: relative;
    height: 45rem;

    @include tablet-portrait {
      height: 72rem;
    }

    @include desktop {
      height: 50rem;
    }
  }

  &__img02,
  &__img03,
  &__img04 {
    border-radius: 7px;

    img {
      width: 100%;
      border-radius: 7px;
    }
  }

  &__img02 {
    position: absolute;
    width: 75%;

    @include tablet-portrait {
      width: 60%;
    }

    @include desktop {
      width: 60%;
    }
  }

  &__img03 {
    width: 40%;
    position: absolute;
    top: 40px;
    right: 0;

    @include tablet-portrait {
      right: 20px;
    }
  }

  &__img04 {
    width: 95%;
    position: absolute;
    bottom: 0;
    right: 0;

    @include tablet-portrait {
      width: 75%;
      right: 70px;
    }

    @include desktop {
      bottom: -40px;
      width: 70%;
    }

    @include big-desktop {
      bottom: -280px;
      right: 100px;
    }
  }
}

// ! careers
.careers {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;

  @include tablet-portrait {
    padding: 4rem 8rem;
  }

  @include big-desktop {
    padding: 4rem 25rem;
    margin-top: 30rem;
  }

  &__header {
    @include sub-heading;
    text-align: center;
  }

  &__card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include desktop {
      flex-direction: row;
      justify-content: center;
    }
  }

  &__card {
    width: 80%;
    overflow: hidden;
    margin-bottom: 5rem;
    border-radius: 7px;
    box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;

    @include tablet-portrait {
      width: 60%;
    }

    @include desktop {
      width: 30%;
      height: 52rem;
      margin-bottom: unset;
      margin: 1rem 3rem;
    }

    @include big-desktop {
      height: 60rem;
    }
  }

  &__card-content-wrapper {
    padding: 0 2rem 5rem;
  }

  &__card-img {
    width: 100%;
    margin-bottom: 3rem;

    img {
      width: 100%;
    }
  }

  &__card-heading {
    @include sub-heading;
    font-size: 2.5rem;
  }

  &__card-content {
    @include heading-content;
  }
}