// !---------------------------------- OG HEADER -------------------------------------------------------------
//!Overlay for when a modal is active
.header-overlay {
  opacity: 1;

  background-image: linear-gradient(to bottom right, rgb(54, 79, 101), rgb(79, 87, 93));

  .main-number,
  .fa-caret-down {
    opacity: 0.3;
  }

  input {
    opacity: 0.3;
  }

  .footer-nav-container,
  .social-phones {
    opacity: 0.5;
  }

  .hide-image {
    opacity: 0.05 !important;
  }
}


//! HEADER SECTION
.header {
  position: relative;

  // ------------------------------------ Contact Section ---------------------------------
  &__contact-section {
    background-image: $reverse-gradient;
    color: $secondary-text-color;
    height: 13rem;
    width: 100%;
  }

  &__contact-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__call-wrapper,
  &__email-wrapper,
  &__g-star-wrapper {
    padding: 0.8rem;
  }

  &__g-star-wrapper {
    i {
      margin: 0 0.3rem;
    }
  }

  &__phone,
  &__email {
    color: $secondary-text-color;
    font-size: $header-font-s;
    font-weight: 700;

    i {
      font-size: $header-font-s;
      font-weight: 900;
      margin-right: 7px;
    }
  }

  // -------------------------- Nav Conatiner ---------------------------------
  &__nav-container {
    display: flex;
    align-items: center;
    position: relative;
    height: 10.5rem;
    width: 100%;
    padding-left: 3rem;

    &--landing-page {
      display: flex;
      align-items: center;
      position: relative;
      height: 10.5rem;
      width: 100%;
      padding-left: 3rem;
      justify-content: flex-start;
    }

    // Main Nav
  }

  &__logo {
    width: 20.7rem;
    margin-right: 3rem;
    z-index: 30;
    padding-bottom: 0.4rem;

    img {
      width: 100%;
    }

    &--hide {
      opacity: 0.2;
    }
  }

  // ---------------------------- Account Settings -------------------------------------
  &__account-options {
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    min-width: 250px;
    font-family: "Fellix-SemiBold";


  }

  &__account-options-avatar {
    margin-left: 1rem;
    font-size: 2rem;
  }

  &__drop-down-icon {
    margin-left: 0.5rem;
  }

  &__user-options-wrapper {
    text-align: center;
    position: absolute;
    top: 75px;
    right: 5px;
    z-index: 11;
    background: #f0f4f8; /* Lighter background for better readability */
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Softer shadow for subtlety */
    border: 1px solid #8c98a7; /* Add a border for defined edges */
  }
  
  &__user-options {
    li {
      margin: 0.75rem 0; /* Adjusted spacing */
      padding: 0.5rem 1rem; /* Padding for better touch targets */
      border-radius: 12px; /* Keep rounded corners for style */
      transition: background-color 0.3s ease; /* Transition for interactive feedback */
    }
    li:hover, li:focus {
      background-color: #e7effd; /* Interactive feedback on hover/focus */
      cursor: pointer; /* Change cursor to indicate clickable */
    }
  }
  

  

  &__account-settings-link {
    min-width: 200px;

    margin: 10px auto !important;
    background-color: #ffffff;
    border-radius: 2px;
    padding: 12px 12px;
    box-shadow: 1px 3px 5px rgb(191, 191, 191);
    a {
      color: #333;
      cursor: pointer;
      font-family: 'Fellix-SemiBold' !important;
      font-weight: 700;
      font-size: 1.4rem !important;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: #2a3eb0;
        text-decoration: underline;
      }
    }
  }

  &__downloads-link {
    min-width: 200px;

    margin: 10px auto !important;
    background-color: #ffffff;
    border-radius: 2px;
    padding: 12px 12px;
    box-shadow: 1px 3px 5px rgb(191, 191, 191);
    a {
      color: #333;

      font-family: 'Fellix-SemiBold' !important;
      font-weight: 700;
      font-size: 1.4rem !important;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: #2a3eb0;
        text-decoration: underline;
      }
    }
  }

  &__sign-out-link {
    cursor: pointer;
    margin-top: 20px !important;
    transition: 0.2s ease-in-out;
    font-size: 1rem;

    p {
      color: $main-blue;
      font-family: "Fellix-SemiBold";
      font-weight: 700;
      font-size: 1rem;
      text-decoration: underline;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  &__manage-billing {
    p {
      font-family: 'DM Sans';
      font-weight: 700;
      font-size: 1rem;
      cursor: pointer;
      margin-bottom: 3rem;
    }

    margin-bottom: 2rem;
  }

  &__account-upgrade-button {
    background: linear-gradient(-45deg, #ffffff, #ffffff, #ffffff);
    color: #051057;
    border-radius: 6px;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-family: "Fellix-Bold";
    padding: 12px 25px;
    border: 2px solid #051057;
    transition: all 0.2s ease-in;
    margin-bottom: 20px;
    &:hover {
      background: #051057;
      font-family: "Fellix-Bold";
      color: #fff;
      border: 2px solid #04092d;
      cursor: pointer;
    }
  }
}

// !------------------- MISC AFTER MAIN HEADER -----------------
.user-icon {
  margin-right: 5px;
}

.caret-down-icon {
  margin-left: 5px;
}

.top-section.hide-image {
  opacity: 0.2;
}

.nav-link.nested {
  margin-left: 5%;
}

// !----------------------- MAIN NAV -------------------------------

.main-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  &__login-button-wrapper {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;

    &--hidden {
      display: none;
    }
  }

  &__cta-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__link {
    align-self: center;
    height: 100%;
    text-decoration: none;
    text-transform: uppercase;
    color: $gray-c;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-right: 4.8rem;
  }

  &__responsive-login-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    padding: 0 2rem 2rem;
    overflow-y: scroll;

    .main-nav__link {
      margin: 0;
      width: 100%;
      color: #383838;
      font-size: 1.8rem;
      padding: 1.5rem 2rem;
      font-weight: 700;
      height: unset;
    }

    .main-nav__login-btn,
    .main-nav__sign-up-btn {
      width: 100%;
      text-align: left;
      color: #383838;
      font-size: 1.8rem;
      padding: 2rem 0;
      background-color: #ffffff;
      border: none;
      margin-bottom: 0;
      width: 205px;
    }

    .main-nav__login-btn {
      margin-bottom: 0;
      padding: 3rem 0 4rem 2rem;
      border-bottom: 1px solid #d7dae0;
      font-family: 'DM Sans';
    }

    .main-nav__sign-up-btn {
      margin-left: 0;
      padding: 3rem 0 4rem 2rem;
    }

    .main-nav__nav-btn {
      text-transform: uppercase;
      border-bottom: 1px solid #d7dae0;
    }

    .nav-dropdown {
      margin-right: 0;
      border-bottom: 0.5px solid grey;
      border-radius: 12px;

      i {
        padding-right: 2rem;
      }

      a {
        display: block;
        margin: 0;
        width: 100%;
        text-align: left;
        color: #383838;
        font-size: 1.6rem;
        padding: 1rem 2rem;
      }
    }

    .nav-link {
      margin: 0;
      width: 100%;
      text-align: left;
      color: #383838;
      font-size: 1.8rem;
      padding-bottom: 0;
      height: 7.5rem;

    }
  }

  &__login-container {
    display: flex;
    flex-direction: column;
    right: 0;
    left: 0;
    background-color: #ffffff;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    &--modal {
      background-color: $gray-a;
      opacity: 0;
    }

    a {
      height: 5.1rem;
    }

    .nav-link {
      height: 7.5rem;
    }

    .phone {
      a {
        transition: all 0.2s ease-in-out;
        margin-top: 2.8rem;
      }
    }
  }

  &__login-btn {
    margin-left: 0;
    background-color: rgba(0,0,0,.1);
    padding: 1rem 2rem;
    border-radius: 6px;

    width: 100%;
    font-family: "Fellix-Bold";
    color: rgb(37, 37, 37);
    font-weight: 700;
    font-size: 1.6rem;
    transition: all 0.3s ease-in-out;
    width: 130px;

    &:hover {
      background-color: darken(rgba(0,0,0,.1), 10%); // Darken the button on hover for a subtle effect
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Add a shadow for depth
      transform: translateY(-2px); // Slight raise effect
    }
  }

  &__sign-up-btn {
    @include signup-nav-btn;
    width: 130px;
  }
}

// ! ----------- MISC AFTER MAIN NAV -----------------

.main-links {
  display: flex;
  align-items: center;
}

.phone-dropdown-container {
  display: flex;
  flex-direction: column;
  position: relative;
  
  a {
    transition: all 0.2s ease-in-out;
  }

  a:hover {
    text-decoration: underline;
  }

  .phone {
    width: 25rem;
    transition: all 0.2s ease-in-out;

    a {
      transition: all 0.2s ease-in-out;
    }

    .main-number {
      padding: 0;
      width: 30rem;
    }
  }
}

// ! ------------ PHONE ------------------

.phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #133a88;
  font-size: $header-font-l;
  width: 253px;
  height: 65px;
  padding-right: 25px;
  transition: all 0.2s ease-in-out;

  a {
    color: #133a88;
    font-size: $header-font-l;
    font-weight: 700;
    margin-right: 0;
    transition: all 0.2s ease-in-out;
  }
}

.phone-options {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60%;
  height: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out, visibility 0.4s ease-in-out;
  z-index: 11;
  background: #f0f4f8; /* Lighter background for better readability */
  border-radius: 4px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Softer shadow for subtlety */
  border: 1px solid #8c98a7; /* Add a border for defined edges */


  &--active {
    opacity: 1;
    visibility: visible;


    &::before {
      opacity: 1; // Make the arrow visible
      visibility: visible; // Make the arrow appear
    }
  }

  :first-child {
    padding-top: 20px;
  }

  a {
    font-size: 1.6rem;
    font-weight: 700;
    color: $gray-a;
    padding: 10px;
  }
}

.phone-dropdown-container {
  .phone-options {
    height: 0;

    &--active,
    &--active__desktop {
      width: 255px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      transform: translateY(13px);
      padding: 10px 25px;
      z-index: 10;
      height: 15.5rem;
      opacity: 1;
      visibility: visible;


 
    }

    &--active {
      position: relative;
    }
  }
}

.phone-dropdown-container {
  i {
    cursor: pointer;
  }
}

.phone,
.phone-options {
  a {
    padding-left: 10px;
  }
}

//  ! --------------- Responsive Nav ------------------

.responsive-nav {
  display: flex;
  flex-direction: column;
  top: 20rem;
  right: 0;
  left: 0;
  max-height: 0;
  background-color: #ffffff;
  z-index: 10;
  overflow: hidden;
  transition: max-height 0.4s ease-out;

  &.active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 100vh;
    transition: max-height 0.7s ease-out;
  }
}

.responsive-dropdown-content.active {
  a {
    height: 4rem;
  }
}

.responsive-nav-dropdown {
  height: 7.5rem;

  a {
    padding: 0;
    margin: 0;
  }
}

// ! ----------------------------- END OF DEFAULT STYLES -----------------------------------------

// !----------------------------  Start of Media Queries  ----------------------------------------

@include mobile {
  .header {
    &__account-options {
      padding: 2rem;
      font-size: 1.6rem;
    }
    &__user-options {
      li {
        margin: 1rem 0 2rem;
      }
    }

    &__account-upgrade-button {
      margin: 2rem 0 0 0;
    }
  }
}

@include tablet-portrait {
  .header {
    &__account-options {
      font-size: 1.6rem;
      padding: 24px 2rem;
    }

    &__user-options-wrapper {
      border-radius: 0;
    }

    &__user-options {
      li {
        margin: 1rem 0 2rem;
      }
    }

    &__account-settings-link {
      a {
        font-size: 1.6rem;
      }
    }

    &__downloads-link {
      a {
        font-size: 1.6rem;
      }
    }

    &__sign-out-link {
      cursor: pointer;

      p {
        font-size: 1.4rem;
      }
    }

    &__manage-billing {
      p {
        font-size: 1.8rem;
        margin-bottom: 2rem;
      }
    }

    &__account-upgrade-button {
      margin: 0;
    }
  }

  .main-nav {
    &__login-button-wrapper {
      width: 50%;
    }

    &__desktop-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  header section.nav-container {
    width: 84%;
    margin: 0 auto;
    padding: 1rem 0;

    .phone {
      padding: unset;
      transition: all 0.2s ease-in-out;

      .main-number {
        padding-left: 24px;
      }
    }
  }
}

@include big-tablet {
  .header {
    &__nav-container {
      justify-content: space-around;
    }
  }
}

// ---------- Tablet/Mobile Header Max-Width -------------------
@media (max-width: 1023px) {
  .main-nav {
    position: absolute;
    top: 55%;
    left: 0;
    height: 0;
    overflow: hidden;
  }

  // hamburger
  .hamburger-btn {
    display: block;
    position: absolute;
    top: 80%;
    right: 4%;
    width: 40px;
    height: 24px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 20;
  }

  .hamburger-btn .line {
    display: block;
    background: #383838;
    width: 30px;
    height: 3px;
    position: absolute;
    left: 50%;
    margin-left: -20px;
    border-radius: 50px;
    transition: all 0.4s;
    -o-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
  }

  .hamburger-btn .line.line-1 {
    top: 0;
  }

  .hamburger-btn .line.line-2 {
    top: 50%;
    margin-top: -2px;
    width: 19px;
    right: 26%;
    left: auto;
  }

  .hamburger-btn .line.line-3 {
    top: 20px;
  }

  .hamburger-btn:hover .line-1,
  .hamburger-btn:focus .line-1 {
    -ms-transform: translateY(-4px);
    -moz-transform: translateY(-4px);
    transform: translateY(-4px);
  }

  .hamburger-btn:hover .line-3,
  .hamburger-btn:focus .line-3 {
    -ms-transform: translateY(4px);
    -moz-transform: translateY(4px);
    transform: translateY(4px);
  }

  .hamburger-btn.active .line-1 {
    -ms-transform: translateY(25px) translateX(0) rotate(45deg);
    transform: translateY(25px) translateX(0) rotate(45deg);
    -webkit-transform: translateY(10px) translateX(0) rotate(45deg);
    -moz-transform: translateY(10px) translateX(0) rotate(45deg);
  }

  .hamburger-btn.active .line-2 {
    opacity: 0;
  }

  .hamburger-btn.active .line-3 {
    -ms-transform: translateY(-25px) translateX(0) rotate(-45deg);
    transform: translateY(-25px) translateX(0) rotate(-45deg);
    -webkit-transform: translateY(-10px) translateX(0) rotate(-45deg);
    -moz-transform: translateY(-10px) translateX(0) rotate(-45deg);
  }
}

// ---------- Desktop Header Min-Width -------------------

@media (min-width: 1024px) {
  .header {
    &__contact-section {
      padding: 0 3rem;
      height: 5.7rem;
    }

    &__phone-email-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__contact-info {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

@include desktop {
  .header {
    &__contact-section {
      padding: 0 9rem;
    }

    &__nav-container {
      padding: 0 8rem;
    }

    &__user-options-wrapper {
      position: absolute;
    }
  }

  .main-nav {
    width: 100%;

    &__desktop-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__login-container {
      flex-direction: row;
    }

    &__nav-items-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;

      .main-nav__desktop-container {
        width: 100%;
        display: flex;
        justify-content: center;

        .dropdown-container,
        .nav-link {
          margin: 0 2.5%;
        }

        .nav-dropdown {
          margin-right: unset;
        }

        .nav-link.nested {
          margin-left: 15%;
        }
      }
    }
  }

  .phone-dropdown-container {
    margin-right: 2.5rem;

    .phone {
      padding: 0;
      width: 100%;
      transition: all 0.2s ease-in-out;

      .main-number {
        padding-left: 0;
        width: 20rem;
      }
    }
  }

  .responsive-nav {
    display: none;
  }
}

@include big-desktop {
  .header {
    &__contact-section {
      padding: 0 14rem;
    }

    &__nav-container {
      padding: 0 13rem;
    }

    &__user-options-wrapper {
    }
  }
}
