.book-form {
  padding: 2rem 3rem;

  @include desktop {
    text-align: center;
  }

  &__header {
    color: #333333;
    font-weight: 700;
    font-size: 2.5rem;
    margin-bottom: 3rem;

    @include desktop {
      font-size: 3.4rem;
      margin-bottom: 5rem;
    }
  }

  &__form-wrapper {
    display: flex;
    flex-direction: column;

    @include desktop {
      margin: 0 1.5rem;
    }
  }

  &__first-row {
    display: flex;
  }

  &__input {
    font-size: 1.5rem;
    padding: 1.5rem 2rem;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid #d8dadf;
    width: 100%;
    margin-bottom: 1.5rem;

    &--name {
      margin-right: 1.5rem;
    }

    &--message {
      height: 14rem;
    }
  }

  &__cta-btn {
    @include cta-btn;
  }

  &__img {
    border-radius: 7px;
    display: none;

    @include desktop {
      display: unset;
      margin: 0 1.5rem;
    }
  }

  &__desktop-wrapper {
    @include desktop {
      display: flex;
      justify-content: center;
    }
  }
}
