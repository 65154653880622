.games__popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.games__popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.games__popup h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.games__popup p {
  font-size: 16px;
  margin-bottom: 20px;
}

.games__popup-close {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.games__popup-close:hover {
  background-color: #2980b9;
}


.scroll-to-top-button {
  position: fixed;
  bottom: 12.5rem;
  right: 2rem;
  background-color: #133a88;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 16px 17px;
  font-size: 1.5rem;
  cursor: pointer;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.7);
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top-button:hover {
  opacity: 1;
  transform: scale(1.1);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.9);
}

.scroll-to-top-button:focus {
  outline: none;
}



.games {

  &__card--wrapper {
    margin-left: 20px;
  }
  &__art-image {
  
    height: 95%;
    margin-top:20px;
  }

  &__memory-image {
  
    width: 99%;
  }
  &__spacejunk-image {
  
    width: 90%;
  }

  &__guess-image {
  
    width: 80%;
  }

  &__guess-image-title {
  
    max-width: 350px;
  }

  &__bvsd-image {
  
    height: 95%;
  }

  &__settings {
    &--container {
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 80%;
      display: flex;
      margin: 6rem auto 2rem;
      padding: 0rem 3rem 3rem;
      background: linear-gradient(45deg, whitesmoke 0%, lightgray 100%);
      border-radius: 10px;
      box-shadow: 1px 5px 10px 0px rgb(78, 88, 125);
    }

    &--input {
      width: 130px;
      height: 70px;
      border: 1px solid black;
      margin: auto;
      margin-top: 40px;
    }

    &--slider-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      max-width: 600px;
      margin: auto;
    }

    &--buttons-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      max-width: 400px;
    
      margin: auto;
     
      &:nth-child(3) {
        margin-top: 40px;
      }
    }

    &--button {
      display: flex;
      align-items: center;
      border: 2px solid #0e2052;
      color: #292997;
      background-color: rgba(255, 255, 255, 0.3);
      padding: 2rem 4rem;
      font-weight: 700;
      text-transform: uppercase;
      box-shadow: 1px 2px 3px 0px rgb(78, 88, 125);
      font-family: "Fellix-Bold";
      // letter-spacing: 0.1rem;
      border-radius: 5px;
      cursor: pointer;
      margin: 10px 30px;
      min-width: 149px;
      height: 80px;
      justify-content: space-around;

      &--start {
        display: flex;
        align-items: center;
        border: 2px solid #0e2052;
        box-shadow: 1px 2px 3px 0px rgb(78, 88, 125);
        color: #4141c4;
        padding: 2rem 4rem;
        font-weight: 700;
        font-size: 2.5rem;
        text-transform: uppercase;
        // letter-spacing: 0.1rem;
        border-radius: 5px;
        cursor: pointer;
        margin: 10px 30px;
        min-width: 360px;
        height: 70px;
        justify-content: space-around;
      }

      &--selected {
        background: linear-gradient(-90deg, #0e2052, #0e2052);
        border: 2px solid white;
        color: white;
        font-family: "Fellix-Bold";
        box-shadow: 1px 2px 3px 0px rgb(78, 88, 125);
      }
    }
  }

  &__modal {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: white;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    overflow: auto;
    z-index: 1000000;
    

    &--close {
      color: white;
      position: absolute;
      display: flex;
      top: 12px;
      right: 12px;
      height: 70px;
      width: 75px;
      padding: 12px;
      cursor: pointer;
      font-size: 30px;
      border-radius: 8px;
     background: linear-gradient(-90deg, #0e2052,  #0e2052);
     font-family: "Fellix-Bold";
     border: 4px solid white;
     
      box-shadow: 1px 2px 3px 1px rgb(78, 88, 125);
      text-align: center;
      align-items: center;
      justify-content: center;
      transition: 0.2s;
      &:hover {
        transform: scaleX(1.03) scaleY(1.03);
      }
    }
  }

  &__container-title {
    position: absolute;
    color: white;
    font-family: "Fellix-Bold";
    top: 14px;
    left: 67px;
    font-size: 1.4rem;
  }

  &__container {
    position: relative;
  display: flex;
 justify-content: flex-start;
      overflow-x: scroll;
  align-items: center;
  height: 200px;
  margin: 0px;
  padding-top: 30px;
  padding-bottom: 10px;

  background-color: black;
  width: 100vw;
  flex-direction: row;
  background: linear-gradient(-90deg, #202d4e, #3a3c49, #181c23);
 
 

}

&__card {
  height: 100px;
  width: 100px;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 1px 5px 10px 3px black;
  transition: 0.2s ease-in-out;



  &:hover {

    transform: scaleX(1.06) scaleY(1.06);

  }

  &--title-quiz {
    font-size: 2rem;
    font-family: "Fellix-Bold";
    text-align: center;
    color: white;
    text-shadow: 2px 2px 1px #583a5f, -1px -1px 1px #785383, -2px 2px 1px #382c45, 1px -1px 1px #23132b;
  }

    &--wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
    }

    &--player {
      color: white;
      font-weight: 700;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      &--max {
        font-size: 1rem;
      }
    }

    &--player-icon {
      margin-left: 4px;
    }

    &--racing-font {
      font-family: "QuickStarter";
      text-align: center;
      color: black;
      line-height: 2.5rem;
    }

    &--blue-font {
      font-family: "QuickStarter";
      text-align: center;
      color: black;
      transform: translateY(-4px);
      font-size: 2rem;
      line-height: 1.5rem;
      color:rgba(0, 157, 255, 0.8);
      text-shadow: 0px 1px 2px #25ffff;
    }

    &--bg {
  
      background-image: linear-gradient(
        to bottom,
        rgba(112, 112, 112, 0.8) ,
        rgba(123, 123, 123, 0.9) 50%,
        rgba(0, 0, 0, 0.7) 50%,
        rgba(0, 0, 0, 0.9) ,
        
      );         border: 1px solid white;


      &--quiz {
        background-image: linear-gradient(
          to bottom,
          rgba(16, 62, 212, 0.2),
          rgba(16, 84, 212, 0.2) 50%,
          rgba(13, 35, 121, 0.7) 50%,
          rgba(22, 14, 95, 0.7)
        );        margin-left: 40px;
        border: 1px solid white;
      }

      &--art {
        background-image: linear-gradient(
          to bottom,
          rgba(255, 103, 217, 0.3),
          rgba(245, 102, 255, 0.1) 50%,
          rgba(255, 0, 251, 0.5) 50%,
          rgba(255, 0, 204, 0.8)
        );        border: 1px solid white;
      }

      &--spacejunk {
        background-image: linear-gradient(
          to bottom,
          rgba(70, 1, 209, 0.7),
          rgba(72, 14, 187, 0.8) 50%,
          rgba(32, 19, 102, 0.9) 50%,
          rgba(34, 10, 80, 0.99)
        );        border: 1px solid white;
      }

      &--memory {
        border: 1px solid white;
        background-image: linear-gradient(
          to bottom,
          rgba(32, 207, 255, 0.2),
          rgba(47, 193, 255, 0.3) 50%,
          rgba(255, 0, 89, 0.5) 50%,
          rgba(255, 0, 119, 0.8)
        );        }

      &--balloons {
        border: 1px solid white;
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.7),
          rgba(0, 0, 0, 0.8) 50%,
          rgba(255, 166, 13, 0.8) 50%,
          rgba(255, 166, 13, 0.9)
        );      
      }
      &--racing {
        border: 1px solid white;
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.7),
          rgba(255, 255, 255, 0.8) 50%,
          rgba(33, 255, 13, 0.8) 50%,
          rgba(15, 91, 1, 0.9)
        );      
      }

      &--ai-tutor {
        border: 1px solid white;
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.7),
          rgba(255, 255, 255, 0.8) 50%,
          rgba(0, 157, 255, 0.8) 50%,
          rgba(13, 146, 255, 0.9)
        );      
      }
    }

    &--title {
      font-size: 0.8rem;
      order: 2;
      color: #fde9ff;
      font-weight: 900;
      text-transform: uppercase;
      font-size: clamp(1.3rem, 4vw, 2.3rem);
      line-height: 0.75em;
      text-align: center;
      // text-shadow: 3px 1px 1px #136f5a, 2px 2px 1px #176346, 4px 2px 1px #2c9b71,
      //   3px 3px 1px #2c9b71, 5px 3px 1px #31c69c, 4px 4px 1px #2c9b71;

      span {
        display: block;
        position: relative;

        &:before {
          content: attr(data-text);
          position: absolute;
          text-shadow: 2px 2px 1px #26705f, -1px -1px 1px #145338, -2px 2px 1px #1a5848, 1px -1px 1px #132b27;
          z-index: 1;
        }

        &:nth-child(1) {
          padding-right: 0.5rem;
          padding-bottom: 0.7rem;
        }

        &:nth-child(2) {
          padding-right: 0rem;
          padding-bottom: 0.7rem;
        }
        &:nth-child(3) {
          padding-left: 0.25rem;
        }
      }

      &--memory {
        font-size: 0.8rem;
        order: 2;
        color: #fde9ff;
        font-weight: 900;
        text-transform: uppercase;
        font-size: clamp(1.2rem, 3vw, 2.1rem);
        line-height: 0.75em;
        text-align: center;
        // text-shadow: 3px 1px 1px #136f5a, 2px 2px 1px #176346, 4px 2px 1px #2c9b71,
        //   3px 3px 1px #2c9b71, 5px 3px 1px #31c69c, 4px 4px 1px #2c9b71;

        span {
          display: block;
          position: relative;

          &:before {
            content: attr(data-text);
            position: absolute;
            text-shadow: 2px 2px 1px #316ef3, -1px -1px 1px #316ef3, -2px 2px 1px #1a2058, 1px -1px 1px #13142b;
            z-index: 1;
          }

          &:nth-child(1) {
            padding-right: 0rem;
            padding-bottom: 0.7rem;
          }

          &:nth-child(2) {
            padding-right: 1rem;
            padding-bottom: 0.7rem;
          }
          &:nth-child(3) {
            padding-left: 0.25rem;
          }
        }
      }

      &--balloons {
        font-size: 0.8rem;
        order: 2;
        color: #fde9ff;
        font-weight: 900;

        font-size: clamp(3rem, 4vw, 2.1rem);
        line-height: 0.75em;
        text-align: center;
        // text-shadow: 3px 1px 1px #136f5a, 2px 2px 1px #176346, 4px 2px 1px #2c9b71,
        //   3px 3px 1px #2c9b71, 5px 3px 1px #31c69c, 4px 4px 1px #2c9b71;

        span {
          display: block;
          position: relative;

          &:before {
            content: attr(data-text);
            position: absolute;
            text-shadow: 2px 2px 2px #a47124, -1px -1px 1px #efa62f, -1px 1px 1px #9d5f25, 1px -1px 1px #6d4b2a;
            z-index: 1;
          }

          &:nth-child(1) {
            padding-right: 0rem;
            padding-bottom: 0.7rem;
          }

          &:nth-child(2) {
            padding-right: 0rem;
            padding-bottom: 0.7rem;
            font-size: 2rem;
            &:before {
              text-shadow: none;
              color: black;
              font-family: 'Fellix-Bold';
            }
          }
          &:nth-child(3) {
            padding-left: 0.25rem;
          }
        }
      }

      &--wrapper {
        display: grid;
        align-items: center;
        justify-content: center;
        transform: skew(0, -10deg);
      }
    }
  }

  .top {
    clip-path: polygon(0% 0%, 100% 0%, 100% 48%, 0% 58%);
  }
  .bottom {
    clip-path: polygon(0% 60%, 100% 50%, 100% 100%, 0% 100%);
    color: transparent;
    background: -webkit-linear-gradient(177deg, black 53%, var(--text-color) 65%);
    background: linear-gradient(177deg, black 53%, var(--text-color) 65%);
    background-clip: text;
    -webkit-background-clip: text;
    transform: translateX(-0.02em);
  }
}

.cards-container {
  display: flex;
  justify-content: flex-start;
  margin: 0 -1.5rem;
  width: calc(100% + 3rem);
  flex-direction: column;

  .card {
    margin: 1.5rem;
    max-width: 20rem;
  }

  &__daughter-section-tag-wrapper {
    &--left {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 90%;
      border-radius: 10px;
      padding: 1rem;
      margin: 3.5rem 0 0;
      box-shadow: 0 3px 10px 3px rgba(203, 219, 223, 0.6);
      max-width: 100%;
      position: relative;
      background: #ffffff;
      box-sizing: border-box;
      // z-index: -1;
    }
    &--middle {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;
      border-radius: 10px;
      padding: 1rem;
      margin: 3.5rem 0 0;
      box-shadow: 0 3px 10px 3px rgba(203, 219, 223, 0.6);
      max-width: 100%;
      position: relative;
      background: #ffffff;
      box-sizing: border-box;
      // z-index: -1;
    }
    &--right {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;
      border-radius: 10px;
      padding: 1rem;
      margin: 3.5rem 0 0;
      box-shadow: 0 3px 10px 3px rgba(203, 219, 223, 0.6);
      max-width: 100%;
      position: relative;
      background: #ffffff;
      box-sizing: border-box;
      // z-index: -1;
    }
    &--left:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -0.5em;
      top: 0;
      left: 9%;
      box-sizing: border-box;
      z-index: -1;
      border: 0.75em solid #142e4a;
      // border-color: transparent transparent #fff #fff;

      transform-origin: 0 0;
      transform: rotate(-45deg);

      box-shadow: 5px -5px 10px -1px rgba(203, 219, 223, 1);
    }
    &--middle:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -0.5em;
      top: 0;
      left: 33.75%;
      box-sizing: border-box;
      z-index: -1;
      border: 0.75em solid #142e4a;
      // border-color: transparent transparent #fff #fff;

      transform-origin: 0 0;
      transform: rotate(-45deg);

      box-shadow: 5px -5px 10px -1px rgba(203, 219, 223, 1);
    }
    &--right:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -0.5em;
      top: 0;
      left: 63.25%;
      box-sizing: border-box;
      z-index: -1;
      border: 0.75em solid #142e4a;
      // border-color: transparent transparent #fff #fff;

      transform-origin: 0 0;
      transform: rotate(-45deg);

      box-shadow: 5px -5px 10px -1px rgba(203, 219, 223, 1);
    }
  }

  &__daughter-section-tags {
    padding: 1rem;
    margin: 1rem auto;
    color: black;
    border-radius: 0.35rem;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
    &:active {
      text-decoration: underline;
    }
  }

  &__worksheets-display-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__subcategory-arrow {
    position: relative;
    height: 15px;
    width: 30px;
    margin-left: -1.5rem;
    margin-right: 0.5rem;
    margin-top: 1.5rem;
    img {
      width: 100%;
    }
  }

  &__close-btn {
    background-color: transparent;
    margin-left: 1rem;
    font-weight: bold;
    font-family: "Fellix-Bold";

    &__subcategory {
     
      background-color: transparent;
      margin-left: 1rem;
      font-weight: bold;
    }
  }

  &__see-more-btn-text {
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__tag-cards-wrapper {
    padding: 0 2rem;
  }

  &__tag-wrapper {
    display: flex;
    flex-direction: row;
    margin: 1rem 0;
    position: relative;
    width: 100%;

    &.scroll {
      overflow-x: scroll;
    }

    &--hidden {
      display: none;
    }
  }

  &__tags {
    background-image: linear-gradient(135deg, #142e4a, #142e4a);
    border-radius: 0.35rem !important;
    font-weight: bold;
    padding: .5rem 1.5rem !important;
    min-width: 137.688px;
    p {
      margin: auto;
    }
  }

  &__grade-wrapper,
  &__subject-wrapper,
  &__subcategory-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__grade-selector {
    cursor: pointer;
    font-weight: bold;
    background-color: #142e4a;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    left: 2.5%;
    text-align: center;
    width: 105px;
    margin-top: 3.5rem;
    padding-bottom: 0.5%;
    animation: bounce 0.8s 1.75 alternate;
    -webkit-animation: bounce 0.8s 1.75 alternate;
    -moz-animation: bounce 0.8s 1.75 alternate;
  }
  &__grade-selector:before {
    content: '';
    position: absolute;
    left: 5%;
    bottom: 0;
    height: 15px;
    width: 90%;
    border-bottom: 1px solid #142e4a;
  }

  &__subject-selector {
    cursor: pointer;
    position: absolute;
    left: 27%;
    text-align: center;
    width: 108px;
    font-weight: bold;
    background-color: #142e4a;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 3.5rem;
    padding-bottom: 0.5%;
    animation: bounce 0.8s 1.75 alternate;
    -webkit-animation: bounce 0.8s 1.75 alternate;
    -moz-animation: bounce 0.8s 1.75 alternate;
  }
  &__subject-selector:before {
    content: '';
    position: absolute;
    left: 5%;
    bottom: 0;
    height: 15px;
    width: 90%;
    border-bottom: 1px solid #142e4a;
  }

  &__subcategory-selector {
    cursor: pointer;
    position: absolute;
    left: 54%;
    text-align: center;
    width: 142px;
    font-weight: bold;
    background-color: #142e4a;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 5rem;
    padding-bottom: 0.5%;
    animation: bounce 0.8s 1.75 alternate;
    -webkit-animation: bounce 0.8s 1.75 alternate;
    -moz-animation: bounce 0.8s 1.75 alternate;
  }
  &__subcategory-selector:before {
    content: '';
    position: absolute;
    left: 5%;
    bottom: 0;
    height: 15px;
    width: 90%;
    border-bottom: 1px solid #142e4a;
  }

  &__empty-grade-tag {
    border-radius: 0.35rem !important;
    font-weight: bold;
    padding: .5rem 1.5rem !important;
    border: #142e4a dashed 2px;
    margin-right: 2rem;
    text-align: center;
    min-width: 137.688px;
    opacity: 0;
  }

  &__empty-subject-tag {
    border-radius: 0.35rem !important;
    font-weight: bold;
    padding: .5rem 1.5rem !important;
    border: #142e4a dashed 2px;
    margin-right: 2rem;
    text-align: center;
    min-width: 137.688px;
    opacity: 0;
  }

  &__empty-subcategory-tag {
    display: flex;
    justify-content: flex-start;
    background: none;
    margin: 0rem 0rem 0rem;
    flex-direction: column;
    font-weight: bold;
    border: #142e4a dashed 2px;
    border-radius: 0.35rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1.5rem !important;
    min-width: 157.094px;
    justify-content: space-around;
    opacity: 0;
  }
}

#subcategory-tag {
  display: flex;
  justify-content: flex-start;
  background: #142e4a;
  margin: 0rem;
  flex-direction: column;
  color: white;
  font-weight: bold;
  border: #142e4a solid 2px;
  border-radius: 0.35rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem !important;
  position: relative;
  
  min-width: 157.094px;
}

#empty-grade-tag,
#grade-tag {
  min-height: 30px;
}

#empty-subject-tag,
#subject-tag {
  min-height: 30px;
  position: relative
 
}

#subcategory-arrow {
  position: absolute;
  left: 49%;
}

#empty-subcategory-tag {
  position: absolute;
  left: 53%;
}

.resource-row-subheading {
  color: black;
  span {
    color: #4d7bd7;
  }
}

.resource-row-subheading-2 {
  margin-bottom: 40px;
}

.resource-row-subheading-link {
  text-decoration: underline;
  cursor: pointer;
}

.resource-row-heading {
  color: black;
  font-size: 35px;
  margin-bottom: 30px;
}

.resource-row-border {
  margin-top: 40px;
  border-top: gray 0.5px dashed;
  margin-bottom: 40px;
}

.worksheets__filter-select--mobile {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

// ! cards container media queries ================================================
@include tablet-portrait {
  .cards-container {
    &__see-more-btn {
      font-size: 1.2rem;
      letter-spacing: 2px;
    }

    &__tag-cards-wrapper {
      padding: 0;
      margin-top: 5rem;
    }
  }
}

@media (min-width: 768px) {
  .cards-container {
    &__tag-cards-wrapper {
      max-width: 68rem;
      margin: 5rem auto 0;
    }
  }
}
@media (max-width: 768px) {

.games {

  &__modal--close {
    top: 90px;
  }

  &__settings--container {
    margin-top: 120px !important;
  }


  &__guess-image-title {
  
    max-width: 370px;
  }

  &__settings--buttons-wrapper {
    max-width: 300px !important;
  }

  &__settings--button--start {
    min-width: 270px !important;
  }

  &__settings--button {
    max-width: 120px !important;
    min-width: 120px !important;
    padding: 1rem;
    margin: 10px;
  }
}

  .cards-container__daughter-section-tags {
    background-color: #f6f6f6;
    margin: 0.2rem;
  }
  #empty-subject-tag,
  #subject-tag {
    position: static;
  }

  #subcategory-arrow {
    position: static;
  }

  #empty-subcategory-tag,
  #subcategory-tag {
    position: static;
    max-height: 31px;
  }
  .cards-container {
    &__daughter-section-tag-wrapper {
      &--left:after {
        display: none;
      }
      &--middle:after {
        display: none;
      }
      &--right:after {
        display: none;
      }
    }
  }
}

@media (min-width: 1024px) {
  .cards-container {
    margin: 0 auto;

    .card {
      margin: 1.5rem 0rem;
      width: 100%;
    }

    &__desktop-flex-wrapper {
      display: flex;
      justify-content: flex-start;
      margin-top: 3rem;
      margin-left: 240px;
      position: relative;

      .tab-filter {
        width: 15%;
      }
    }

    &__tag-wrapper {
      margin-bottom: 1rem;
    }

    &__tag-cards-wrapper {
      max-width: 93%;
      margin: 0;
    }
  }
}

@include desktop {
  .cards-container {
    &__desktop-flex-wrapper {
      .tab-filter {
        max-width: 7rem;
      }
    }
  }
}

@include big-desktop {
  .cards-container {
    &__tag-cards-wrapper {
      min-width: 93%;
    }
    &__grade-selector {
      left: 1.25%;
    }
    &__subject-selector {
      left: 14.6%;
    }
    &__subcategory-selector {
      left: 30.7%;
    }
  }
  .resource-row {
    &__see-more {
      max-width: 65rem;
    }
  }

  #empty-subject-tag,
  #subject-tag {
    position: relative
   
  }

  #subcategory-arrow {
    position: absolute;
    left: 27.5%;
  }

  #empty-subcategory-tag,
  #subcategory-tag {
    position: relative;
   
  }

  .cards-container {
    &__daughter-section-tag-wrapper {
      &--left:after {
        left: 5%;
      }
      &--middle:after {
        left: 18.5%;
      }
      &--right:after {
        left: 35.75%;
      }
    }
  }
}

// ! resource row styles ============================================================
.resource-row {
  margin-bottom: 3rem;

  &__card-wrapper {
    display: flex;
    margin-bottom: 1rem;
    overflow-y: hidden;
    justify-content: flex-start;
  }

  &__wrapper {
    width: 24.7rem;
  }

  &__row-heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--overlay {
      .resource-row__title {
        color: #5d6169;
      }

      .cards-container__see-more-btn {
        color: #5f6169;
      }
    }
  }

  &__single-card-wrapper {
    margin-right: 8px;
  }

  &__title {
    font-size: 1.6rem;
  }

  &__see-more {
    display: flex;
    justify-content: flex-end;
    margin-right: 5%;
  }

  &__see-more-btn {
    position: relative;
    color: #fff;
    background-color: #4d7bd7;
    width: fit-content;
    border-radius: 1.6rem;
    padding: 0.6rem 1.9rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;

    &:hover {
      img {
        transform: translateX(0.75rem);
        transition: all 0.5s ease;
        // -moz-transform: translateX(0.75rem);
        -moz-transform: translate(0.75);
      }
    }

    img {
      top: 0.7rem;
      height: 30px;
      width: 30px;
      filter: invert(100%);
      -webkit-filter: invert(100%);
      background-color: inherit;
      transform: rotate(30deg);
      margin-left: -10px;
      margin-right: 10px;
    }
  }
}

// ! resource row media queries ============================================================
@include tablet-portrait {
  .resource-row {
    margin-bottom: 3rem;

    &__title {
      font-size: 2.75rem;
    }
  }
}

@media (min-width: 1024px) {
  .resource-row {
    &__single-card-wrapper {
      margin-right: 16px;
    }
  }
}

@include big-desktop {
  .resource-row {
    &__row-heading-wrapper {
      margin-bottom: 1rem;
      // padding-right: 8rem;
    }
  }
}

.unfill {
  background: none;
  border: #f26931 1px solid;
}

.invert-square {
  background: none;
  background-color: white;
}

//! Two versions of the 'triangle with border.' Can't decide which one looks better. Uncomment/comment out the corresponding lines to see the difference.
.invert-triangle {
  // border-left: 5px solid transparent;
  // border-right: 5px solid transparent;
  // border-top: 10px solid #f15b49;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 11px solid #142e4a;
}
.invert-triangle:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 7px solid white;
  // border-left: 4px solid transparent;
  // border-right: 4px solid transparent;
  // border-top: 8px solid white;
  position: relative;
  top: -1px;
  left: -3px;
  // left: -4px;
}

@-webkit-keyframes bounce {
  0% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(1px);
  }
}

@-moz-keyframes bounce {
  0% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(1px);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(1px);
  }
}