.test-overview {
  padding: 4rem;

  &__summary {
    margin-bottom: 6rem;
  }

  &__heading {
    @include sub-heading;
    font-size: 2.5rem;
  }

  &__summary-content,
  &__section-list,
  &__section-items,
  &__test-summary {
    @include heading-content;
  }

  &__section-heading {
    @include heading-content;
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }

  &__section-items {
    margin: 0 0 1rem 3rem;
    list-style-type: disc;
  }

  &__img {
    margin: 4rem auto 0;
    width: 20rem;

    img {
      width: 100%;
      border-radius: 7px;
    }
  }
}

// ! test overview media queries ==================================================
@include tablet-portrait {
  .test-overview {
    padding: 7rem;
  }
}

@include desktop {
  .test-overview {
    &__summary-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 90%;
      margin: 0 auto;
    }

    &__overview-wrapper {
      width: 100%;
      margin-right: 5rem;
    }

    &__img {
      width: 40rem;

      img {
        width: 100%;
      }
    }
  }
}

// ! perfect score =======================================================
.perfect-score {
  padding: 4rem 2rem;

  &__heading,
  &__sub-heading {
    padding-left: 5rem;
    text-align: center;
  }

  &__wrapper {
    position: relative;
    padding: 0 2rem;
  }

  &__heading {
    @include sub-heading;
  }

  &__sub-heading {
    @include heading-content;
    margin-bottom: 3rem;
  }

  &__orange-text {
    color: #f18658;
  }

  &__img {
    height: auto;
    padding: 0 1rem;
    width: 30rem;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__step-heading {
    margin: 0 0 1rem;
    font-size: 1.5rem;
  }

  &__step-summary {
    font-weight: 500;
    font-size: 1.2rem;
    color: #5d6168;
    line-height: 1.4;

    @include tablet-portrait {
      font-size: 1.5rem;
    }
  }

  &__cycle {
    margin-left: 1.5rem;
    list-style-type: disc;
    font-weight: 500;
    font-size: 1.2rem;
    color: #5d6168;
    line-height: 1.4;
  }

  &__step1,
  &__step2,
  &__step3,
  &__step4,
  &__step5 {
    position: absolute;
    top: 0;
    width: 16rem;
  }

  &__step1 {
    top: 51px;
    left: 85px;
  }

  &__step2 {
    left: 100px;
    top: 300px;
  }

  &__step3 {
    left: 90px;
    top: 580px;
  }

  &__step4 {
    left: 105px;
    top: 770px;
  }

  &__step5 {
    left: 85px;
    top: 1095px;
  }
}

// ! perfect score media queries ========================================
@include tablet-portrait {
  .perfect-score {
    &__wrapper {
      padding: 0 15rem;
    }

    &__heading {
      font-size: 4rem;
    }

    &__step-heading {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }

    &__step-summary {
      font-size: 1.3rem;
    }

    &__step1,
    &__step2,
    &__step3,
    &__step4,
    &__step5 {
      width: 16rem;
    }

    &__step1 {
      left: 220px;
      top: 40px;
    }

    &__step2 {
      top: 300px;
      left: 240px;
    }

    &__step3 {
      left: 220px;
      top: 570px;
    }

    &__step4 {
      top: 755px;
      left: 240px;
    }

    &__step5 {
      left: 220px;
      top: 1070px;
    }
  }
}

@include big-tablet {
  .perfect-score {
    &__wrapper {
      padding: 0 16%;
    }

    &__step1 {
      left: 370px;
      top: 40px;
    }

    &__step2 {
      top: 300px;
      left: 380px;
    }

    &__step3 {
      left: 370px;
      top: 575px;
    }

    &__step4 {
      top: 750px;
      left: 380px;
    }

    &__step5 {
      left: 370px;
      top: 1070px;
    }
  }
}

@include desktop {
  .perfect-score {
    &__wrapper {
      padding: 0 20%;
    }

    &__sub-heading {
      margin-bottom: 6rem;
    }

    &__step1 {
      left: 500px;
      top: 40px;
    }

    &__step2 {
      top: 300px;
      left: 520px;
    }

    &__step3 {
      left: 500px;
      top: 575px;
    }

    &__step4 {
      top: 755px;
      left: 520px;
    }

    &__step5 {
      left: 510px;
      top: 1068px;
    }
  }
}

@include big-desktop {
  .perfect-score {
    &__wrapper {
      padding: unset;
      max-width: 650px;
      margin: 0 auto;
    }

    &__step1 {
      left: 890px;
      top: 40px;
    }

    &__step2 {
      top: 300px;
      left: 900px;
    }

    &__step3 {
      left: 890px;
      top: 575px;
    }

    &__step4 {
      top: 755px;
      left: 900px;
    }

    &__step5 {
      left: 890px;
      top: 1068px;
    }
  }
}

// ! why tutoring ======================================================
.why-tutoring {
  padding: 4rem;

  &__img {
    width: 100%;

    img {
      width: 100%;
      border-radius: 7px;
    }
  }

  &__header {
    @include sub-heading;
  }

  &__right-container {
    margin-top: 4rem;
  }

  &__point {
    margin-bottom: 6rem;
  }

  &__sub-header {
    @include sub-heading;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
}

// ! why tutoring media queries ================================================
@include tablet-portrait {
  .why-tutoring {
    &__img {
      width: 75%;
      display: block;
      margin: 0 auto;
    }
  }
}

@include desktop {
  .why-tutoring {
    display: flex;
    padding: 8rem;

    &__left-container {
      width: 50%;
      margin-right: 5rem;
    }

    &__right-container {
      width: 50%;
    }
  }
}
