.icon-container {
  padding: 4px 0;
  margin-left: 12px;
}

.icon {
  background-color: $gray-icon;
  height: 36px;
  width: 36px;
  border-radius: 36px;
}

@media (max-width: 700px) {
  .sharing {
    flex-direction: row;

    .icon-container {
      margin-left: 0;
      margin-right: 12px;
    }
  }
}


// .sharing {
//   display: flex;
//   justify-content: space-around;
//   width: 50%;
//   margin: 24px auto;

//   @include desktop {
//     margin: unset;
//     width: unset;
//     position: absolute;
//     top: 55rem;
//     left: 8vw;
//     display: flex;
//     flex-direction: column;
//   }

//   .icon-container {
//     padding: 4px 0;
//   }

//   .icon {
//     height: 48px;
//     width: 48px;
//   }
// }

// @include medium {
//   .blog-body {
//     position: relative;
//   }
// }

// @include large {

//   .icon-container,
//   .icon {
//     width: 48px;
//     height: 48px;
//   }
// }