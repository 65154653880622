body {
  margin: 0;
  padding: 0;
  background-color: white;
}
.dice {
  height: 100px;
  width: 100px;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-100px) rotateY(-45deg) rotateX(-45deg);
  transition: transform 1s;

  &__dice-container {
    width: 200px;
    height: 230px;
    position: relative;
    background-color: white;
    align-self: flex-start;
    border-bottom-left-radius: 12px;
    margin-right: 0px;
  }
}
.panel {
  border-radius: 10px;
  width: 100px;
  height: 100px;
  perspective: 400px;
  position: absolute;
  left: 50%;
  top: 80px;
  transform: translate(-50%, -50%);
}
.dot {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 4px;
  background-color: gold;
}
.side {
  position: absolute;
  background-color: black;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  line-height: 100px;
  padding: 5px;
}
.one {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateX(-90deg) translateZ(50px);
  z-index: 1;
}
.two {
  display: flex;
  justify-content: space-between;
  transform: rotateY(180deg) translateZ(50px);
  z-index: 2;
}
.two .dot:nth-of-type(2) {
  align-self: flex-end;
}
.three {
  display: flex;
  justify-content: space-between;
  transform: rotateY(90deg) translateZ(50px);
  z-index: 3;
}

.three .dot:nth-of-type(2) {
  align-self: center;
}

.three .dot:nth-of-type(3) {
  align-self: flex-end;
}
.four {
  z-index: 4;
  transform: rotateY(0deg) translateZ(50px);
}
.four,
.six {
  display: flex;
  justify-content: space-between;
}

.four .kolona,
.six .kolona {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.five {
  display: flex;
  justify-content: space-between;
  transform: rotateY(-90deg) translateZ(50px);
  z-index: 5;
}

.five .kolona {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.five .kolona:nth-of-type(2) {
  justify-content: center;
}
.six {
  transform: rotateX(90deg) translateZ(50px);
  z-index: 6;
}

/*Customization panel*/
.custom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 147px;
}
.custom > * {
  margin: 10px;
}
.custom > button {
  font-size: 20px;
  font-family: "Verdana";
  width: 120px;
  height: 50px;
  color: gold;
  background-color: black;
  border: none;
  border-radius: 10px;
}
.picker {
  border: none;
}
