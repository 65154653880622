.App {
  overflow-x: hidden;
}

.consultation-widget {
  background-color: #eef9fe;
  position: relative;
  margin: 4% -150% 1%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  min-height: 405px;

  &__modalized {
    background-color: #eef9fe;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    min-height: 337px;
  }

  &__start-container {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 85rem;
    text-transform: uppercase;

    &--modalize {
      width: 80%;
      margin: auto;
      margin-top: 11%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 85rem;
      text-transform: uppercase;
    }
  }

  &__start-container-backslide {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 85rem;
    text-transform: uppercase;
    animation: fadeIn, slideFromBack;
    -webkit-animation: fadeIn, slideFromBack;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;

    &--modalize {
      width: 80%;
      margin: auto;
      margin-top: 11%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 85rem;
      text-transform: uppercase;
      animation: fadeIn, slideFromBack;
      -webkit-animation: fadeIn, slideFromBack;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
    }
  }

  &__left-side {
    display: flex;
    flex-direction: column;
  }

  &__popout-text {
    font-size: 35px;
    color: #f26522;
    font-family: 'GothamBold';
    font-weight: bolder;
  }

  &__heading {
    margin-top: -0.5%;
    margin-bottom: 1rem;
    font-family: 'GothamBold';
    font-size: 18px;
  }

  &__right-side {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__date {
    color: #285ecb;
  }

  &__flavor-text2 {
    text-transform: none;
  }

  &__start-button {
    display: flex;
    flex-direction: row;

    justify-content: center;
    background-color: #112d65;
    color: white;
    padding: 2rem 1rem;
    margin: 0.5rem 0;
    border-radius: 4px;
    width: 100%;
    text-transform: none;
    cursor: pointer;
    box-shadow: 5px 5px 4px 1px #cbdbdf;
    &--text {
      margin-left: 1rem;
      font-weight: bold;
      font-size: 25px;
    }
  }

  &__slider-container {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &--modalize {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      margin-top: 6.5%;
    }
  }

  &__slider-container-backslide {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeIn, slideFromBack;
    -webkit-animation: fadeIn, slideFromBack;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    &--modalize {
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: fadeIn, slideFromBack;
      -webkit-animation: fadeIn, slideFromBack;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      margin: auto;
      margin-top: 6.5%;
    }
  }

  &__slider-container-frontslide {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeIn, slideFromFront;
    -webkit-animation: fadeIn, slideFromFront;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    &--modalize {
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: fadeIn, slideFromFront;
      -webkit-animation: fadeIn, slideFromFront;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      margin: auto;
      margin-top: 6.5%;
    }
  }

  &__slider-query {
    margin-bottom: 4.5rem;
    font-size: 25px;
  }

  &__slider {
    -webkit-appearance: none;
    width: 63%;

    height: 15px;
    border-radius: 10px;
    background: linear-gradient(-90deg, #133a88, #103c94, #133a88);
    box-shadow: 0 2px 10px 0 #858f92;
    outline: none;
    opacity: 1;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    margin: 2rem 0 5.5rem;
    @include mobile {
      min-width: 200px;
      max-width: 200px;
    }
    @include tablet-portrait {
      min-width: 300px;
      max-width: 300px;
    }
    @include desktop {
      min-width: 400px;
      max-width: 400px;
    }
    @include big-desktop {
      min-width: 500px;
      max-width: 500px;
    }
  }

  &__slider-value {
    font-weight: 700;
    font-size: 21px;
  }

  &__slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background: linear-gradient(135deg, rgb(175, 214, 210), #fff, rgb(175, 214, 210));
    box-shadow: $box-shadow;
    cursor: pointer;
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    bottom: 0;
  }

  &__button {
    display: flex;
    background: linear-gradient(-90deg, #133a88, #103c94, #133a88);
    color: white;
    padding: 2rem 4rem;
    font-weight: 700;
    text-transform: uppercase;
    // letter-spacing: 0.1rem;
    border-radius: 5px;
    cursor: pointer;
    margin: 0.75rem 6rem;
    min-width: 269px;
    justify-content: space-around;
  }

  &__button--no {
    display: flex;
    align-items: center;
    background-color: gray;
    color: white;
    padding: 2rem 4rem;
    font-weight: 700;
    text-transform: uppercase;
    // letter-spacing: 0.1rem;
    border-radius: 5px;
    cursor: pointer;
    margin: 0.75rem 6rem;
    min-width: 269px;
    justify-content: space-around;
  }

  &__subject-container {
    display: flex;
    flex-direction: column;
    width: 25%;
    align-items: center;

    &--modalize {
      display: flex;
      flex-direction: column;
      width: 25%;
      align-items: center;
      margin-top: 2%;
    }
  }

  &__subject-container-backslide {
    display: flex;
    flex-direction: column;
    width: 25%;
    align-items: center;
    animation: fadeIn, slideFromBack;
    -webkit-animation: fadeIn, slideFromBack;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;

    &--modalize {
      display: flex;
      flex-direction: column;
      width: 25%;
      align-items: center;
      animation: fadeIn, slideFromBack;
      -webkit-animation: fadeIn, slideFromBack;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      margin-top: 2%;
    }
  }

  &__subject-container-frontslide {
    display: flex;
    flex-direction: column;
    width: 25%;
    align-items: center;
    animation: fadeIn, slideFromFront;
    -webkit-animation: fadeIn, slideFromFront;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;

    &--modalize {
      display: flex;
      flex-direction: column;
      width: 25%;
      align-items: center;
      animation: fadeIn, slideFromFront;
      -webkit-animation: fadeIn, slideFromFront;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      margin-top: 2%;
    }
  }

  &__subject-query {
    align-self: center;
    text-align: center;
    font-weight: 700;
    font-size: 21px;
  }
  &__subject-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 754.156px;
    min-width: 380.5px;
    margin: 0 0 5rem;
  }
  &__subject {
    background-color: #fff;
    border-radius: 7px;
    text-align: center;
    padding: 3rem;
    margin: 5rem 2rem 0;
    width: 15rem;
    height: 13rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &--highlight {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #f6f7f9;
      width: 15rem;
      height: 13rem;
      margin: 5rem 2rem 0;
      border: 4px solid #133a88;
      padding: 2.6rem;
      border-radius: 7px;
      cursor: pointer;
    }
  }

  &__prompt-container {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &--modalize {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 5%;
    }
  }

  &__prompt-container-backslide {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    animation: fadeIn, slideFromBack;
    -webkit-animation: fadeIn, slideFromBack;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    &--modalize {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      animation: fadeIn, slideFromBack;
      -webkit-animation: fadeIn, slideFromBack;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      margin-top: 5%;
    }
  }

  &__prompt-container-frontslide {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    animation: fadeIn, slideFromFront;
    -webkit-animation: fadeIn, slideFromFront;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    &--modalize {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      animation: fadeIn, slideFromFront;
      -webkit-animation: fadeIn, slideFromFront;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      margin-top: 5%;
    }
  }

  &__prompt {
    margin-bottom: 3rem;
    font-size: 20px;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    text-align: center;
    &--question {
      margin-top: 2.5rem;
    }
    &--popout-text {
      color: #133a88;
    }
    &--blurb {
      @include mobile {
        min-width: 300px;
        max-width: 300px;
      }
      @include tablet-portrait {
        min-width: 600px;
        max-width: 600px;
      }
      @include desktop {
        min-width: 800px;
        max-width: 800px;
      }
      @include big-desktop {
        min-width: 1000px;
        max-width: 1000px;
      }
    }
  }

  &__form-container-backslide {
    animation: fadeIn, slideFromBack;
    -webkit-animation: fadeIn, slideFromBack;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
  }
  &__form-container-frontslide {
    animation: fadeIn, slideFromFront;
    -webkit-animation: fadeIn, slideFromFront;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
  }

  &__form-heading {
    text-align: center;
    width: 50%;
    margin: auto auto 3rem;
  }
  &__form {
    display: flex;
    flex-direction: row;
    margin-bottom: 3rem;
  }
  &__form-input {
    height: 5rem;
    font-size: 1.5rem;
    padding: 1.5rem 2rem;
    border: 1px solid #d8dadf;
    border-radius: 8px;
    margin: 1rem 2rem 1rem;
    max-width: 250px;
    min-width: 250px;
  }

  &__datetime-picker {
    display: flex;
    flex-direction: column;
  }
  &__datetime-picker-backslide {
    display: flex;
    flex-direction: column;

    justify-content: space-around;
    animation: fadeIn, slideFromBack;
    -webkit-animation: fadeIn, slideFromBack;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
  }
  &__datetime-picker-frontslide {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    animation: fadeIn, slideFromFront;
    -webkit-animation: fadeIn, slideFromFront;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
  }
  &__datetime-heading {
    margin-bottom: 1rem;
    align-self: center;
    font-size: 25px;
  }
  &__datetime-subheading {
    margin: auto;
    font-size: 15px;
  }
  &__datetime-select-wrapper {
    text-align: center;
    margin: 5rem 0;
  }
  &__datetime-select {
    margin: 0 3rem;
    min-width: 200px;
    font-size: 20px;
    text-align-last: center;
    border-radius: 8px;
    min-height: 49px;
    border: 1px solid #d8dadf;
    background: white;
  }

  &__end-container {
    display: flex;
    flex-direction: column;
  }
  &__end-container-backslide {
    display: flex;
    flex-direction: column;
    animation: fadeIn, slideFromBack;
    -webkit-animation: fadeIn, slideFromBack;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
  }
  &__end-container-frontslide {
    display: flex;
    flex-direction: column;
    animation: fadeIn, slideFromFront;
    -webkit-animation: fadeIn, slideFromFront;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
  }
  &__end-heading {
    align-self: center;
    margin-bottom: 4rem;
  }
  &__end-textbox {
    width: 100%;
    height: 50px;
    margin-bottom: 4rem;
    border: 1px solid #d8dadf;
    border-radius: 8px;
    padding: 3rem 2.5rem;
    font-size: 2rem;
  }

  &__true-end-container {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &--modalize {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 5%;
    }
  }

  &__true-end-container-backslide {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    animation: fadeIn, slideFromBack;
    -webkit-animation: fadeIn, slideFromBack;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    &--modalize {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      animation: fadeIn, slideFromBack;
      -webkit-animation: fadeIn, slideFromBack;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      margin-top: 5%;
    }
  }

  &__true-end-container-frontslide {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    animation: fadeIn, slideFromFront;
    -webkit-animation: fadeIn, slideFromFront;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    &--modalize {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      animation: fadeIn, slideFromFront;
      -webkit-animation: fadeIn, slideFromFront;
      animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      margin-top: 5%;
    }
  }

  &__true-end {
    margin-bottom: 3rem;
    font-size: 20px;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 80rem;
    &--blurb {
      margin-bottom: 3rem;
    }
    &--popout-text {
      color: #133a88;
    }
  }

  &__dropdown-arrow {
    position: absolute;
    left: 49%;
    top: 43.75%;
  }
}

.consultation-modal {
  width: 70%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #eef9fe;
  border-radius: 8px;
  position: fixed;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: $box-shadow;
  padding: 3rem;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideFromBack {
  0% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideFromFront {
  0% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}

//make mobile show two subject cards per row
@media (max-width: 867px) {
  #form-button,
  #form-button-no {
    max-width: 269px;
  }
  .consultation-widget {
    &__button-container {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-around;
      position: relative;
      bottom: 0;
      align-items: center;
    }
    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__button {
      margin-bottom: 2rem;
    }
  }
}

@media (max-width: 467px) {
  .consultation-widget {
    &__subject-wrapper {
      justify-content: space-around;
    }
  }
}

// @media (max-width: 1315px) {
//   .consultation-widget {
//     &__subject-wrapper {
//       flex-wrap: wrap;
//       &--modalize {
//         flex-wrap: wrap;
//       }
//     }
//   }
// }

@font-face {
  font-family: GothamBlack;
  src: url('../../assets/Gotham-Black.otf') format('opentype');
}
@font-face {
  font-family: GothamBold;
  src: url('../../assets/Gotham-Bold.otf') format('opentype');
}


.click-arrow-image {
  position: absolute;
  width: 60px;
  right: 46%;
  top: 37%;
}


@media (max-width: 1500px) {

  .click-arrow-image {
    right: 44%;
  }

}

@media (max-width: 800px) {

  .click-arrow-image {
    right: 37%;
  }

}