.form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eef9fe;
  padding: 4% 8%;

  form {
    width: 100%;
    padding-bottom: 2%;
    display: flex;
    flex-direction: column;

    .form-header {
      font-size: 3rem;
      padding-bottom: 4rem;
    }

    input,
    textarea {
      margin: 0 1.5rem 1.5rem 0;
      font-size: 1.5rem;
      padding: 1.5rem 2rem;
      border: 1px solid $gray-g;
      border-radius: 8px;
    }

    input {
      display: flex;
      align-items: center;
      height: 5rem;
    }

    textarea {
      resize: none;
      height: 135px;
    }

    .recaptcha {
      margin-bottom: 12px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    input {
      width: calc(50% - 0.5rem);
      padding: 1.5rem 2rem;
      font-size: 1.5rem;
    }
  }

  .free-consultation {
    font-size: 1.6rem;
    font-weight: 700;
    background: $reverse-gradient;
    width: 180px;
    height: 50px;
    padding: 12px 0;
    border-radius: 4px;
  }

  .form-image {
    border-radius: 7px;
    overflow: hidden;
    margin-left: 2rem;
    display: none;
  }

  .subscribe-input-container {
    display: flex;
    align-items: center;

    input {
      margin-top: 0.3rem;
    }
  }
}

@include tablet-portrait {
  .form {
    form {

      input,
      textarea {
        margin-right: 0;
      }

      .row {
        flex-direction: column;

        input {
          width: unset;
        }
      }
    }
  }
}

@media (min-width: 748px) {
  .form {
    form {
      width: 55%;
    }

    .form-image {
      display: unset;
      max-width: 40%;
    }
  }
}

@include tablet-landscape-sm {
  .form {
    // flex-direction: column;

    form {
      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        input {
          width: calc(50% - 0.5rem);
          padding: 1.5rem 2rem;
          font-size: 1.5rem;
        }
      }
    }
  }
}

@include desktop {
  .form {
    form {
      width: 55%;

      .form-header {
        font-size: 4rem;
      }
    }
  }

  .form-image {
    max-width: 40%;
  }
}