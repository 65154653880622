.reading-page {
  &__advantages-wrapper {
    padding: 8rem 0 0;
  }

  &__heading {
    @include blue-heading;
    text-align: center;
  }

  &__sub-heading {
    @include sub-heading;
    text-align: center;
    margin-bottom: 0;
  }

  &__heading,
  &__sub-heading {
    padding: 0 3.5rem;
  }

  &__cta-btn {
    background-color: #f18558;
    border-radius: 4px;
    font-size: 1.6rem;
    color: #ffffff;
    width: 18rem;
    height: 5rem;
    padding: 1.2rem 0;
    font-weight: 700;
    margin-bottom: 4rem;
    // margin-top: 4rem;
  }

  &__skills-wrapper {
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $gray-f;

    .english-skills__skill-wrapper {
      width: 84%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: wrap;

      .english-skills__skill-card {
        width: 45%;

        * {
          font-size: 1.6rem;
        }
      }
    }
  }
}

@include tablet-portrait {
  .reading-page {
    &__skills-wrapper {
      padding: 4rem 0;
    }

    &__sub-heading {
      margin-bottom: 5rem;
    }
  }
}

@include tablet-landscape-sm {
  .reading-page {
    &__sub-heading {
      width: 84%;
      margin: 3rem auto 5rem;
      padding: 0rem 2rem;
    }
  }
}

@include desktop {
  .reading-page {
    &__heading {
      font-size: 2.5rem;
    }

    &__sub-heading {
      font-size: 4.5rem;
    }
  }
}

// ! selling points
.selling-points {
  padding: 5rem;

  &__heading {
    @include blue-heading;
    text-align: center;
  }

  &__sub-heading {
    @include sub-heading;
    text-align: center;
    margin-bottom: 5rem;
  }

  &__card {
    overflow: hidden;
    border: 1px solid gainsboro;
    margin-bottom: 4rem;
    border-radius: 7px;
  }

  &__card-content-wrapper {
    padding: 1rem 2rem 4rem;
  }

  &__img {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;

    img {
      width: 100%;
    }
  }

  &__card-number {
    text-transform: uppercase;
    color: #4c7bd7;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 3rem;
  }

  &__card-header {
    font-size: 2.8rem;
    margin-bottom: 3rem;
  }

  &__card-content {
    font-size: 16px;
    color: #5d6168;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 2rem;
  }
}

@include tablet-portrait {
  .selling-points {
    &__heading {
      font-size: 2rem;
    }

    &__card-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      // width: 84%;
    }

    &__card {
      margin: 0 auto;
      width: 60%;
      margin-bottom: 2rem;
    }
  }
}

@media (min-width: 800px) {
  .selling-points {
    padding: 10rem 5rem;

    &__card-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0 auto;
      width: 94%;
    }

    &__card {
      width: 30%;
      min-height: 53rem;
      margin: 0 1rem;
    }
  }
}

// @include desktop {
//   .selling-points {
//     padding-bottom: 10rem;

//     &__card-wrapper {
//       display: flex;
//       flex-direction: row;
//       justify-content: center;
//       margin: 0;
//     }

//     &__card {
//       width: 30%;
//       min-height: 53rem;
//       margin: 0 1rem;
//     }
//   }
// }

// ! let us help
.let-us-help {
  padding: 4rem 4rem 8rem;
  overflow: hidden;
  background-color: $gray-f;

  &__img {
    // width: 100%;
    padding: 0 3rem;
    height: auto;

    img {
      width: 100%;
      border-radius: 8px;
    }
  }

  &__content-wrapper {
    padding: 0 3rem;
  }

  &__heading {
    @include blue-heading;
    margin-top: 7rem;
  }

  &__sub-heading {
    @include sub-heading;
  }

  &__description {
    @include heading-content;
  }

  &__btn {
    background-color: #f18558;
    border-radius: 4px;
    font-size: 1.6rem;
    color: #ffffff;
    width: 18rem;
    height: 5rem;
    padding: 1.2rem 0;
    font-weight: 700;
    margin: 3rem 0 2rem;
  }
}

@include tablet-portrait {
  .let-us-help {
    padding: 4rem 8rem;
    display: flex;
    flex-direction: column;

    &__img {
      width: 90%;
      margin: 0 auto;
    }
  }
}

@include tablet-landscape-sm {
  .let-us-help {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 8rem;
    padding-bottom: 8rem;

    &__heading {
      margin-top: unset;
    }

    &__img {
      padding: unset;
      img {
        width: 100%;
      }
    }

    &__img,
    &__content-wrapper {
      width: 45%;
    }
  }
}

@include big-desktop {
  .let-us-help {
    padding: 8rem 16%;
  }
}

// ! reading method
.reading-method {
  padding: 4rem 0 14rem;

  &__heading,
  &__description {
    padding: 0 4rem;
  }

  &__heading {
    @include sub-heading;
    text-align: center;
  }

  &__description {
    margin-bottom: 2.5rem;
    @include heading-content;
    text-align: center;
  }

  &__graphic-wrapper {
    position: relative;
  }

  &__img {
    width: 100%;
    height: auto;
    padding: 0 1rem;

    img {
      width: 100%;
    }
  }

  &__step-heading {
    font-size: 2.4rem;
    margin-bottom: 1rem;
    line-height: 1.1;
    font-weight: 500;
  }

  &__step-description {
    // font-size: 1.5rem;
    line-height: 1.5;
  }

  &__step-a-d,
  &__step-a-and-b,
  &__step-c,
  &__step-d,
  &__step-e-p,
  &__step-j-l,
  &__step-m-p {
    width: 60%;
  }

  &__step-a-d {
    position: absolute;
    top: 7%;
    left: 8%;
  }

  &__step-a-and-b {
    position: absolute;
    top: 19.5%;
    left: 30%;
  }

  &__step-c {
    position: absolute;
    top: 35.5%;
    left: 8%;
  }

  &__step-d {
    position: absolute;
    top: 49.5%;
    left: 30%;
  }

  &__step-e-p {
    position: absolute;
    top: 64%;
    left: 8%;
  }

  &__step-j-l {
    position: absolute;
    top: 82%;
    left: 30%;
  }

  &__step-m-p {
    position: absolute;
    bottom: -4%;
    left: 8%;
  }
}

@include tablet-portrait {
  .reading-method {
    padding: 4rem 0 20rem;

    &__heading {
      font-size: 4rem;
    }

    &__description {
      font-size: 1.5rem;
      line-height: 1.5;
      padding: 0 6rem;
    }

    &__img {
      width: 60%;
      display: block;
      margin: 0 auto;
    }

    &__step-a-d,
    &__step-a-and-b,
    &__step-c,
    &__step-d,
    &__step-e-p,
    &__step-j-l,
    &__step-m-p {
      width: 40%;
    }

    &__step-a-d {
      left: 23%;
    }

    &__step-a-and-b {
      top: 20%;
      left: 40%;
    }

    &__step-c {
      left: 24%;
    }

    &__step-d {
      left: 40%;
    }

    &__step-e-p {
      left: 24%;
    }

    &__step-j-l {
      left: 40%;
    }

    &__step-m-p {
      left: 24%;
    }
  }
}

// @include tablet-landscape-sm {
//   .reading-method {
//
//   }
// }

@include big-tablet {
  .reading-method {
    &__description,
    &__heading {
      width: 84%;
      margin-left: auto;
      margin-right: auto;
    }

    &__description {
      font-size: 2rem;
    }

    &__img {
      display: block;
      margin: 0 auto;
      width: 80%;
    }

    &__step-a-d {
      top: 13%;
      left: 12%;
    }

    &__step-a-and-b {
      top: 24%;
      left: 52%;
      width: 25%;
    }

    &__step-c {
      top: 40%;
      left: 12%;
      width: 25%;
    }

    &__step-d {
      top: 57%;
      left: 52%;
      width: 25%;
    }

    &__step-e-p {
      top: 67%;
      left: 12%;
      width: 25%;
    }

    &__step-j-l {
      left: 52%;
      top: 90%;
      width: 25%;
    }

    &__step-m-p {
      bottom: -5%;
      left: 12%;
    }
  }
}

// @include big-desktop {
//   .reading-method {
//
//   }
// }

.consultation-widget__video-container-section {
  position: relative; /* Ensure the pseudo-element is positioned relative to this element */
  padding: 10px;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.9), #eefaff)/* Add the gradient as an overlay */
}



.consultation-widget__video-container {
background-color: white;
  padding: 1rem; // Padding around the content
  margin: 10px auto 0px; // Center it horizontally
  border-radius: 10px; // Rounded corners
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(255,255,255,0.6);
  max-width: 400px; // Maximum width of the container
  width: 100%; // Ensures it takes up 100% of the container width
  position: relative; // Relative for positioning children absolutely
  padding-top: 28.25%; // Maintain aspect ratio (16:9)
  height: 0; // Collapse the container to just fit the player
  border: 7px solid #f47c48;



  @media (max-width: 768px) {
    max-width: 95%; // Allows the container to fit smaller screens
    margin: 10px;
    padding: 10px;
    min-height: 400px;
  }
}

.consultation-widget__video-heading {
 

  text-align: center;
  padding: 10px 0; // Padding at top of the container
  font-size: 2.6rem;
  font-family: "Fellix-Bold";
  z-index: 10;
  margin-bottom: 0px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

@media (max-width: 768px) {
  .consultation-widget__video-container {
    padding: 0;
    .consultation-widget__video-heading {
      font-size: 1rem;
    }
  }
}

.react-player__shadow {

  box-shadow: 0 0 8px white; /* Optional: adds a glow effect */
  border: 2px solid white; /* Add a white outline */

}


.react-player__play-icon {

}


.reading-skills-section {
  padding: 40px;
  font-family: "Fellix-Bold";
  max-width: 700px;
  margin: auto;

  h2 {
    font-family: "Fellix-Bold";
    text-align: center;
    margin: auto;
    margin-bottom: 30px;
    font-size: 2.3rem;
  }

  ul {
    margin: auto;
    margin-left: 10px;

  }

  li {
    margin: auto;
    margin-bottom: 20px;
    font-family: "Fellix-SemiBold";
    font-size: 1.8rem;
    line-height: 2.0;
  }

}


@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');



