// ! tutoring programs ================================================
.math-page {
  &__tutoring-programs {
    padding: 8rem 4rem 1rem;
  }

  &__heading {
    @include sub-heading;
    text-align: center;
    margin: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 6rem;
  }

  &__content-reverse {
    display: flex;
    flex-direction: column-reverse;
  }

  &__program-header {
    color: #333333;
    font-weight: 700;
    font-size: 2.3rem;
    margin: 4rem 0 2rem;

    &--number {
      color: #4c7bd7;
      font-weight: 700;
      font-size: 2rem;
    }
  }

  &__program-content {
    width: 85%;
    margin: 0 auto;
  }

  &__program-info,
  &__program-benefits-content {
    font-size: 1.6rem;
    color: #5d6168;
    font-weight: 500;
    letter-spacing: 0.2px;
    padding: 1rem 4rem 0 0;
    margin-bottom: 3rem;
  }

  &__program-benefits-icon {
    margin-right: 1rem;
    color: #f18558;
  }

  &__01-img,
  &__02-img {
    width: 100%;
    border-radius: 7px;

    * {
      width: 100%;
      border-radius: 8px;
    }
  }
}

@include tablet-portrait {
  .math-page {
    &__tutoring-programs {
      padding: 8rem;
      text-align: center;
    }

    &__program-content {
      width: 100%;
      margin: unset;
      margin-bottom: 3rem;
      text-align: left;
    }

    &__program-benefits-content {
      margin-bottom: 1rem;
    }

    &__01-img,
    &__02-img {
      width: 75%;
      margin: 0 auto;
    }
  }
}

@include tablet-landscape-sm {
  .math-page {
    &__heading {
      margin-bottom: 3rem;
    }

    &__tutoring-programs {
      padding: 7rem 10rem;
      text-align: unset;
    }

    &__program-content {
      // margin: 0 5rem;
      margin-bottom: unset;
      width: 45%;
    }

    &__content {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: unset;
    }
    &__content-reverse {
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
      margin-bottom: unset;
    }

    &__program-header {
      &--number {
        margin: 0rem 0 2rem 0;
      }
    }

    &__02-img,
    &__01-img {
      margin: unset;
      width: 45%;
      height: auto;
    }
  }
}

@include desktop {
  .math-page {
    &__tutoring-programs {
      padding: 12rem 10rem;
    }
    &__heading {
      margin-bottom: 6rem;
    }
    &__content {
      margin-bottom: 6rem;
    }

    &__01-img,
    &__02-img {
      * {
        width: 85%;
        margin: 0 auto;
      }
    }
  }
}

@include big-desktop {
  .math-page {
    &__tutoring-programs {
      width: 70%;
      margin: 0 auto;
    }
  }
}

// ! levels of math
.levels-of-math {
  padding: 0 4rem 6rem;
  background-color: $gray-f;

  &__header {
    @include sub-heading;
    text-align: center;
    margin: unset;
    padding: 4rem 0 2rem 0;
  }

  &__sub-header {
    @include heading-content;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 6rem;
  }

  &__img-wrapper {
    padding: 2rem 0;

    img {
      width: 100%;
    }
  }

  &__img {
    width: 100%;

    img {
      width: 100%;
    }
  }
}

@include tablet-portrait {
  .levels-of-math {
    &__sub-header {
      padding: 0 4rem 6rem 4rem;
    }

    &__img-wrapper {
      padding: 2rem 5rem;
    }
  }
}

@include desktop {
  .levels-of-math {
    &__sub-header {
      padding: 0 6rem 6rem 6rem;
    }

    &__img-wrapper {
      padding: 2rem 30rem;
    }
  }
}

// ! meet our tutors
.meet-our-tutors {
  padding: 4rem;

  &__heading,
  &__sub-heading,
  &__info {
    text-align: center;
  }

  &__heading {
    @include blue-heading;
  }

  &__sub-heading {
    @include sub-heading;
  }

  &__info {
    @include heading-content;
  }

  &__tutor-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
}

@include tablet-portrait {
  .meet-our-tutors {
    padding: 6rem;

    &__heading {
      text-align: center;
      font-size: 2.5rem;
    }

    &__tutor-cards {
      flex-direction: unset;
      justify-content: center;
    }
  }
}
