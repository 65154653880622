// ! selling point
.selling-point {
  padding: 8rem 5rem;

  @include desktop {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8rem 15rem;
  }

  &__heading {
    @include blue-heading;
  }

  &__sub-heading {
    @include sub-heading;
  }

  &__content-wrapper {
    font-size: 1.6rem;
    color: #5d6168;
    font-weight: 500;
    letter-spacing: 0.2px;
    padding: 0 4rem 0 0;
    margin-bottom: 3rem;

    @include desktop {
      width: 50%;
      padding: 0;
      margin-right: 6rem;
    }
  }

  &__img {
    width: 100%;
    height: auto;
    border-radius: 7px;

    img {
      width: 100%;
      border-radius: 7px;
    }

    @include tablet-portrait {
      width: 75%;
    }
  }
}

// ! tutoring program
.tutoring-program {
  text-align: center;
  padding: 2rem 3rem;

  @include desktop {
    padding: 2rem 15rem;
  }

  &__heading {
    @include blue-heading;
  }

  &__sub-heading {
    @include sub-heading;
  }

  &__content {
    @include heading-content;
    margin-bottom: 6rem;
  }

  &__card-wrapper {
    @include tablet-portrait {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @include desktop {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__card {
    display: flex;
    align-items: center;
    border-radius: 7px;
    margin-bottom: 2rem;
    padding: 1rem 3rem;
    width: 31rem;
  }

  &__card-img {
    height: 4rem;
    margin-right: 2rem;

    img {
      height: 100%;
    }
  }

  &__card-heading {
    font-size: 1.6rem;
  }
}

// ! programs
.programs {
  padding: 4rem;

  @include tablet-portrait {
    padding: 2rem 8rem;
  }

  &__sub-heading {
    @include sub-heading;
    text-align: center;
  }

  &__img {
    width: 100%;
    height: auto;
    border-radius: 7px;

    img {
      width: 100%;
      border-radius: 7px;
    }

    @include tablet-portrait {
      width: 75%;
      align-self: center;
    }

    @include desktop {
      width: 50%;
      margin: 0 5rem;
    }
  }

  &__tag {
    padding: 0.5rem;
    width: 13rem;
    border-radius: 25px;
    color: #fff;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    margin: 3rem 0;

    @include desktop {
      margin: 0;
    }

    &--jk-grade-3 {
      background-color: #c84be8;
    }

    &--grade-4 {
      background-color: #53cce0;
    }

    &--grade-9 {
      background-color: #5755f9;
    }
  }

  &__heading {
    color: #333333;
    font-weight: 700;
    font-size: 2.3rem;
    margin: 4rem 0 1rem;
  }

  &__content-wrapper {
    @include desktop {
      width: 50%;
      margin-right: 4rem;
    }
  }

  &__content {
    font-size: 1.6rem;
    color: #5d6168;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  &__jk-grade-3 {
    @include desktop {
      display: flex;
      align-items: center;
    }
  }

  &__grade-4 {
    margin-top: 7rem;

    @include desktop {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }
  }

  &__grade-9 {
    @include tablet-portrait {
      padding-bottom: 4rem;
    }

    @include desktop {
      display: flex;
    }
  }
}
