.tutors {
  text-align: center;
  padding: 4rem;
  background-color: $gray-f;
  width: 100%;

  &__heading {
    @include blue-heading;
  }

  &__sub-heading {
    @include sub-heading;
    width: 90%;
    margin: 0 auto 1.5rem;
  }

  &__heading-info {
    @include heading-content;
    margin-bottom: 8rem;
  }

  &__heading,
  &__sub-heading,
  &__heading-info {
    padding: 0 8px;
  }

  &__tutor-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }

  &__tutor-card {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
    border-radius: 7px;
    height: 369px;
    background-color: white;
    margin-bottom: 2rem;
    width: 29.5rem;
    padding: 3rem;

    &--ellison {
      border-bottom: 2px solid #eab254;
    }

    &--natalie {
      border-bottom: 2px solid #e894ff;
    }

    &--rachel {
      border-bottom: 2px solid #53cce0;
    }
  }

  &__tutor-card-img {
    border-radius: 50%;
    height: 9rem;
    width: 9rem;

    img {
      height: 100%;
      border-radius: 50%;
    }
  }

  &__tutor-header-info-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6rem;
  }

  &__info-wrapper {
    margin-left: 2rem;
    text-align: left;
  }

  &__tutor-card-name {
    margin-bottom: 1rem;
    font-size: 1.8rem;
    color: rgb(51, 51, 51);
  }

  &__tutor-occupation {
    font-size: 1.6rem;
    color: #5d6168;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin-bottom: 1rem;
  }

  &__education-bio-icon {
    margin-right: 2rem;
  }

  &__education,
  &__bio {
    text-align: left;
    margin-bottom: 4rem;
    font-size: 1.6rem;
    color: #5d6168;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  .tutors__stars {
    width: 70%;

    * {
      width: 100%;
    }
  }
}

@include tablet-portrait {
  .tutors {
    &__heading-info {
      width: 85%;
      margin: 0 auto 3rem;
    }

    &__tutor-cards {
      flex-direction: unset;
      justify-content: center;
    }

    &__tutor-card {
      margin: 2rem 1.5rem;
    }
  }
}

@include desktop {
  .tutors {
    padding-top: 80px;
    padding-bottom: 17rem;

    &__heading {
      padding-bottom: 3rem;
      padding-top: 4rem;
    }

    &__tutor-cards {
      flex-direction: row;
      justify-content: center;
    }

    &__tutor-card {
      margin: 0 2rem;
    }

    &__heading-info {
      margin-bottom: 80px;
    }
  }
}

/* ---------- English Page Tutor different background colour ----------  */

.english__tutors {
  background-color: white;

  .tutors {
    background-color: inherit;
  }
}
