// ! blog page banner ================================================================
.blog-page {
  &__banner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__banner-img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    z-index: 1;
    filter: brightness(70%);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__banner-content {
    position: absolute;
    color: #ffffff;
    z-index: 1;
    width: 90%;
  }

  &__banner-heading {
    font-size: 3.6rem;
    z-index: 10;
    font-weight: 700;
    margin-top: 0;
    text-shadow: 2px 2px 2px black;
  }

  // ! blog page banner media queries ===================================================
  @include tablet-portrait {
    &__banner-heading {
      font-size: 5rem;
      margin-top: 2rem;
      margin-bottom: 0;
    }
  }

  @include desktop {
    &__banner-heading {
      margin-top: 3.5rem;
    }

    &__banner-content {
      top: 20%;
      padding: 0 7rem;
    }
  }

  @include big-desktop {
    &__banner-content {
      padding: 0 9rem;
    }
  }
}

.banner {
  &__upgrade-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 2.5rem 1.5rem;
    background: linear-gradient(170deg, rgb(101, 111, 219) 53%, rgb(81, 107, 238) 65%);
    color: white;
    align-items: center;
    margin-top: -2px;
    &--left {
      font-weight: bold;
      font-size: 1.7rem;
    }
    &--right {
      background: linear-gradient(-45deg, #ffffff, #ffffff, #ffffff); /* starting with a white gradient */
      color: #051057; /* deep blue text color */
      border-radius: 6px; /* rounded corners */
      font-size: 1.4rem; /* size of the text */
      text-transform: uppercase; /* uppercase text for emphasis */
      font-family: "Fellix-Bold"; /* custom bold font for the text */
      padding: 12px 25px; /* padding around the text */
      border: 2px solid #051057; /* deep blue border to match the text */
      transition: 0.3s ease-in-out all; /* smooth transition for all properties */
      &:hover {
        background: #051057; /* deep blue background on hover */
        color: #fff; /* white text on hover */
        border: 2px solid transparent; /* transition to a transparent border */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* subtle shadow for depth */
        transform: translateY(-2px); /* slight lift effect */
        cursor: pointer;
      }
    }
    
  }
}

.hide {
  opacity: 0.2;
}

.heading-opacity {
  opacity: 0.1;
}

.fade-banner {
  opacity: 0;
}

.main {
  display: flex;
  align-items: center;
  position: relative;
  &--mobile {
    height: 200px !important;
    background: linear-gradient(-45deg, #2132b5, #162d7e, #14174f);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
  }
}

.banner,
.main {
  width: 100vw;
  overflow: hidden;
  height: 200px;

  img {
    width: 100vw;
    min-height: 200px;
    object-fit: cover;
    height: 100%;
  }

  .banner__content {
    top: 24%;

    .banner__content--heading {
      font-size: 4.8rem;
    }
  }

  .main-banner__content {
    .banner__content--heading {
      font-size: $banner-font;
      padding-top: 0;
    }
  }

  .banner__content,
  .main-banner__content {
    position: absolute;
    margin-left: 8vw;
    color: #ffffff;

    h1 {
      // line-height: 1.55;
      padding-top: 75px;
      z-index: 1;
    }

    .banner__content--heading {
      font-weight: 700;
      text-shadow: 2px 2px 2px black;
    }

    i {
      font-size: 0.5rem;
    }
  }
}

// ! author and date ====================================
.author-date {
  display: flex;
  align-items: center;
  text-shadow: 2px 2px 2px black;

  &__author,
  &__date {
    font-size: $author-font;
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 2px 2px 2px black;
  }

  &__author {
    margin-right: 1rem;
  }

  &__date {
    margin-left: 1rem;
  }

  &__dot {
    font-size: 0.2rem;
  }
}

@media (max-width: 800px) {
  .main,
  .banner {
    .banner__content {
      top: 28%;
    }

    .main-banner__content,
    .banner__content {
      h1.banner__content--heading {
        font-size: 4rem;
        font-weight: 700;
        // position: absolute;
        top: 0;
        // text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;
      }
    }
  }
}

@media (max-width: 700px) {
  .main,
  .banner {
    .banner__content {
      top: 28%;
    }
  }
  .banner .banner__content,
  .banner .main-banner__content,
  .main .banner__content,
  .main .main-banner__content {
    margin-left: unset;
  }
}

// @media (min-width: 1200px) {
//   .main,
//   .banner {
//     // min-height: 350px;
//     // width: 150%;
//   }
// }

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
