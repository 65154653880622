.worksheets__seo-text {
  width: 84%;
  margin: 0 auto;
  text-align: center;
  * {
    margin-bottom: 8px;
  }

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    color: #5d6169;
    font-size: 18px;
    line-height: 1.45;
  }
}
