// ! selling points
.english-page {
  &__selling-points--image {
    width: 100%;
  }
  &__selling-points,
  &__selling-point-reverse {
    padding: 8rem 4rem 4rem;
  }

  &__selling-point-reverse {
    padding-top: 0;
    margin-bottom: 8rem;
  }

  &__selling-point-wrapper,
  &__selling-point-reverse-wrapper {
    margin-bottom: 4rem;
  }

  &__selling-point-content {
    margin-bottom: 4rem;
  }

  &__selling-point-reverse-content {
    margin-top: 2rem;
  }

  &__selling-point-number,
  &__selling-point-reverse-number {
    @include blue-heading;
    margin-bottom: 2rem;
  }

  &__selling-point-header,
  &__selling-point-reverse-header {
    @include sub-heading;
    margin-bottom: 3rem;
    margin-top: 0;
  }

  &__selling-point-info,
  &__selling-point-reverse-info {
    @include heading-content;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }

  &__btn {
    padding: 1.5rem 3rem;
    border-radius: 4px;
    font-size: 1.2rem;
    font-weight: 700;
    margin: 2rem 0 0;
    width: 55%;

    &--reading {
      background-color: #f18558;
    }

    &--writing {
      background-color: #dc659c;
    }
  }

  &__01-img,
  &__02-img {
    width: 100%;
    height: auto;
    border-radius: 10px;

    img {
      width: 100%;
      border-radius: 7px;
    }
  }

  &__selling-points--links {
    display: flex;
    flex-direction: column;
  }
}

@include tablet-portrait {
  .english-page {
    &__selling-points {
      width: 84%;
      margin: 0 auto;
    }

    &__selling-point-reverse-wrapper {
      width: 84%;
      margin: 0 auto;
    }

    &__selling-points,
    &__selling-point-reverse {
      padding-bottom: 2rem;
    }

    &__selling-point-reverse-content {
      margin-top: 6rem;
    }

    &__selling-point-number,
    &__selling-point-reverse-number {
      font-size: 2rem;
    }

    &__btn {
      margin: 2.5rem 2rem 2rem 0;
    }

    &__selling-points--links {
      flex-direction: row;
      width: 65%;
      justify-content: space-between;
      margin: 6rem 0 8rem;

      a {
        width: calc((100% - 20px) / 2);
      }

      .english-page__btn {
        margin: unset;
        width: 100%;
      }
    }
  }
}

@include tablet-landscape-sm {
  .english-page {
    &__selling-points {
      margin-top: 5rem;
    }

    &__selling-points,
    &__selling-point-reverse {
      padding: unset;
    }

    &__selling-point-wrapper,
    &__selling-point-reverse-wrapper {
      padding: 0rem 5rem;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__selling-point-content {
      margin-bottom: unset;
    }

    &__selling-point-content,
    &__selling-point-reverse-content {
      width: 100%;
      margin-right: 10rem;
    }

    &__selling-point-reverse-content {
      margin-right: 0;
      margin-left: 8rem;
      margin-top: unset;
    }

    &__btn {
      margin-right: 2rem;
    }

    &__01-img,
    &__02-img {
      img {
        width: 100%;
      }
    }

    &__02-img {
      margin-top: 4rem;
    }

    &__selling-points--links {
      width: 100%;
      margin: 4rem 0 2rem;

      a {
        width: calc((100% - 10px) / 2);
      }
    }
  }
}

@include desktop {
  .english-page {
    &__content {
      justify-content: space-between;
      width: 84%;
    }

    &__selling-point-content,
    &__selling-point-reverse-content {
      width: 45%;
    }

    &__selling-points--links {
      a {
        width: calc((100% - 20px) / 2);
      }
    }

    &__01-img,
    &__02-img {
      width: 45%;
    }
  }
}

@include big-desktop {
  .english-page {
    &__selling-points,
    &__selling-point-reverse-wrapper {
      width: 64%;
    }
  }
}

// ! english skills
.english-skills {
  padding: 4rem;
  background-color: #f6f7f9;

  &__header,
  &__sub-header {
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }

  &__header {
    color: #333333;
    font-weight: 700;
    font-size: 3.4rem;
    margin-bottom: 2rem;
  }

  &__sub-header {
    font-size: 1.8rem;
    color: #5d5c6d;
    line-height: 2.6rem;
    font-weight: 500;
    margin-bottom: 5rem;
  }

  &__skill-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 4rem;
  }

  &__skill-card {
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 3rem;
    padding: 2rem;
    background-color: #fff;
    border-radius: 7px;
    width: 30rem;
  }

  &__check-icon {
    margin-right: 1rem;
    color: #f18558;
  }

  &__skill {
    line-height: 2rem;
    font-size: 1.6rem;
    color: #5d6168;
    font-weight: 500;
    letter-spacing: 0.2px;
  }
}

@include tablet-portrait {
  .english-skills {
    &__skill-wrapper {
      flex-direction: unset;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__skill-card {
      margin: 1.5rem 1rem;
    }
  }
}

@include desktop {
  .english-skills {
    padding-bottom: 6rem;

    &__header {
      margin-top: 3rem;
    }

    &__skill-wrapper {
      justify-content: center;
      display: grid;
      grid-template-areas: 'a a a';
    }

    &__skill-card {
      align-items: flex-start;
      margin: 1rem;
    }

    &__check-icon {
      margin-top: 0.5rem;
      font-size: 2rem;
    }

    &__skill {
      font-size: 2rem;
    }
  }
}

@include big-desktop {
  .english-skills {
    padding-bottom: 8rem;
  }
}

// ! pricing cards
.pricing-cards {
  padding: 3rem;

  &__header {
    @include sub-heading;
    text-align: center;
    font-size: 2.3rem;
    padding: 0 1.5rem;
  }

  &__cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__card-wrapper {
    overflow: hidden;
    margin-bottom: 5rem;
    border-radius: 10px;
    border: 1px solid #c8cdd6;
  }

  &__card-img {
    width: 100%;
    height: auto;

    img {
      width: 100%;
    }
  }

  &__card-content {
    padding: 3rem;
  }

  &__card-tag {
    padding: 0.6rem 1rem;
    width: 50%;
    border-radius: 25px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-bottom: 2rem;

    &--elementary-school {
      background-color: #c84be8;
    }

    &--middle-school {
      background-color: #53cce0;
    }

    &--high-school {
      background-color: #5755f9;
    }
  }

  &__card-header {
    margin-bottom: 2rem;
    font-size: 2.4rem;
    font-weight: 700;
  }

  &__card-price {
    color: #f18558;
    letter-spacing: 0.2px;
    font-size: 3.4rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  &__per-hour {
    font-size: 1.7rem;
    font-weight: 100;
  }

  &__description {
    line-height: 1.5;
    font-weight: 500;
    font-size: 16px;
    color: #5d6168;
  }
}

@include tablet-portrait {
  .pricing-cards {
    &__header {
      width: 84%;
      margin-left: auto;
      margin-right: auto;
    }

    &__card-wrapper {
      width: 50%;
    }
  }
}

@include tablet-landscape-sm {
  .pricing-cards {
    &__cards-container {
      width: 84%;
      margin: 0 auto;
      flex-direction: unset;
      justify-content: space-evenly;
      padding-bottom: 5rem;
      height: 700px;
    }

    &__card-wrapper {
      width: calc((100% - 6rem) / 3);
      height: 100%;
      margin-bottom: unset;
    }
  }
}

@include desktop {
  .pricing-cards {
    padding: 10rem 6rem;

    &__header {
      width: 60%;
      margin: 0 auto;
      font-size: 3rem;
    }

    &__cards-container {
      padding-top: 6rem;
    }

    &__card-wrapper {
      width: 30%;
      margin: 5rem 2rem;
    }
  }
}

@include big-desktop {
  .pricing-cards {
    width: 84%;
    margin: 0 auto;
  }
}
