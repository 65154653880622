.downloads-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
 
  padding: -1rem 5rem 5rem 0rem;

  &__title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    align-self: flex-start;

  }

  &__sub-heading {
    font-size: 1.4rem;
    font-style: italic;
    margin-bottom: 4rem;
  }

  &__cards-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    overflow-y: hidden;
    width: 112%;
    padding-left: 4.5rem;

    .card__wrapper {
      margin-bottom: 2rem;
    }
  }
}

// ! downloads page media queries =============================================
@include tablet-portrait {
  .downloads-page {
    &__title {
      font-size: 3.5rem;
    }

    &__sub-heading {
      font-size: 1.5rem;
    }

    &__cards-container {
      flex-direction: row;
    }
  }
}

@include mobile {
  .downloads-page {
    padding-top: unset;
    &__cards-container {
      padding-left: unset;
    }
    &__title {
      margin-bottom: 2rem;
    }
  }
}
