.success {
  font-size: 3rem;
  text-align: center;
  padding-top: 10vh;
  padding-bottom: 30vh;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #50c878;
  color: white;
  font-weight: 500;
  text-shadow: 1px 1px 2px #333;

  &__text {
    margin-bottom: 30px;
  }

  &__bold {
    font-weight: 800;
    color: #50c878;
    background-color: white;
    padding: 12px 8px;
    border-radius: 4px;
    margin-bottom: 50px;
    text-shadow: 1px 1px 1px #50c878;
  }
}

@media (max-width: 867px) {
  .success {
    font-size: 2rem;
  }
}
