
.board {
    width: 100%;
    height: 100%;
}

.whiteboard {

    position: relative;
    height: 700px;
    box-shadow: 0px 3px 1px rgb(221, 221, 221), 0px -3px 1px rgb(231, 231, 231);
    
    &__use-label {
        font-family: "Fellix-SemiBold";
        margin-left: 12px;
    }
    &__tool {

        &--wrapper {
            position: absolute;
            top: 12px;
            left: 12px;
            z-index: 10;
            display: flex;
            align-items: center;
        }

        &--button {
            flex-direction: column;
            background-color: rgb(57, 69, 239);
            padding: 8px 20px;
            margin: 4px;
            border-radius: 8px;
            color: white;
            border: none;
            cursor: pointer;
            justify-content: center;
            align-items: center;
         
            font-family: "Fellix-Bold";
            transition: ease-in 0.2s;
        
            &:hover {
                background-color: rgb(56, 37, 231);
                transform: translateY(-1px);
            }
        }
       
    }
}