.blog-page {
  // ! cards ============================================================
  &__cards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem;
    padding-bottom: 0;
  }

  &__blog-card-wrapper {
    margin-bottom: 4rem;
  }

  &__blog-card-link {
    overflow-wrap: normal;
    overflow: hidden;
    cursor: pointer;
  }

  &__card-body {
    width: 20rem;
    height: 32rem;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 0.75rem;
  }

  &__blog-card-img-wrapper {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    height: 11rem;

    img {
      width: 100%;
    }
  }

  &__blog-card-img {
    min-width: 100%;
    max-width: 100%;
  }

  &__blog-card-info {
    background-color: $gray-f;
    min-height: 21vh;
    padding: 2rem;
    border-radius: 0 0 10px 10px;
    max-width: 100%;
  }

  &__blog-card-date {
    font-size: 1.4rem;
    color: $gray-b;
    text-transform: uppercase;
  }

  &__blog-card-title {
    color: #000000;
    font-size: 2rem;
    font-weight: 500;
    margin: 1rem 0;
  }

  &__download {
    width: 22rem;
    font-weight: 700;
    color: #fff;
    background-image: linear-gradient(-90deg, #f26931, #f15b49, #ee3f7a);
    background-color: #162947;
    border-radius: 4px;
    padding: 1.2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto auto 2.5rem;
  }

  // ! cards media queries ================================================
  @include tablet-portrait {
    &__cards-wrapper {
      flex-direction: unset;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 8rem;
    }

    &__blog-card-wrapper {
      margin: 0 1rem 1rem;
    }
  }

  @include desktop {
    &__cards-wrapper {
      flex-direction: unset;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 8rem;
      // border: 1px solid green;
      width: 90%;
      margin: 0 auto;
    }
  }

  @include big-desktop {
    &__cards-wrapper {
      // border: 1px solid red;
      width: 80%;
    }
  }

  //! blog post headings =================================================
  &__h4-heading {
    font-weight: 500;
    line-height: 1.1;
    font-size: 2rem;
    margin: 2.4rem 0;
    color: #333;
  }

  &__points {
    color: #5d6169;
    font-size: 1.8rem;
    line-height: 1.45;
    list-style: disc;
    margin-left: 3rem;

    li {
      margin-bottom: 0.75rem;
    }
  }

  // ! blog post content ====================================================
  &__article {
    width: $container-width;
    margin: 0rem auto 0;

    p {
      color: $gray-c;
      line-height: 2.5rem;
      margin-bottom: 1rem;
      font-size: 1.8rem;
    }

    h3 {
      margin: 0 0 2.4rem;
      font-size: 2.4rem;
    }

    &--quote {
      font-style: italic;
      padding: 0 !important;
    }

    &--bold {
      font-weight: 900;
      text-align: center;
    }
  }

  // ! blog post content media queries ==========================================
  @include desktop {
    &__article {
      padding: 1rem 20rem;
    }

    &__article-content {
      padding: 5rem 0;
    }

    &__articles-wrapper {
      padding-top: 8rem;
    }
  }

  @include big-desktop {
    &__articles-wrapper {
      padding: 10rem 20rem;
    }
  }

  // ! sharing icons ==========================================
  &__sharing {
    padding: 2.4rem 0;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // ! sharing icons media queries ==========================================
  @include desktop {
    &__sharing {
      position: absolute;
      flex-direction: column;
      top: 52rem;
      left: 2%;
    }
  }

  // ! side links ============================================================
  &__side-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 2rem;
    padding: 1rem 2rem 2rem;
    background-color: #ffffff;
    box-shadow: $box-shadow;
    border-radius: 10px;
    width: 12% !important;
    z-index: 1;
  }

  &__side-content--mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    padding: 1rem 2rem 0;
    background-color: #ffffff;
    box-shadow: $box-shadow;
    border-radius: 10px;
    width: 70vw;
    // width: 12% !important;
    z-index: 1;

    & .blog-page__share-icons-wrapper {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    & .blog-page__side-links {
      width: 70%;

      .internal-links {
        width: 100%;
        display: flex;
        justify-content: space-around;

        & .internal-links__link-wrapper {
          align-items: flex-start;
        }
        & .internal-links__name {
          margin-bottom: 1rem;
        }
      }
    }
  }

  // &__side-links {
  //   display: none;
  // }

  &__free-consult-button {
    display: none;
  }

  @include desktop {
    &__side-links {
      display: unset;
      width: 100%;
    }

    &__side-content {
      width: 10%;
      position: fixed;
      top: 4rem;
      left: 1%;
      flex-direction: column;
      margin: 1rem 3rem;

      &--fix-top {
        position: absolute;
        top: 3rem;
      }

      &--fix-bottom {
        position: absolute;
        bottom: 42.5rem;
        top: auto;
      }
    }

    &__free-consult-button {
      display: unset;
      @include cta-btn;
      width: 100%;
      cursor: pointer;
    }

    &__share-icons-wrapper {
      width: 100%;
    }
  }

  @include big-desktop {
    &__side-links {
      display: unset;
      width: 100%;
    }

    &__side-content {
      width: 10%;
      position: fixed;
      top: 4rem;
      left: 5%;
      flex-direction: column;
      margin: 1rem 3rem;

      &--fix-top {
        position: absolute;
        top: 3rem;
      }

      &--fix-bottom {
        position: absolute;
        bottom: 42.5rem;
        top: auto;
      }
    }
  }
}

.blog-container {
  position: relative;
  overflow-x: hidden;
}

.blog__graphic {
  width: 100%;

  img {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .blog-page {
    &__article {
      h3 {
        margin: 4rem 0 2.4rem;
      }
    }
    &__side-content {
      display: none;
    }
  }
}
