// ! =================== advantages section =========================
.advantages {
  margin-top: 80px;
  width: 100%;
  padding-left: 16%;

  &--home__wrapper {
    width: 80%;
  }

  &__icon {
    height: 8rem;
    width: 8rem;
    margin-bottom: 4rem;

    img {
      height: 100%;
    }
  }

  &--reading {
    width: 60%;

    .advantages__icon {
      margin-bottom: 2rem;
    }

    &__wrapper {
      // @include tablet-portrait {
      //   display: flex;
      //   align-items: center;
      //   padding: 0 4rem;
      // }

      @include desktop {
        height: 20rem;
        margin: 1.5rem;
        padding: 0;
      }
    }
  }

  &--reading {
    @include desktop {
      grid-template-areas: 'a a';
    }

    &__wrapper {
      @include desktop {
        width: 50rem;
      }
    }
  }

  &__icon {
    @include tablet-portrait {
      margin: 0 3rem 3rem 0;
    }

    @include desktop {
      align-self: flex-end;
      margin-top: 3rem;
    }
  }

  &__heading {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #333333;
  }

  &__text {
    margin-bottom: 5rem;
    font-size: 1.6rem;
    color: $gray-c;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 2.2rem;

    @include desktop {
      margin-bottom: 0;
    }
  }
}

@include tablet-portrait {
  .advantages {
    padding-left: 12%;
    margin-top: 100px;
    margin-bottom: 120px;

    &--reading {
      width: 84%;
      margin: 0 auto;
      padding-left: unset;
      padding-bottom: 6rem;

      &__wrapper {
        display: flex;
        align-items: center;
        padding: 0rem 6rem;
      }
    }
  }
}

@include tablet-landscape-sm {
  .advantages {
    padding-left: unset;
  }

  .advantages--home {
    width: 84vw;
    margin: 40px auto 40px auto;

    // display: flex;
    padding-left: unset;

    &__wrapper {
      padding: 0 30px;
    }
  }

  .advantages--reading {
    width: 84%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__wrapper {
      width: 45%;
      padding: unset;
      // display: flex;
      // justify-content: space-between;
      // align-items: center;

      .advantages__icon {
        align-self: center;
      }
    }
  }
}

@include big-tablet {
  .advantages.advantages--home {
    display: flex;
  }
}

@include desktop {
  .advantages.advantages--home {
    margin-bottom: 80px;
  }

  .advantages--reading {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 6rem;

    &__wrapper {
      width: 45%;
      margin: unset;
    }
  }
}

// ! your personalized learning journey
.journey {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;

  &__right-side {
    width: 90%;
  }

  &__heading {
    text-align: center;

    @include sub-heading;
  }

  &__header-img {
    height: 14.3rem;
    width: 25.5rem;
    border-radius: 7px;
    margin: 0 auto 3rem;

    img {
      height: 100%;
      width: 100%;
      border-radius: 7px;
    }
  }

  &__steps {
    display: flex;
    align-items: flex-start;
  }

  &__number-wrapper {
    width: 5rem;
  }

  &__number {
    font-size: 5rem;
    color: #f18659;
    font-weight: 700;
    margin-right: 4rem;
  }

  &__steps-content-header {
    font-weight: 700;
    font-size: 2rem;
    color: rgb(51, 51, 51);
    margin: 1rem 0;
  }

  &__steps-content-info {
    margin-bottom: 5rem;
    font-size: 1.6rem;
    color: #5d6168;
    font-weight: 500;
    letter-spacing: 0.2px;
  }
}

@include tablet-portrait {
  .journey {
    padding: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &__left-side {
      height: 100%;
      margin-right: 3rem;
    }

    &__right-side {
      display: flex;
      flex-direction: column;
      margin-left: 3rem;
    }

    &__heading {
      margin-top: 0;
      margin: 0 auto 4rem;
    }

    &__header-img {
      width: 90%;
      height: auto;
    }

    &__steps {
      margin-bottom: 16px;
    }

    &__steps-content {
      padding-right: 4rem;
    }
  }
}

@include desktop {
  .journey {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 8rem 10rem 12rem;

    &__heading {
      margin-top: 0;
      text-align: left;
      padding: 0 8rem 0 4rem;
    }

    &__header-img {
      width: 100%;
      height: auto;
    }

    &__steps {
      margin-bottom: 7.4rem;

      &:last-child {
        margin-bottom: 0;
      }

      &:first-child {
        margin-top: 5rem;
      }
    }

    &__steps-content-info {
      margin-bottom: 1.5rem;
    }
  }
}

@include big-desktop {
  .journey {
    padding: 7rem;

    &__heading {
      margin-left: 8rem;
    }

    &__header-img {
      width: 70%;
    }
  }
}

// ! advanced e-learning with lesson space
.advancedLearning {
  background-image: url(https://nft-image-pool.s3.us-east-2.amazonaws.com/homepage/lesson-space-bg-blue.png);
  background-size: cover;
  background-position: -40% 0%;
  color: #fff;
  padding: 4rem;

  &__header {
    @include sub-heading;
    color: #fff;
    text-align: center;
  }

  &__sub-header {
    margin-bottom: 3rem;
    font-size: 2rem;
    line-height: 2.8rem;
  }

  &__benefit {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }

  &__benefit-icon {
    color: #f18558;
    margin-right: 1rem;
    font-size: 1.5rem;
  }

  &__benefit-text {
    font-size: 1.6rem;
  }

  &__img-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }

  &__img {
    margin-top: 2rem;
    width: 90%;

    img {
      width: 100%;
    }
  }
}

@include tablet-portrait {
  .advancedLearning {
    padding: 6rem 10rem;
    background-position: unset;

    &__header {
      font-size: 4rem;
      text-align: left;
    }
  }
}

@include tablet-landscape-sm {
  .advancedLearning {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__header {
      margin-bottom: 2rem;
      text-align: left;
      width: 75%;
    }

    &__sub-header {
      width: 80%;
    }

    &__img-wrapper {
      width: 65%;
      margin-bottom: 15%;
    }
  }
}

@include desktop {
  .advancedLearning {
    &__content-wrapper {
      flex-direction: row;
    }
  }
}

@include big-desktop {
  .advancedLearning {
    padding: 6rem 8rem;

    &__header {
      margin-bottom: 3rem;
    }

    &__sub-header {
      width: 95%;
    }

    &__img {
      img {
        width: 100%;
      }
    }

    &__img-wrapper {
      width: 50%;
      margin-bottom: unset;
    }
  }
}

// ! subject cards
.subjects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 0 4rem;

  &__label {
    font-weight: 700;
    font-size: 1.8rem;
    margin-top: 10%;
  }

  &__link {
    text-decoration: none;
    color: #333333;
  }

  &__header {
    @include blue-heading;
    margin: 4rem 0;
  }

  &__icon-card {
    background-color: #f6f7f9;
    border-radius: 7px;
    text-align: center;
    margin-bottom: 3rem;
    padding: 3rem;
    width: 15rem;
    height: 13rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    width: 4rem;

    img {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}

@include tablet-portrait {
  .subjects {
    padding-bottom: 100px;

    &__header {
      font-size: 2rem;
      padding: 2rem 0;
      margin-bottom: unset;
    }

    &__icon-cards-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__icon-card {
      margin: 1.5rem 1.5rem 0 1.5rem;
    }
  }
}

@include tablet-landscape-sm {
  .subjects {
    padding-bottom: 100px;

    &__icon-card {
      width: 20rem;
      height: 20rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

@include desktop {
  .subjects {
    padding-bottom: 100px;

    &__header {
      padding: 2rem 0;
      margin-bottom: unset;
      margin-top: 100px;
    }

    &__icon-cards-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

// ! location cards
.locations {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 4rem 11rem;
  background-color: $gray-f;

  &__header {
    @include blue-heading;
    margin: 4rem 0;
  }

  &__location-card {
    height: 22rem;
    width: 20.3rem;
    text-align: center;
    // padding-top: 15rem;
    margin-bottom: 3rem;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    // align-items: center;
    position: relative;
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;

    // &--new-york {
    //   background-image: url('https://nft-image-pool.s3.us-east-2.amazonaws.com/homepage/New-york.png');
    // }

    // &--florida {
    //   background-image: url('https://nft-image-pool.s3.us-east-2.amazonaws.com/homepage/Florida.png');
    // }

    // &--california {
    //   background-image: url('https://nft-image-pool.s3.us-east-2.amazonaws.com/homepage/California.png');
    // }

    // &--ontario {
    //   background-image: url('https://nft-image-pool.s3.us-east-2.amazonaws.com/homepage/Ontario.png');
    // }

    // &--bc {
    //   background-image: url('https://nft-image-pool.s3.us-east-2.amazonaws.com/homepage/bc.png');
    // }

    .location__image--background {
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 0;
      border-radius: 8px;

      * {
        z-index: 0;
        border-radius: 8px;
      }
    }
  }

  &__location-label {
    color: #fff;
    font-weight: 700;
    font-size: 1.8rem;
    margin-top: 70%;
    width: 100%;
    height: 100%;
    z-index: 27;
  }
}

@include tablet-portrait {
  .locations {
    &__header {
      font-size: 2rem;
      padding-top: 40px;
    }

    &__card-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    &__location-card {
      margin: 2rem 1.5rem;
    }
  }
}

@include tablet-landscape-sm {
  .locations {
    padding: 4rem 6rem 11rem;
  }
}

@include desktop {
  .locations {
    &__card-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    &__location-card {
      margin: 3rem 1rem;
    }
  }
}

// ! pricing cards
.pricing {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4rem;

  &__header {
    @include blue-heading;
    margin: 4rem 0 0;
  }

  &__sub-header {
    @include sub-heading;
    text-align: center;
  }

  &__info-text {
    @include heading-content;
    text-align: center;
    margin-bottom: 4rem;
  }

  &__cards-wrapper {
    padding: 2rem;
  }

  &__card {
    position: relative;
    border-radius: 7px;
    border: 2px solid #d7dadf;
    padding: 1rem 3rem;
    width: 35rem;
    margin-bottom: 7rem;
    text-align: center;
  }

  &__card-header {
    @include blue-heading;
    font-size: 1.5rem;
    border-radius: 24px;
    margin: 4rem auto 2rem auto;
    background-color: $gray-f;
    padding: 1rem;
    width: 50%;
  }

  &__img {
    height: 6rem;
    background-color: #fff;
    position: absolute;
    top: -30px;
    left: 100px;
    padding: 0 3rem;

    img {
      height: 100%;
    }
  }

  &__title {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }

  &__price {
    color: #f18558;
    letter-spacing: 0.2px;
    font-size: 3.4rem;
    margin-bottom: 3rem;

    &--hour {
      font-size: 1.6rem;
    }
  }

  &__benefit-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__benefit-logo {
    margin-right: 1rem;
    color: #f18558;
  }

  &__benefit-text {
    font-size: 1.6rem;
  }

  &__cta-btn {
    @include cta-btn;
    margin-bottom: 6rem;
    height: 5rem;
  }
}

@include tablet-portrait {
  .pricing {
    padding: 4rem 0;

    &__header {
      font-size: 2rem;
    }
  }
}

@include tablet-landscape-sm {
  .pricing {
    padding: 4rem 0 0 0;

    &__cards-wrapper {
      width: 80%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }
}

@include desktop {
  .pricing {
    &__sub-header {
      margin: 4rem 0 2rem 0;
    }

    &__cards-wrapper {
      width: 90%;
      margin-bottom: 4rem;
    }

    &__card {
      margin: 0 1.5rem;
    }
  }
}

@include big-desktop {
  .pricing {
    &__cards-wrapper {
      justify-content: center;
    }
  }
}

// ! online review section
.online-review {
  width: 100%;
  padding: 8rem 0 12rem 0;
  display: flex;
  justify-content: center;
  background-color: $gray-i;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  &__widget {
    width: 75%;
    border-radius: 10px;
    background-color: white;
  }

  &__header-wrapper {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    background-color: #133a88;
    padding-top: 3rem;
  }

  &__header {
    margin-bottom: 1rem;
  }

  &__review-count {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__count-rating-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 2rem 0 3rem;
    padding: 0 3rem 1rem;
    border-bottom: 1px solid #b4c8ee;
    width: 90%;
  }

  &__rating,
  &__count {
    font-weight: 700;
    font-size: 1.6rem;
  }

  &__review-container {
    height: 50rem;
    overflow-y: scroll;
  }

  &__date {
    color: #999999;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  &__name {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    color: #333333;
  }

  &__star-rating {
    margin-bottom: 0.5rem;
  }

  &__review-content {
    display: flex;
    justify-content: center;
  }

  &__flex-wrapper {
    display: flex;
    justify-content: center;
    padding: 2.5rem 1.5rem;
    border-bottom: 1px solid #cfcfcf;
  }

  &__google-icon {
    margin-right: 2rem;

    img {
      height: 3rem;
    }
  }

  &__review {
    color: #333333;
    font-size: 1.75rem;
  }
}

@include tablet-portrait {
  .online-review {
    &__count-rating-wrapper {
      padding: 0 15rem 1rem;
    }
  }
}

@include desktop {
  .online-review {
    &__header-wrapper {
      flex-direction: row;
      justify-content: space-between;
      padding: 2rem;
    }

    &__header {
      margin: 0;
    }

    &__review-count {
      flex-direction: row;
      width: auto;
    }

    &__count-rating-wrapper {
      border-bottom: 0;
      margin: 0;
      padding: 0 0 0 0.75rem;
    }

    &__rating {
      display: none;
    }

    &__flex-wrapper {
      justify-content: flex-start;
    }
  }
}
