.bg-ai-tutor {
  position: relative;
  text-align: center;
  background-color: #eec0c6;
  background-image: linear-gradient(315deg, #7554ed 0%, #3264c1 74%);
  height: 100vh;
  overflow: hidden;
  font-family: "Fellix-Bold";

}

.message-limit-info {
  color: #fff;
 
  padding: 10px;
  border-radius: 5px;

  text-align: center;
  font-size: 1.3rem;
  z-index: 10000;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: 0;
  top: -28px;
  font-family: "Fellix-SemiBold";

  &--row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &--text {
    font-family: "Fellix-SemiBold";

  }
}

.message-limit-upgrade-link {
  color: #fff;
 text-decoration: underline;
 cursor: pointer;
margin-left: 7px;

  text-align: center;
  font-size: 1.3rem;
  z-index: 10000;

  font-family: "Fellix-Bold";
  transition: all 0.2s ease-in;

  &:hover {
    transform: translateY(-2px);
  }
}

.message-limit-info--link {
  color: #fff;
 text-decoration: underline;
 cursor: pointer;
  font-size: 1.3rem;
  z-index: 10000;
  font-family: "Fellix-Bold";
  transition: all 0.2s ease-in;

  &:hover {
    transform: translateY(-2px);
    font-size: 1.4rem;
  }
}





/* Animated background gradient */
@keyframes moveGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.bg-ai-tutor {
  background-size: 200% 200%;
  animation: moveGradient 3s ease infinite;
}

.bg-ai-tutor::before,
.bg-ai-tutor::after {
  content: '';
  position: absolute;
  width: 140px;
  height: 140px;
  background: rgba(255, 255, 255, 0.6); /* Semi-transparent white */
  border-radius: 50%;
  animation: blob 4s infinite;
  z-index: 0; /* Ensure it's behind your chat interface */
}

.bg-ai-tutor::before {
  top: -50px; /* Position towards the top edge */
  left: -50px; /* Position towards the left edge */
}

.bg-ai-tutor::after {
  top: -100px; /* Position towards the bottom edge */
  right: -100px; /* Position towards the right edge */
  width: 200px; /* Larger blob */
  height: 200px; /* Larger blob */
  animation-duration: 5s; /* Slower animation for variation */
}

/* Adjust the keyframes for blob animation to ensure they move as expected */
@keyframes blob {
  0%, 100% {
    border-radius: 50%;
  }
  25% {
    border-radius: 60% 40% 34% 66% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 70% 50% 50% / 76% 34% 66% 24%;
  }
  75% {
    border-radius: 58% 42% 51% 49% / 38% 60% 40% 62%;
  }
}



@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    25%, 75% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .share-button.copied-2 {
    animation: bounce .3s ease-out;
    background-color: #27ae60 !important;
  }
  
  .share-button.copied-2:hover {
    background-color: #229954 !important;
  }
  

.chat-actions {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
  }
  
  .share-button {
    padding: 8px 16px;
    border: none;
    background-color: #191919;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    border-radius: 20px;
    cursor: pointer;
    font-family: "Fellix-Bold";
  }
  
  .share-button:hover {
    background-color: #000;
  }

  @import url('https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap'); /* Import a fun, bold font */

  .h1-ai {
    display: inline-block;
    font-size: 2.4rem; /* Increased font size */
    font-weight: bold;
    margin: 10px auto 0;
    background: -webkit-linear-gradient(45deg, #000000 30%, #000000 90%); /* Gradient text color */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2); /* Subtle text shadow */
    font-family: 'Bungee Shade', cursive; /* Fun, bold font for impact */
    animation: pulse 5s infinite; /* Add a pulse animation */
    transition: transform 0.2s; /* Smooth transition for hover effect */
  }
  
  .h1-ai:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
  
  /* Keyframes for the pulse animation */
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1); /* Pulse to a larger size */
    }
    100% {
      transform: scale(1);
    }
  }
  

.title-image {
    display: inline-block;
    height: 70px;
    width: 70px;
    background-image: url('https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/popcorn.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 10px;
    vertical-align: middle;
}

// .assistant-message::before {
//     content: '';
//     display: inline-block;
//     height: 40px;
//     width: 40px;
//     background-image: url('https://nft-image-pool.s3.us-east-2.amazonaws.com/main-site-games/popcorn.png');
//     background-repeat: no-repeat;
//     background-size: contain;
//     margin-right: 4px;
//     vertical-align: middle;
// }

.chat-ai {
    border: 2px solid #00264e;
    background-color: #f7f7f7;
    padding: 0px 16px;
    border-radius: 4px;
    max-width: 90%;
    margin: 0 auto;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.7);
    overflow: hidden;
    height: 59vh;
    margin-top: 1vh;
    max-width: 1100px;
}

.chat-ai ul {
    background-color: white;
    
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
    list-style-type: none;
    padding: 20px;
    margin: auto;
   
    height: 100%; /* Subtract the margin-top space from height */
    overflow-y: scroll;
   /* Add space above the chat history */
    max-width: 800px;
    
}

.chat-ai li {
    margin-bottom: 20px;
    clear: both;
    overflow: hidden;
}

.feedback-message-chat {
  color: #d8000c;
  background-color: #ffbaba;

  padding: 10px;
  border-radius: 5px;

  text-align: center;
height: 40px;
}

.feedback-message-chat p {
  font-family: "Fellix-Bold";
}
.user-message {
    text-align: left;
    background-color: #00264e;
    font-size: 2rem;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
    color: white;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 4px;
    float: right;
    max-width: 70%;
    font-family: "Fellix-SemiBold";
}

.assistant-message {
    text-align: left;
    max-width: 70%;
    background-color: #f2f2f2;
    font-size: 2rem;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
    color: #333;
    margin-bottom: 20px;
    padding: 12px;
    border-radius: 4px;
    float: left;
    font-family: "Fellix-SemiBold";
}

.chat-ai form {
    position: absolute;
    top: calc(117px + 59vh);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: stretch;
    width: 90%;
    max-width: 1100px;
    height: calc(24vh - 20px); /* Subtract the margin-bottom space from height */
    margin-bottom: 10px; /* Add space below the text area */
}

.chat-ai textarea {
    flex-grow: 2;
    padding: 8px;
    border: 2px solid #00264e;
    border-radius: 4px;
    margin-right: 8px;
    font-size: 2rem;
    resize: none;
    outline: none;
    height: 87%;
    overflow: auto;
    font-family: "Fellix-SemiBold";
}

.chat-ai button {
    height: 87%;
    padding: 20px 40px;
    font-size: 2rem;
    min-width: 120px;
    background-color: #191919;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Fellix-Bold";
}

.chat-ai button:hover {
    background-color: #000;
}

.loading-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    margin: 10px 0;
  }
  
  .loading-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #2fcfe0;
    margin: 0 2px;
    animation: loading-dot-animation 1s infinite ease-in-out;
  }
  
  @keyframes loading-dot-animation {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  

@media (max-width:481px) {

  .user-message {
    max-width: 95%;font-size: 1.7rem
  }

  .assistant-message { max-width: 95%;
  font-size: 1.7rem}

  .chat-actions {
    position: absolute;
    top: 10px;
    right: 20%;
  }

  .h1-ai {

    margin-top: 45px;
    margin-left: 0px;
    line-height: 0;
    font-size: 1.6rem;
  }


    .chat-ai {
        max-width: 96% !important;
    }
    .chat-ai form {
        font-size: 1.4rem;
        margin-bottom: 0px !important;
        width: 97%;
        margin-top: 30px;
    }
    #messages {
width: 93%;
    }

    .message-limit-info {
      font-size: 1.2rem;
      padding: 3px;
      top: -21px
    }

    .message-limit-info--link {
      font-size: 1.2rem;
      padding: 0;
    }



    #message-input {
      font-size: 1.6rem;
    }

    .chat-ai button {
        padding: 10px 10px !important;
        min-width: 90px;
    }
}