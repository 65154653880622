// ! our pricing
.our-pricing {
  padding: 10rem 0 4rem;

  &__left-wrapper {
    padding: 0rem 6rem;
    margin-bottom: 3rem;
  }

  &__header {
    @include blue-heading;
  }

  &__sub-header {
    @include sub-heading;
  }

  &__info {
    @include heading-content;
  }

  &__cards-wrapper {
    padding: 1rem 3rem;
  }

  &__card {
    border-radius: 7px;
    margin-bottom: 4rem;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    background-color: #f6f7f9;

    &--elementary {
      border-left: 2px solid #c84be9;
    }

    &--middle {
      border-left: 2px solid #21cb9e;
    }

    &--high {
      border-left: 2px solid #5655f9;
    }
  }

  &__card-header {
    color: #333333;
    font-weight: 700;
    font-size: 1.8rem;
  }

  &__card-grade {
    text-transform: uppercase;
    color: #4c7bd7;
    font-size: 1.4rem;
    font-weight: 700;
    padding-top: 0.5rem;
  }

  &__card-cost {
    font-size: 2.1rem;
    color: #f18558;
    font-weight: 700;
    letter-spacing: 0.2px;
    align-self: flex-end;

    &--per-hour {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
}

@include tablet-portrait {
  .our-pricing {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5rem;

    &__left-wrapper {
      padding: 1rem 10rem;
      text-align: center;
    }

    &__cards-wrapper {
      padding: 3rem;
      width: 64%;

      .our-pricing__card {
        width: 100%;
      }
    }

    &__card-cost {
      font-size: 3rem;
    }
  }
}

@include tablet-landscape-sm {
  .our-pricing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin: 0 auto;

    &__header {
      margin-top: unset;
    }

    &__left-wrapper {
      padding: 1rem 0;
      width: 45%;
      text-align: left;
    }

    &__cards-wrapper {
      padding: 1rem 0;
      width: 45%;

      .our-pricing__card {
        padding: 2rem;

        &-cost {
          font-size: 3rem;
        }

        &-cost--per-hour {
          font-size: 1.5rem;
        }
      }
    }
  }
}

@include desktop {
  .our-pricing {
    display: flex;
    align-items: center;
    padding: 8rem 0rem;

    &__header {
      margin-top: unset;
    }

    &__left-wrapper {
      padding: 1rem 0;
      margin-right: 10rem;
      width: 50%;
      text-align: left;
    }

    .our-pricing__card.our-pricing__card--high {
      margin-bottom: unset;
    }
  }
}

@include big-desktop {
  .our-pricing {
    justify-content: center;
  }
}