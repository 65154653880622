@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Permanent+Marker&display=swap');

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  margin: 10px;
  border-radius: 10px;
  margin-left: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #327dff;
 
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #327dff;
}

.program-img {
  width: 100%;
  height: 130px;
  background-color: #53cce0;
  color: white;
  font-size: 4rem;
  font-weight: 800;
  line-height: 1.7;
  padding-top: 20px;
  letter-spacing: 7px;
  font-family: 'Indie Flower', cursive;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 #bbb;
  position: relative;
  text-align: center;

  &--text {
    font-family: 'DM Sans', sans-serif;
    font-size: 1.4rem;
    font-weight: 800;
    letter-spacing: 0px;
    color: #333;
    position: absolute;
    bottom: 8px;
    left: 8px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.programs {
  font-family: 'DM Sans', sans-serif;
  &__price {
    font-size: 1.6rem;
    color: #5d6168;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  &__each-lesson {
    font-weight: 800;
    margin-top: 10px;
  }

  &__lesson-container {
    margin-left: 17px;
    margin-right: 32px;
  }

  &__lesson-container-2 {
    width: 300px;
    margin-bottom: 30px;
    text-align: center;
  }

  &__lesson-heading {
    text-align: left;
    margin-bottom: 10px;
    font-weight: 800;
  }

  &__lesson-length-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__lesson-length {
    background-color: white;
    padding: 8px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 #cbdbdf;
    width: 80px;
    font-weight: 800;
    transition: 0.2s;

    &:hover {
      background-color: #50c878;
      box-shadow: 0 5px 15px #cbdbdf;
      color: white;
      cursor: pointer;
    }
    &--selected {
      background-color: #50c878;
      box-shadow: 0 5px 15px #cbdbdf;
      color: white;
      cursor: pointer;
      padding: 8px 12px;
      border-radius: 4px;
      width: 80px;
      font-weight: 800;
      transition: 0.2s;
    }
  }

  &__price-info-wrapper {
    font-weight: 800;
    width: 50%;
    text-align: right;
    padding-top: 7px;
  }
  &__price-number-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-right: 0px;
  }

  &__price-number {
    font-weight: 800;
    width: 73px;
    text-align: center;
    color: white;
    padding: 4px 8px;
    background-color: #50c878;
    border-radius: 4px;
    box-shadow: 0 5px 15px #50c878;
  }

  &__price-each {
    font-weight: 800;
    width: 73px;
    text-align: center;

    color: white;
    padding: 4px 8px;
    color: #50c878;
    border-radius: 4px;
    text-shadow: 0 0.2px 0.8px #50c878;
  }

  &__price-row-end {
    display: flex;
    justify-content: end;
  }

  &__price-payment-schedule {
    font-weight: 800;
    margin-top: 12px;
    width: 100px;
    margin-left: 23px;
    align-self: end;
  }

  &__price-payment-schedule-2 {
    font-weight: 800;
    margin-top: 7px;
    width: 100px;
    margin-left: 23px;
    align-self: end;
  }

  &__program-cards-container {
    display: flex;
    flex-direction: row;
    overflow: auto;
    width: 80vw;
    height: 483px;
    padding-top: 8px;
  }

  &__program-card {
    min-width: 330px;
    max-width: 330px;
    margin: 30px;
    margin-bottom: 17px;
  }
  &__program-card:first-child {
    margin-left: 7px;
  }
  &__program-card:last-child {
    margin-right: 4px;
  }
}

.programs {
  text-align: center;
  padding: 4rem;
  background-color: $gray-f;
  width: 100%;

  &__heading {
    @include blue-heading;
  }

  &__sub-heading {
    @include sub-heading;
    width: 90%;
    margin: 0 auto 1.5rem;
  }

  &__heading-info {
    @include heading-content;
    margin-bottom: 8rem;
  }

  &__heading,
  &__sub-heading,
  &__heading-info {
    padding: 0 8px;
  }

  &__program-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }

  &__program-card {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
    border-radius: 7px;
    height: 354px;
    background-color: white;
    margin-bottom: 2rem;
    width: 29.5rem;
    padding: 3rem;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      box-shadow: 1px 4px 7px 0 rgba(0, 0, 0, 0.5);

      transform: translateY(-7px);
    }

    &--ellison {
      border-bottom: 2px solid #eab254;
    }

    &--natalie {
      border-bottom: 2px solid #e894ff;
    }

    &--rachel {
      border-bottom: 2px solid #53cce0;
    }
  }

  &__tutor-card-img {
    border-radius: 50%;
    height: 9rem;
    width: 9rem;

    img {
      height: 100%;
      border-radius: 50%;
    }
  }

  &__program-header-info-wrapper {
    display: flex;
    align-items: center;
  }

  &__info-wrapper {
    margin-top: 1.7rem;
    text-align: left;
    width: 50%;
  }

  &__program-card-name {
    margin-bottom: 1.2rem;
    font-size: 1.8rem;
    color: rgb(51, 51, 51);
  }

  &__program-occupation {
    font-size: 1.6rem;
    color: #5d6168;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin-bottom: 1rem;
  }

  &__education-bio-icon {
    margin-right: 8px;
  }

  &__education,
  &__bio {
    text-align: left;
    margin-bottom: 2rem;
    font-size: 1.6rem;
    color: #5d6168;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  .programs__stars {
    width: 70%;

    * {
      width: 100%;
    }
  }
}

@include tablet-portrait {
  .programs {
    &__heading-info {
      width: 85%;
      margin: 0 auto 3rem;
    }

    &__program-cards {
      flex-direction: unset;
      justify-content: center;
    }

    &__program-card {
      margin: 2rem 1.5rem;
      margin-bottom: 17px;
    }
  }
}

@include desktop {
  .programs {
    padding-top: 80px;
    padding-bottom: 17rem;

    &__heading {
      padding-bottom: 3rem;
      padding-top: 4rem;
    }

    &__program-cards {
      flex-direction: row;
      justify-content: center;
    }

    &__program-card {
      margin: 0 23px;
      margin-bottom: 17px;
    }

    &__heading-info {
      margin-bottom: 80px;
    }
  }
}

@media (max-width: 467px) {
  .programs {
    padding: 1rem !important;

    &__program-card {
      margin-top: 0rem;
    }

    &__lesson-container {
      margin-left: 10px;
    }
  }
}
