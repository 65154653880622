.tab-fixed {
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  
  padding: 10px;
 
  top: 70px;
  left: -230px;
  opacity: 0;
  max-height: 900px;
  width: 180px;
  pointer-events: none;

  &--visible {
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    pointer-events: auto;
  
   
    transition: opacity 0.2s ease-in-out, transform 0.5s ease-in-out;
    transform: translateY(-3px);
    opacity: 1;
   
    background-color: white;
  }
}

.tab-filter {

  position: sticky;
  padding: 2rem;
  min-width: 20rem;

  &__left-close {
    color: #142e4a;
  }

  &__grade,
  &__subject {
    margin-bottom: 2rem;
  }

  &__doc-type {
    border-bottom: 1px solid #eff1f5;
    margin: 3rem auto 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
  }

  &--overlay {
    .tab-filter__heading {
      color: #5d6169;
    }
  }

  &__heading {
    font-size: 1.8rem;
    color: #000000;
    letter-spacing: -0.3px;
    line-height: 3.3rem;
  }

  &__filter-link-wrapper {
    padding-left: 0;
    margin-right: 2rem;
    width: max-content;
  }

  &__filter-option {
    font-family: "Fellix-SemiBold";
    font-size: 1.5rem;
    line-height: 2.8rem;
    letter-spacing: -0.28px;
    color: #5d6169;
    cursor: pointer;
    text-align: left;
    background-color: transparent;
    transition: 0.2s ease-in-out;

    &:hover {
      color: #142e4a;
      text-decoration: underline;
    }


    &--doc-type-active {
      border-bottom: 2px solid #142e4a;
    }

    &--active {
      color: #142e4a !important;
      font-weight: bold !important;
      text-decoration: underline;
    }

    &--doctype {
      margin-left: 1rem;
    }
  }

  &__sub-categories {
    padding-left: 2rem;
  }

  &__option {
    margin-bottom: 1rem;
    color: #000000;
    background-color: transparent;
  }

  &__filters {
    display: flex;
    flex-direction: column;

    &--doctype {
      margin-bottom: 0;
    }
  }

  &__close-icon {
    height: 1rem;
    margin-left: 0.5rem;
    cursor: pointer;
    display: inline;
    transition: all 1s linear;

    &--hidden {
      display: none;
    }
  }

  &__flex-wrapper {
    display: flex;
    overflow-y: hidden;
  }
}

// ! tab filter tablet styles

@media (max-width: 1023px) {
  .tab-fixed {
    position: relative;
    overflow-y: scroll;
    overflow-x: scroll;
    padding: 10px;
    z-index: 1000;
    left: 0px;
    margin: auto;
    opacity: 1;
    max-height: 700px;
    max-width: 95%;
    background-color: white;
    width: auto;
    pointer-events: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    transform: translateY(-70px);
  
    &--visible {
      border-radius: 12px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
      pointer-events: auto;
      transition: opacity 0.2s ease-in-out, transform 0.5s ease-in-out;
      transform: translateY(-70px);
      opacity: 1;  
      background-color: white;
    }
  }
  .tab-filter {
    margin: auto;
  }
}

@include tablet-portrait {


  .tab-filter {
    padding: 0;
    width: 90%;
 
    margin-bottom: 2rem;

    &__doc-type {
      width: 35%;

      // ! using a regular selector inside bem here because creating all the filters is part of a reusable component, so it's hard to distinguish between doc type filters and the others. if any other ideas are out there, would be glad to hear them
      .tab-filter__filter-link-wrapper {
        margin-right: 0;
      }
    }
  }
}

@include big-tablet {
  .tab-filter {
    &__doc-type {
      width: 25%;
    }
  }
}

@media (min-width: 1024px) {
  .tab-filter {
    width: auto;

    // &--active-filter {
    //   padding-top: 7.5rem;
    // }

    &__flex-wrapper {
      flex-direction: column;
    }

    &__doc-type {
      margin-bottom: 0;
      width: 22%;
    }
  }
}

@include big-desktop {
  .tab-filter {
    &__doc-type {
      width: 14%;
    }
  }
}

@include mobile {
  .tab-fixed {
    position: relative;
    overflow-y: scroll;
    overflow-x: scroll;
    padding: 0px 10px;
    z-index: 1000;
    margin: auto;
    opacity: 1;
    max-height: 430px;
    margin-bottom: 40px;
    max-width: 95%;
    background-color: white;
    width: auto;
    pointer-events: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    transform: translateY(-70px);
  
    &--visible {
      border-radius: 12px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
      pointer-events: auto;
      transition: opacity 0.2s ease-in-out, transform 0.5s ease-in-out;
      transform: translateY(-70px);
      opacity: 1;  
      background-color: white;
    }
  }
  .tab-filter {
    &__filter-option {
      &--active {
        font-family: "Fellix-Bold";
        border: 2px solid #142e4a;
        padding: 8px 12px !important;
      }
    }
  }
}