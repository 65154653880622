.premium {
  position: relative;

  &__benefits-list {
    list-style: none; // Remove default list styling
    padding: 1rem; // Remove default padding
    margin: 0; // Add some margin to the top and bottom
    text-align: left;
    
    li {
      display: flex;
      align-items: left;
      margin-bottom: 1rem; // Add some space between list items
      font-family: "Fellix-SemiBold";
      font-size: 1.6rem;
      line-height: 1.5;

  
    }
  }

  // ! hero ========================================================================
  &__hero {
    padding: 4rem 2rem;
    color: #fff;
    background: linear-gradient(-90deg, #133a88, #103c94, #133a88);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__hero-sub-heading {
   
    font-size: 1.8rem;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 18px;
    width: fit-content;
    padding: 1rem 3rem;
    font-family: "Fellix-Bold";
    margin: 3rem 2rem;
   
  }

  &__hero-heading {
    font-size: 3rem;
  }

  &__credit-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
  }

  // ! cards ============================================================
  &__cards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;
  }

  &__card {
    margin: 0 0 4rem 0;
    padding: 2.5rem 2rem;
    text-align: center;
    height: auto; // Changed from a fixed height to auto
    width: 100%; // Make it responsive
    max-width: 30rem; // Max-width for larger screens
    border: 1px solid #dfdfdf;
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease; // Smooth transition for hover effect
  
    &:hover {
      transform: translateY(-5px); // Slightly raise the card on hover
      box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.25); // Enhance shadow for a "lifting" effect
    }
  
    @media(max-width: 768px) {
      // Adjust styles for tablet and below
      margin: 2rem; // Give some space around for smaller screens
    }
  }

  &__card-heading {
    font-size: 2.6rem;
    margin-bottom: 2rem;
  }

  &__card-price {
    font-size: 4rem;
    color: #051057;
    margin-bottom: 1.3rem;
    font-family: "Fellix-Bold";
  }

  &__card-price-month {
    font-size: 2.2rem;
  }

  &__original-price {
    font-size: 2rem;
    color: #5d6169;
    font-weight: lighter;
    text-decoration: line-through;
    margin-bottom: 2rem;
    font-family: "Fellix-Bold";
  }

  &__subscription-benefit {
    color: #5e6169;
    font-size: 1.8rem;
    margin-bottom: 4rem;
    line-height: 3rem;
    font-family: "Fellix-SemiBold";
  }

  &__select-btn {
    @include nav-btn;
    background: #051057;
    padding: 2rem 0;
    border: none;
    height: unset;
    width: 22rem;
    color: white;
    transition: 0.2s ease-in-out;
    font-family: "Fellix-Bold";

    &:hover {
 
        transform: scaleX(1.07) scaleY(1.07);
     
        font-family: "Fellix-Bold";
     
      
        cursor: pointer;
      
    }
  }

  // ! FAQ ============================================================
  &__faq {
    padding: 0 3rem 6rem;
    margin: 2rem 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__faq-heading {
    font-size: 3.6rem;
    margin-bottom: 3.6rem;
    text-align: center;
  }

  &__faq-qa-wrapper {
    margin-bottom: 3rem;
  }

  &__faq-question {
    font-size: 2.6rem;
    line-height: 5.4rem;
    margin-bottom: 1.1rem;
  }

  &__faq-answer {
    font-size: 1.8rem;
    letter-spacing: -0.3px;
    line-height: 2.8rem;
    color: #5d6169;
  }
}

// ! premium page media queries ================================================
@include tablet-portrait {
  .premium {
    &__cards-wrapper {
      padding: 5rem 10rem;
    }

    &__hero {
      padding: 2rem 5rem;
    }

    &__hero-sub-heading {
      margin-left: 1rem;
      margin-right: 1rem;
      
    }

    &__faq {
      padding: 0 6rem 6rem;
    }
  }
}

@include big-tablet {
  .premium {
    &__hero {
      padding: 2rem 5rem;
    }

    &__credit-wrapper {
      flex-direction: row;
    }

    &__cards-wrapper {
      flex-direction: row;
      justify-content: center;
    }

    &__card {
      margin: 0 3rem;
    }
  }
}

@include desktop {
  .premium {
    &__cards-wrapper {
      padding: 5rem 20rem;
    }

    &__faq {
      padding: 6rem 15rem5;
    }
  }
}

@include big-desktop {
  .premium {
    &__hero {
      padding: 2rem 10rem;
    }

    &__cards-wrapper {
      padding: 5rem 35rem;
    }

    &__faq {
      padding: 6rem 25rem;
    }
  }
}

// ! successfully subscribed page ===============================================================
.subscribed-page {
  border-bottom: 1px solid #e2e2e2;

  &__content-wrapper {
    padding: 0 2rem 6rem;
    text-align: center;
  }

  &__success-message {
    font-size: 1.75rem;
  }

  &__worksheet-page-link {
    margin-top: 2rem;
    font-size: 1.5rem;
    color: $gray-a;

    i {
      margin-right: 0.5rem;
    }
  }

  &__checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 5rem auto;
    box-shadow: inset 0px 0px 0px $main-blue;
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
  }

  &__checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: $main-blue;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  &__checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
}

// ! keyframes for check mark animation ==================================================
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $main-blue;
  }
}

// ! media queries for subscribed page ==================================================
@include desktop {
  .subscribed-page {
    // border: 1px solid red;
  }
}
