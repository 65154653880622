.hide-scrollbar {
  overflow-y: scroll;

  overflow-x: hidden;
}

.hide-scrollbar::-webkit-scrollbar {
  background-color: white;

  border-radius: 10px;

  width: 20px;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  //   background: linear-gradient(-180deg, #f26931, #f15b49, #ee3f7a); //main gradient, just north-south
  //   background-color: $main-blue; //Blue in case we want it to pop more?
  background-color: $gray-g; //Grey scrollable to minimize focus on it but still notify users it's there.

  border-radius: 10px;

  border: 5px solid white;
}

.hide-scrollbar::-webkit-scrollbar-thumb:vertical {
  height: 40px !important;

  width: 20px;
}
