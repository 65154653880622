.card.hide,
.hide-shadow {
  box-shadow: none;
}

.card {
  background-color: #142e4a;
  height: 25rem;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(77, 83, 85, 0.43) !important;
  cursor: pointer;
  width: 22rem;
  box-shadow: $box-shadow;
  transition: 0.2s ease-in-out;

  &:hover{
    transform: translateY(-3px);
    box-shadow: 0 2px 10px rgba(77, 83, 85, 0.93) !important;
  }

  &--worksheets-widget {
    box-shadow: 0 2px 10px 0 rgba(77, 83, 85, 0.43) !important;
  }

  &--worksheets-widget:hover {
    box-shadow: 0 2px 10px 0 rgba(77, 83, 85, 0.93) !important;
  }

  &__wrapper {
    width: 21rem;
    margin-right: 20px;
  }

  &__image {
    width: 100%;
    height: 16rem;
    overflow: hidden;

    img {
      min-width: 100%;
      max-width: 100%;
      min-height: 100px;
    }
  }

  .hide-image {
    opacity: 0.2;
  }

  &__content-wrapper {
    padding: 1rem 1rem 0.5rem;
    text-align: left;
    background-color: rgb(68, 83, 255) 53%;
    border-top: 1px solid rgb(124, 124, 124);
    box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.5);
    // border-top: 1px solid $gray-g;
    &__see-more {
      display: flex;
      flex-direction: column;
      padding: 2rem;
      align-items: center;
      margin-top: 5rem;
      text-transform: uppercase;
      font-weight: bold;

      width: 20rem;
      img {
        width: 25%;
        margin-top: -5%;
      }
    }
  }

  &__info {
    background-color: rgb(255, 252, 242);
    box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.5);
    border-top: 0px solid #142e4a;
    // box-shadow: inset 0px 2px 0px rgba(203, 219, 223, 43%);
  }

  &__subject {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    margin-bottom: 1rem;

    &--english {
      color: #d7561e;
    }

    &--math {
      color: #d238bb;
    }

    &--reading {
      color: #19b3ca;
    }

    &--writing {
      color: #4f4ff7;
    }
  }

  &__info-download-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 1.4rem;
    color: black;
    margin: 0.2rem 0;
    height: 2.9rem;
  }

  &__grade {
    color: black;
    font-size: 1.2rem;
  }

  &__download-icon-wrapper {
    .icon-image {
      width: 20px;

      img {
        max-width: 100%;
      }
    }
  }
}

.card:hover {
  box-shadow: 0 2px 10px 0 rgba(77, 83, 85, 0.43);
  .see-more-arrow {
    animation: sideways-bounce 2s infinite;
  }
}

@keyframes sideways-bounce {
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}
