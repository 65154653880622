.reset-password {
  &__content-wrapper {
    padding: 5rem 2rem;
    text-align: center;
  }

  &__heading {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  &__sub-heading {
    font-size: 1rem;
    font-style: italic;
    margin-bottom: 4rem;
  }

  &__reset-form {
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__input {
    margin-bottom: 2rem;
    height: 5rem;
    border: 1px solid #d7dae0;
    width: 100%;
  }

  &__update-button {
    @include cta-btn;
    height: 5rem;
    width: 22rem;
    margin: 0 auto;
  }

  &__return-to-account {
    color: #4d7bd7;
    margin-top: 2rem;
    font-style: italic;
    cursor: pointer;
  }
}

// ! reset-password media queries ========================================
@include tablet-portrait {
  .reset-password {
    &__reset-form {
      padding: 0 10rem;
    }
  }
}

@include big-tablet {
  .reset-password {
    &__heading {
      font-size: 3.5rem;
    }

    &__sub-heading {
      font-size: 1.5rem;
    }

    &__reset-form {
      padding: 0 25rem;
    }
  }
}

@include desktop {
  .reset-password {
    &__reset-form {
      padding: 0 35rem;
    }
  }
}

@include big-desktop {
  .reset-password {
    &__reset-form {
      padding: 0 55rem;
    }
  }
}