.internal-links {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto 4rem;

  &__header-wrapper {
    display: flex;
    margin-bottom: 1rem;
  }

  &__name {
    color: #000;
  }

  &__worksheets-wrapper {
    cursor: pointer;
  }

  &__link-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

// ! media queries ========================================================
@include tablet-portrait {
  .internal-links {
    width: 55%;
  }
}

@include desktop {
  .internal-links {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1rem;

    &__name {
      margin-bottom: 0.5rem;
    }

    &__pages-wrapper {
      margin-top: 2rem;
    }

    &__link-wrapper {
      align-items: flex-start;
    }

    &__header {
      font-size: 1.75rem;
    }
  }

  .blog-page__side-content--mobile .internal-links {
    flex-direction: row;
  }
}
