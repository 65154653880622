.hero {
  width: 100%;
  padding: 7rem 2rem;
  color: #fff;
  // // background-position: 70% 0;
  background-repeat: no-repeat;
  background-size: cover;
  height: 46.7rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  position: relative;

  .homepage__banner--image {
    width: 100%;
    height: auto;
    position: absolute;
    left: -15%;
    z-index: 0;
    filter: brightness(70%);

    * {
      height: 100%;
    }
  }

  &__text-wrapper {
    z-index: 1;
  }

  &__header {
    font-size: 3.5rem;
    margin-top: 0;
  }

  &__sub-header {
    font-size: 2.3rem;
    margin-bottom: 2rem;
  }

  &__cta-btn {
    @include cta-btn;
    font-size: 1.9rem;
    padding: 1.8rem 2rem 1.8rem 2.5rem;
    display: block;
  }
}

// ! media queries ======================================================================
@include tablet-portrait {
  .hero {
    padding: 20rem 8rem;
    align-items: flex-start;

    &__header {
      width: 100%;
      font-size: 3.5rem;
      margin: 0 0 3rem 0;
      text-shadow: 2px 2px 2px black;
    }

    &__sub-header {
      margin-bottom: 4rem;
    }

    .homepage__banner--image {
      left: 0;
      height: auto;
      min-width: 100%;
      min-height: 100%;

      * {
        min-width: 100%;
        min-height: 100%;
      }
      // height: 45rem !important;
    }
  }
}

@include tablet-landscape-width {
  .hero {
    .homepage__banner--image {
      * {
        width: 100%;
        height: auto;
      }
    }
  }
}

@include desktop {
  .hero {
    padding: 12rem 5rem;
    // height: 57rem;
    align-items: flex-start;

    &__text-wrapper {
      width: 60%;
      margin-left: 10.3rem;
    }

    &__header {
      width: 100%;
      margin-top: 2rem;
      font-size: 5rem;
      width: 80%;
    }

    &__sub-header {
      width: 80%;
      margin-bottom: 4.5rem;
    }

    .homepage__banner--image {
      width: 100%;
      height: unset;
      position: absolute;
      left: 0;
      z-index: 0;

      * {
        height: unset;
        width: 100%;
      }
    }
  }
}

@include big-desktop {
  .hero {
    &__header {
      margin-top: 4rem;
    }
  }
}
