canvas {
    width: 100%;
    height: 100%;
  }

  .undo-redo-buttons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .undo-redo-buttons button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .undo-redo-buttons button:hover {
    background-color: #000000;
    color: #ffffff;
  }
  
  .undo-redo-buttons button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  

  .clear-canvas-button,
.fill-color-button,
.eraser-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.clear-canvas-button {
  background-color: #FF4136;
}

.fill-color-button {
  background-color: #2ECC40;
  margin-left: 10px;
}

.eraser-button {
  background-color: #AAAAAA;
  margin-left: 10px;
}

  .color-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

  }
  
  @media (max-width: 480px) {
    .color-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .color-square {
    width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 50%;
  cursor: pointer;
  }

.ArtOff {

   

    &__create {
        background-color: #ff13df;
        border: none;
        color: white;
        padding:10px 34px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 8px 0px;
        cursor: pointer;
        border-radius: 8px;
        transition-duration: 0.4s;
        font-family: "Fellix-Bold";

        &:hover {
            background-color: #e900b2;
            color: white;
        }
    }

    &__waiting {
        background-color: #ff13df;
        border: none;
        color: white;
        padding:10px 34px;
        text-align: center;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 430px;
        font-size: 16px;
        margin: 20px auto 0px;
        cursor: pointer;
        border-radius: 8px;
        transition-duration: 0.4s;
        font-family: "Fellix-Bold";

        &:hover {
            background-color: #e900b2;
            color: white;
        }
    }

    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 30px 30px 0px;
        margin: 20px 0px 0px 20px;
        border-radius: 12px;
        border: 3px solid  #ff13df;
        background-color: #fff;
        
        transition: transform 0.2s ease-in-out;
      
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0.05) 50%,
          rgba(255, 0, 251, 0.09) 50%,
          rgba(255, 0, 204, 0.14)
        );
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15), 0 0 8px rgba(0, 0, 0, 0.2);
    

    }

 

    &__logo img {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100px;
      }


      &__-or- {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding-bottom: 12px;
        font-weight: bold;
        color: rgb(121, 121, 121);
    }

    &__row-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        font-family: "Fellix-SemiBold";

        &--bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: flex-end;
            font-family: "Fellix-SemiBold";
           
        }

      

        &--bottom-1 {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: flex-end;
            font-family: "Fellix-SemiBold";
            max-width: 900px;
            margin: auto;
        }

        &--arrows {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            min-width: 320px;
            max-width: 320px;
            font-family: "Fellix-SemiBold";
            left: -100px;
        }
    }

    &__between-arrows {
        min-width: 260px;
        margin: auto;
    }

    &__hat-off {
        margin-left: 70px;
    }

    &__eyes-off {
        margin-left: 80px;
    }

    &__hat-off-2 {
        margin-left: 17px;
    }

    &__eyes-off-2 {
        margin-left: 30px;
    }


    &__nickname-wrapper {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: left;
        font-family: "Fellix-SemiBold";

    }

    &__label {
        font-family: "Fellix-SemiBold";
        margin-bottom: 7px;
    }

    &__custom-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
        padding: 40px;
        max-width: 500px;
        border-radius: 12px;
        margin: 20px auto;
        box-shadow: 0px 2px 10px #333;
        background-color: #fff;
        border: 2px solid  #ff13df;
    }

    &__join {
        background-color: #5177ff;
        border: none;
        color: white;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 8px 0px;
        cursor: pointer;
        border-radius: 8px;
        transition-duration: 0.4s;
        font-family: "Fellix-Bold";

        &:hover {
            background-color: #3b64e8;
            color: white;
        }

    }

    &__character {
        width: 100px;
        margin: auto;
        margin-top: 50px;
    }

    &__custom--label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.7rem;
        font-weight: bold;
        text-align: center;
        margin: 20px auto -10px;
    }

    &__players--label {
        display: flex;
        align-items: center;
        font-size: 1.7rem;
        font-weight: bold;
        margin-bottom: 1rem;
        width: 75%;
        margin: 20px auto -10px;
    }

    &__players--label-span {
        margin-left: 0.5rem;
        font-size: 1.6rem;
        font-weight: normal;
        color: #777;
    }

    &__arrow-select {
        color: #fff;
        padding: 12px 20px;
        border-radius: 30px;
        cursor: pointer;
        background-color: #2d8fff;
        border: none;
        outline: none;
        transition: all 0.3s ease-in-out;
        font-family: "Fellix-Bold";
        font-size: 2rem;
        }
        
        &__arrow-select:hover {
        background-color: #0077ff;
        }
        
        &__arrow-select:active {
        background-color: #2d8fff;
        }
        
        

    &__shape {
        position: relative;
        transform: translateX(-30px);
        
    }

 

    &__hat {
        position: absolute;
        top: -30px;
        
    }

    &__eyes {
        position: absolute;
        top: 50px;
        
    }

    &__color {
        position: absolute;
        bottom: -10px;
        
    }

    &__random-word {
      font-size: 3rem;
      letter-spacing: 4px;
      font-family: "Fellix-Bold";
    }
    


    &__draw-canvas {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 800px;
        width: 50%;
        height: 500px;
        min-height: 500px;
        overflow: hidden;
        cursor: crosshair;
      }

    &__game-container {
        height: 100vh;
        display: flex;
        flex-direction: row;
    }

    &__character--list {
        display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 2px 10px #333;
 
  border: 3px solid  #ff13df;
  
    }

    &__character--card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 240px;
        height: 240px;
        padding: 20px;
        margin: 20px;
        border-radius: 12px;
        background-color: #fff;
        box-shadow: 0px 2px 10px #333;
        transition: transform 0.2s ease-in-out;
      
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0.05) 50%,
          rgba(0, 0, 0, 0.05) 50%,
          rgba(0, 0, 0, 0.1)
        );
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15), 0 0 8px rgba(0, 0, 0, 0.2);
    
    }

    

    &__character--username {
        margin: 0;
        font-size: 1.8rem;
        font-weight: bold;
        text-align: center;
    }


    &__guess--container {
        height: 100%;
        box-shadow: 0px 2px 10px #333;
        width: 30%;
        margin: 5px;
    }



    &__character--place-list {    
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        box-shadow: 0px 2px 10px #333;
        width: 20%;
        margin: 5px;
        height: 100%;
    }
    &__character--row {    
        padding: 10px;
        box-shadow: 0px 2px 10px #333;
    }

    &__character--game {    
        width: 20px;
        margin: auto;
        
    }

    &__middle-game-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.ArtOff__chat--container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-height: 500px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f5f5f5;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .ArtOff__chat--correctGuess {
    background-color: rgb(44, 184, 119) !important;
    padding: 7px 12px;
    border-radius: 7px;
    color: white !important;
    font-family: "Fellix-Bold";
 
    
  }
  
  .ArtOff__chat--messages {
    overflow-y: scroll;
    height: calc(100% - 50px);
    padding: 10px;
  }
  
  .ArtOff__chat--message {
    margin-bottom: 10px;

    &--correct {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
  
  .ArtOff__chat--sender {
    font-weight: bold;
    color: #222;
  }
  
  .ArtOff__chat--content {
    color: #444;
  }
  
  .ArtOff__chat--form {
    display: flex;
    padding: 5px;
    background-color: #fff;
    border-top: 1px solid #ccc;
  }
  
  .ArtOff__chat--form input {
    flex-grow: 1;
    border: none;
    outline: none;
    padding: 5px 10px;
    font-size: 14px;
  }
  
  .ArtOff__chat--form button {
    background-color: #ff13df;
    color: #fff;
    border: none;
    outline: none;
    padding: 5px 15px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 3px;
    transition: background-color 0.3s;
  }
  
  .ArtOff__chat--form button:hover {
    background-color: #0056b3;
  }




.settings-form {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: auto;
  gap: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem;
  border: 3px solid  #ff13df;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-title {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1.5rem;
}

.form-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-label {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-input,
.form-select {
  font-size: 1.2rem;
  padding: 0.5rem;
  border-radius: 4px;
  border: 2px solid #ccc;
}

.form-input:focus,
.form-select:focus {
  outline: none;
  border-color: #000;
}

.form-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.6667 1.33337L8.00004 6.00004L1.33337 1.33337' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
}
  





  .ArtOff__room-code-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .ArtOff__room-code-text {
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .ArtOff__room-code {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid  #ff13df;
    border-radius: 10px;
    font-size: 24px;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .ArtOff__room-code:hover {
    background-color: #eee;
  }
  
  .ArtOff__room-code-copied {
    font-size: 14px;
    color:#ff13df;
    margin-left: 5px;
  }
  
  
  



  
  
  
  
  