@import './partials/variables';
@import './partials/mixins';
@import './partials/header';
@import './partials/headerRefactor';
@import './partials/links';
@import './partials/worksheetAssessments';
@import './partials/tabFilter';
@import './partials/resources';
@import './partials/banner';
@import './partials/search';
@import './partials/card';
@import './partials/modal';
@import './partials/signinmodal';
@import './partials/sharing';
@import './partials/form';
@import './partials/errors';
@import './partials/premium';
@import './partials/footer';
@import './partials/bookForm';
@import './partials/hero';
@import './partials/tutors';
@import './partials/programs';
@import './partials/googleReview';
@import './partials/ourPricing';
@import './partials/backhome';
@import './partials//worksheetSEO';
@import './partials//scrollbar';
@import './partials/worksheetsWidget';
@import './partials/consultationWidget';
@import './partials/selfCheckoutWidget';
@import './partials/checkoutForm';
@import './partials/simpleCheckout';
@import './partials/sideContent';
@import './partials/searchInput';
@import './partials/dice';
@import './partials/questions';
@import './partials/whiteboard';
@import './pages/home';
@import './pages/math';
@import './pages/english';
@import './pages/reading';
@import './pages/writing';
@import './pages/science';
@import './pages/language';
@import './pages/testPrep';
@import './pages/blog';
@import './pages/404Page';
@import './pages/account';
@import './partials/resetPassword';
@import './pages/downloadsPage';
@import './pages/singleWorksheet';
@import './pages/locations';
@import './pages/success';
@import './pages/guessTheWord';
@import './pages/balloons';
@import './pages/memoryGame';
@import './pages/rollAndRead';
@import './pages/artOff';
@import './pages/racingGame';
@import './pages/aiTutor';

@font-face {
  font-family: 'QuickStarter';
  src: url('../Fonts/QuickStarter.woff') format('woff');
}

@font-face {
  font-family: 'Fellix-Thin';
  src: url('../Fonts/Fellix-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Fellix-Thin';
  src: url('../Fonts/Fellix-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Fellix-Regular';
  src: url('../Fonts/Fellix-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Fellix-Medium';
  src: url('../Fonts/Fellix-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Fellix-SemiBold';
  src: url('../Fonts/Fellix-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Fellix-Bold';
  src: url('../Fonts/Fellix-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Fellix-ExtraBold';
  src: url('../Fonts/Fellix-ExtraBold.woff') format('woff');
}

html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

.modal-open {
  overflow-y: hidden;
  height: 100vh;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

button:disabled,
button[disabled],
button[disabled='disabled'] {
  background: none !important;
  background-color: lightgray !important;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: '';
  clear: both;
  height: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-family;

  a {
    text-decoration: none;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

*:focus,
*:visited,
*:active,
*:hover {
  outline: 0 !important;
}

*::-moz-focus-inner {
  border: 0;
}

html {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  // reset font size to 10px
  font-size: 1.4rem;
  color: $primary-text-color;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

button {
  border: none;
  color: #ffffff;
  text-align: center;
  // transition: background 250ms ease-in-out, transform 150ms ease;
  // -webkit-appearance: none;
  // -moz-appearance: none;
}

button:hover,
button:focus {
  cursor: pointer;
}

a {
  text-decoration: none;
}

button {
  a {
    color: #ffffff;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}

.container {
  width: $container-width;
  margin: 0 auto;
}
