.downloads {
  width: 95%;
  margin: 0 auto;
  // position: relative;

  &__search {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__header {
    margin-bottom: 3.7rem;
  }

  .search-bar {
    margin-bottom: 4rem;
  }

  &__worksheets,
  &__assessments {
    font-size: 1.8rem;
    letter-spacing: -0.3px;
    color: #5d6169;
    padding-bottom: 0.8rem;
    background-color: transparent;

    &--active {
      color: #000000;
      font-weight: 500;
      border-bottom: 3px solid #f18659;
    }
  }

  &__worksheets {
    margin-right: 5.8rem;
  }

  .cards-container {

    &__card-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    &__close-icon {
      height: 1rem;

      &--filter {
        margin-bottom: 0.4rem;
        cursor: pointer;
      }

      &--filter-hidden {
        visibility: hidden;
        height: 1rem;
      }
    }

    &__grades-filter-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 14rem;
    }

    &__tags {
      color: #fff;
      background-color: #4d7bd7;
      width: fit-content;
      border-radius: 1.6rem;
      padding: 0.6rem 1.9rem;
      display: flex;
      align-items: center;
      margin-right: 2rem;
    }
  }
}