.account {
  padding: 5rem 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content-wrapper {
    text-align: center;
    padding: 5rem 2rem;
  }

  &__heading {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  &__sub-heading {
    font-size: 1rem;
    font-style: italic;
    margin-bottom: 4rem;
  }

  &__form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 3rem;
  }

  &__success-span {
    padding-top: 4rem;
    display: block;
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }

  &__header-container {
    text-align: center;
  }

  &__header {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  &__sub-header {
    font-size: 1.4rem;
    font-style: italic;
    margin-bottom: 4rem;
  }

  &__input {
    margin-bottom: 2rem;
    height: 5rem;
    width: 100%;
    border: 1px solid #d7dae0;
  }

  &__reset-link {
    font-size: 1rem;
    margin-top: -1rem;
    margin-bottom: 2rem;
    text-decoration: none;
    color: #4d7bd7;
    align-self: flex-start;
    font-style: italic;
    cursor: pointer;
  }

  &__delete-account-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__button {
    &--update {
      margin-left: 0;
      width: 100%;
      @include cta-btn;
      height: 5rem;
    }

    &--delete {
      height: 5rem;
      width: 22rem;
      margin-top: 2rem;
      margin-bottom: 6rem;
      background-color: transparent;
      border: none;
      cursor: pointer;

      &:hover {
        color: #fff;
      }
    }
  }

  &__confirm-delete-wrapper {
    text-align: center;
    margin: -3rem 0 3rem;
  }

  &__confirm-delete-text {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-style: italic;
  }

  &__confirm-delete-button {
    &--yes {
      border: none;
      border: 1px solid red;
      font-size: 1.8rem;
      font-weight: bold;
      border-radius: 6px;
      color: red;
      height: 5rem;
      width: 12rem;
    }

    &--no {
      background-color: transparent;
      border: none;
      height: 5rem;
      width: 12rem;
    }
  }
}

// ! account media queries ====================================================
@include tablet-portrait {
  .account {
    padding: 5rem 10rem 0;
  }
}

@include big-tablet {
  .account {
    padding: 5rem 25rem 0;

    &__header {
      font-size: 3.5rem;
    }

    &__sub-header {
      font-size: 1.5rem;
    }
  }
}

@include desktop {
  .account {
    padding: 5rem 35rem 0;
  }
}

@include big-desktop {
  .account {
    padding: 5rem 55rem 0;
  }
}