.single-worksheet__wrapper {
  border-top: 1px solid#e9e9e9;
  width: 100%;
}

.single-worksheet {
  padding: 4rem 1rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid#e9e9e9;

  &__type {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 5px;
    color: #5d6169;
  }

  &__heading-share-wrapper {
    .sharing {
      margin: 0 auto 2rem;
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
    font-size: 3.5rem;
    line-height: 1.64;
    letter-spacing: -0.7px;
    color: #000000;
    margin-bottom: 0;
  }

  &__heading-type-wrapper {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__share-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__type-wrapper {
    margin-bottom: 2rem;
  }

  &__worksheet-image {
    margin: 0 auto;
    // width: 80%;
    width: 35rem;
    margin-bottom: 5rem;
    position: relative;
    pointer-events: none;

    img {
      width: 100%;
    }

    .preview-blocker {
      position: absolute;
      bottom: -5px;
      z-index: 10;
    }
  }

  &__worksheet-info {
    width: 75%;
    margin: 0 auto 1rem;
  }

  &__worksheet-description {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  &__subject-grade-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__newsletter-info {
    font-size: 1rem;
    width: 75%;
    font-style: italic;
  }

  &__download-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .download {
    @include cta-btn;
    margin: 2rem 0 1rem;
    height: 4rem;
    font-size: 1.4rem;
  }

  // ! loading text ========================================================
  &__loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 70vh;
    border-top: 1px solid#e9e9e9;
    border-bottom: 1px solid#e9e9e9;
  }

  // ! Text Block
  &__textBlock-heading {
    width: 80%;
    text-align: center;
    margin: 24px auto;
    font-size: 3.2rem;
  }

  &__textBlock-paragraph,
  &__textBlock-list {
    margin: 0 auto;
    width: 80%;
    align-items: flex-start;
  }

  &__textBlock-paragraph {
    text-indent: 2rem;
    // margin: 12px 0px;
    margin: 12 auto;
    font-size: 2rem;
    line-height: 2.5rem;
  }

  &__textBlock-list {
    margin-left: 6rem;
    padding: 8px 4rem;
    margin: 0 auto;
    width: 80%;
    font-size: 1.7rem;
    line-height: 2.3rem;
  }
}

.single-worksheet.worksheet_text_block {
  align-items: flex-start;
  background-color: $gray-i;
}

// ! single worksheet media queries ============================================
@include tablet-portrait {
  .single-worksheet {
    &__heading-wrapper {
      width: 80%;
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__heading {
      margin-top: 0;
    }

    &__heading-type-wrapper {
      align-items: flex-start;
    }

    &__subject-grade-wrapper {
      justify-content: flex-start;
    }

    &__subject {
      margin-right: 1rem;
    }

    &__download-button {
      display: unset;
    }
  }
}

@include big-tablet {
  .single-worksheet {
    &__heading-wrapper {
      width: 75%;
    }

    &__worksheet-description,
    &__subject,
    &__grade {
      font-size: 2rem;
    }

    .download {
      height: 5rem;
    }

    &__newsletter-info {
      font-size: 1.5rem;
    }
  }
}

@include desktop {
  .single-worksheet {
    padding: 4rem 20rem 8rem;

    &__heading-wrapper {
      width: 97%;
    }

    &__worksheet-content {
      display: flex;
    }

    &__worksheet-image {
      margin-right: 3rem;
    }

    &__worksheet-description {
      font-size: 2rem;
    }

    &__worksheet-info {
      margin-top: 2rem;
    }

    &__subject-grade-wrapper {
      font-size: 2rem;
      flex-direction: column;
      align-items: flex-start;
    }

    &__subject {
      margin-bottom: 1rem;
    }

    &__newsletter-info {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    //   // ! Text Block
    // &__textBlock-heading {
    //   margin: 16px auto;
    //   font-size: 2.7rem;
    // }

    // &__textBlock-paragraph,
    // &__textBlock-list {
    //   align-items: flex-start;
    // }

    // &__textBlock-paragraph {
    //   text-indent: 2rem;
    //   margin: 12px 0px;
    //   font-size: 1.7rem;
    //   line-height: 2rem;
    // }

    // &__textBlock-list {
    //   margin-left: 6rem;
    //   padding: 4px 0;
    //   // margin: 8px auto;
    // }
  }
}

@include big-desktop {
  .single-worksheet {
    padding: 4rem 30rem 8rem;
  }
}
