.worksheets-widget {
  background-color: hsl(193, 100%, 94%);
  margin: 5% -150%;
  // margin: 5% -10%;
  // width: 100vw;

  &__sub-heading {
    font-weight: 500;
    background: linear-gradient(-90deg, #f26931, #f15b49, #ee3f7a);
    color: #ffffff !important;
    padding: 1rem;
    border-radius: 4px;
    margin-top: 15%;
  }

  &__cards-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 80rem;
    overflow: auto !important;
    width: 25%;
    padding: 1rem;
  }

  &__card-wrapper {
    cursor: pointer;
    margin: 0 5rem 0 0;
  }
}

@include desktop {
  .worksheets-widget {
    margin-bottom: 0;
    // margin: 5% -42%;
  }
}

@include big-desktop {
  .worksheets-widget {
    margin-bottom: -1%;
    // margin: 5% -62%;

    &__cards-container {
      width: 50%;
    }
  }
}
