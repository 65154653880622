.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 3.5rem;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 655px;
}

.search-bar,
.search-bar-hide {
  display: flex;
  align-items: center;
  background-color: $gray-f;
  width: calc(100% - 440px);
  padding-left: 1rem;
  margin-right: 3rem;
}

.search-icon {
  width: 14px;
  max-width: 100%;

  img {
    max-width: 100%;
  }
}

input[name='search'] {
  background-color: $gray-f;
  border: none;
  width: 100%;
  padding-left: 0.7rem;
}

.search-bar,
input,
select,
.reset {
  border-radius: 4px;
}

input,
select,
.reset {
  padding: 0.6rem 1.3rem;
}

input,
select,
.reset {
  height: 35px;
}

.search-bar-hide {
  background-color: $gray-a;
  opacity: 0;
}

.filters {
  select:disabled {
    background-color: $gray-a;
    opacity: 0.05;
  }

  select:disabled+.select-icon {
    opacity: 0.5;
  }

  .reset {
    background-color: transparent;
    color: $main-pink;
    // border: 1px solid $main-pink;
  }

  .reset-hide {
    // display: none;
    background: transparent;
    color: $main-pink;
    opacity: 0.2;
  }

  .reset:hover,
  .reset:focus {
    background-color: $main-pink;
    color: #ffffff;
  }
}

.select-container {
  position: relative;
  color: $gray-d;

  select {
    width: 100%;
    border: 1px solid $gray-e;
    width: 175px;
    margin: 0 0.75rem;
    font-family: $font-family;
    color: $gray-d;
  }

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }
}

.select-icon {
  position: absolute;
  top: 30%;
  right: 10%;
  width: 10px;
  height: 6px;
}

@media (max-width: 992px) {
  .search {
    flex-direction: column;
    align-items: center;
  }

  .search-bar {
    width: 612px;
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 670px) {
  .search {
    align-items: flex-start;
  }

  .search-bar {
    width: 275px;
  }

  .filters {
    flex-direction: column;
    align-items: flex-start;

    select {
      width: 225px;
      margin-bottom: 1rem;
      margin-left: 0;
    }

    .reset {
      margin-top: 1rem;
    }

    .select-icon {
      top: 25%;
      right: 8%;
    }

    .subjects-dropdown {
      margin-bottom: 0;
    }
  }
}