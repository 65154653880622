.dropdown-container,
.responsive-dropdown-container {
  position: relative;
}

.dropdown-container:hover .dropdown-content,
.responsive-dropdown-container:hover .dropdown-content,
.responsive-dropdown-content.active { // Add your active class selector if needed
  opacity: 1; // Fully visible
  transform: translateY(0); // At its starting position
  visibility:visible;

  
}

.nav-link,
.nav-dropdown {
  display: flex;
  align-items: center;
  margin-right: 30px;

  overflow: hidden;
  cursor: pointer;
}

.nav-link,
.nav-dropdown a {
  // color: $nav-link-color;
  color: #5d5c6d;
  font-size: 1.4rem;
  font-weight: 700;

  // margin-top: 0.5rem;
}

.nav-link:hover,
.nav-link:focus,
.nav-dropdown a:hover,
.nav-dropdown a:focus,
.responsive-nav-link:hover,
.responsive-nav-link:focus,
.responsive-nav-dropdown a:hover,
.responsive-nav-dropdown a:focus {
  color: #e77579;
}

.nav-dropdown a,
.responsive-nav-dropdown a {
  margin-right: 12px;
}

.dropdown-content {
  position: absolute;
  top: 16px;
  left: -30%;
  transform: translateY(0px); // Slightly above its starting position
  padding: 0px;
  height: 0px;
  width: auto; /* Adjust width as needed */
  display: flex;
  flex-direction: row;
  justify-content: center; /* Centering items horizontally */
  align-items: flex-start; /* Aligning items to the top */
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  opacity: 0; // Fully transparent
  z-index: 11;
  background: #f0f4f8; /* Lighter background for better readability */
  border-radius: 4px;
  visibility: hidden;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Softer shadow for subtlety */
  border: 1px solid #8c98a7; /* Add a border for defined edges */
  transition: opacity 0.2s ease-in,visibility 0.2s ease-in,  transform 0.2s ease-in, height 0.2s ease-in, width 0.2s ease-in, padding 0.2s ease-in,;// Animation for both properties

  

  a {
    height: 40px;
  }
}



.nested {
  margin-left: 20px;
  height: 20px !important;
  width: 200px !important;
}

.dropdown-container:hover .dropdown-content {
  height: 305px;

    display: flex;
    flex-direction: row;
    justify-content: center; /* Centering items horizontally */
    align-items: flex-start; /* Aligning items to the top */
    padding: 10px;
    width: auto; /* Adjust width as needed */
    min-width: 600px; /* Ensure it's wide enough */
  
  
  
}

.dropdown-separator {
  height: 100%; /* Full height of the dropdown */
  width: 1px; /* Thin line */
  background-color: #ccc; /* Line color */
  margin: 0 10px; /* Add some horizontal space around the line */
}


/* General style for nested links */
.nested-links {
  display: grid;
  gap: 1px;
  height: 20px !important;
  width: 143px !important;
}

.dropdown-group {
  display: flex;
  flex-direction: column;
  margin: 0 10px; /* Space between Canada and USA sections */
}

.dropdown-group.canada {
  border-right: 1px solid #ccc; /* Separator as a border */
  padding-right: 10px; /* Space next to the border */
}

.dropdown-group.usa {
  padding-left: 10px; /* Space next to the border */
}


@media (max-width: 600px) {
  .dropdown-content {
    flex-direction: column;
  }

  .dropdown-group.canada {
    border-right: none;
    border-bottom: 1px solid #ccc; /* Horizontal separator for small screens */
    padding-right: 0;
    padding-bottom: 40px;
  }

  .dropdown-group.usa {
    padding-left: 0;
    padding-top: 10px;
  }
}


// !<--------------- media queries --------------->
// @media (max-width: 992px) {
@media (max-width: 1023px) {
  // redesign header
  .responsive-nav {
    // .responsive-nav-dropdown,
    // a,
    // i {
    //   margin-top: 2rem;
    //   padding-bottom: 2rem;
    // }

    .nav-link,
    .responsive-nav-link,
    .responsive-nav-dropdown a {
      // height: 35px;
    }
  }

  // .responsive-nav.active {
  //   height: 100vh;
  //   height: 100%;
  //   transition: all 0.6s;
  //   -o-transition: all 0.6s;
  //   -webkit-transition: all 0.6s;
  //   -moz-transition: all 0.6s;
  // }

  .nav-link,
  .responsive-nav-link,
  .responsive-nav-dropdown a {
    color: $gray-a;
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: none;
  }

  // .responsive-nav-dropdown,
  .responsive-dropdown-content,
  .nav-link {
    // padding: 0 8%;
    margin-right: 0;
  }

  .nav-link,
  .responsive-dropdown-content {
    border-bottom: 1px solid $gray-h;
  }

  .responsive-nav-dropdown,
  .responsive-nav-dropdown a,
  .responsive-nav-link {
    display: flex;
    align-items: center;
    // height: 35px;
  }

  .responsive-nav-link.nested {
    font-size: 1.6rem;
    padding-left: 20px;
  }

  #phones .responsive-nav-dropdown,
  #phones .responsive-nav-dropdown a {
    color: $main-blue;
  }

  .responsive-dropdown-content {
    display: flex;
    flex-direction: column;
   

    .nav-link,
    .responsive-nav-link {
      margin: 0.5rem 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    :first-child {
      margin-top: 0;
    }
  }

  .responsive-dropdown-content:not(.active) {
    height: 0;
  }

  .responsive-dropdown-content:not(.active),
  .responsive-dropdown-content.active {
    overflow: scroll;
    
  }

  .responsive-dropdown-content:not(.active),
  .responsive-dropdown-content.active {
    transition: all 0.4s;
    -o-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
  }

  .dropdown-group.elementary {
    height: 200px;
  }

  .dropdown-group.highschool {
    height: 285px;
  }
  .dropdown-group.language {
    height: 355px;
  }

    .dropdown-group.testprep {
    height: 140px;
  }

  .dropdown-group.canada {
    height: 270px;
  }
  .dropdown-group.usa {
    height: 350px;
  }

  #subjects .responsive-dropdown-content.active {
    //! this height needs to be the calculated height of all items in the dropdown * the individual height of each dropdown item, in this case 35px * 7 items = 245px + margins / padding
    height: 315px;
  }

  #phones .responsive-dropdown-content.active {
    //! this height needs to be the calculated height of all items in the dropdown * the individual height of each dropdown item
    height: 140px;
  }
}




