.isa_info,
.isa_success,
.isa_warning,
.isa_error {
  display: flex;
  flex-direction: row;
  align-items: center;
  // height: $default-button-height;
  font-family: $font-family;
  // margin: 1rem 0;
  // padding: $label-margin-top $input-padding-left;
  // border-radius: $input-radius;
  cursor: pointer;
  padding-right: 3rem;
}

.isa_info {
  color: #00529b;
  background-color: #bde5f8;
  z-index: 10;
}

.isa_success {
  color: #4f8a10;
  background-color: #c9f8be;
  z-index: 10;
}

.isa_warning {
  color: #ca8516;
  background-color: #fdf7ed;
  // to cover save button on render
  z-index: 10;
}

.isa_error {
  color: $error;
  background-color: #ffd2d2;
  z-index: 10;
}

.isa_info i,
.isa_success i,
.isa_warning i,
.isa_error i {
  margin: 1rem;
  font-size: 1.3rem;
  vertical-align: middle;
}

.warning-container {
  display: flex;
  flex-direction: column;
}

.warning-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.error {
  display: flex;
  // justify-content: center;
  align-items: center;
  padding: 0 1rem 1rem 0rem;
}

.modal form .error {
  padding-top: 1.5rem;
  padding-bottom: 0;
  width: 100%;
}

.error-span {
  color: $error;
}

.success-span {
  color: $success;
}

.form form .error {
  margin-right: 1rem;
}

@media (max-width: 800px) {
  .error {
    justify-content: flex-start;
  }
}

// ! download history
.download-history {
  &__error-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 5rem;
  }

  &__return-home {
    margin-top: 1rem;
    display: block;
  }
}
