.footer {
  padding: 4rem 2rem;
  // background-image: $reverse-gradient;
  background: linear-gradient(-90deg, #133a88, #103c94, #133a88);
  color: #ffffff;
  font-weight: 700;

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  // ! newsletter and subscription wrapper ========================================
  &__left-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__logo {
    width: 35rem;
    margin-bottom: 1rem;

    img {
      width: 50%;
    }
  }

  &__newsletter {
    padding: 2rem;
    width: 75%;
    min-width: 250px;
  }

  &__newsletter-message {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  &__subscribe-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    padding: 6px 6px 6px 10px;
    border: 1px solid $gray-e;
    border-radius: 7px;
  }

  &__email-input {
    width: 82%;
    border-radius: 3px;
    border: none;
    padding: 1px 2px;
    background-color: transparent;
    color: white;

    &::placeholder {
      color: #ffffff;
    }
  }

  &__subscribe-button {
    background-color: transparent;
    width: 27px;
    height: 27px;
    border-radius: 4px;
  }

  // ! newsletter subscription media queries  ========================================
  @include tablet-portrait {
    &__newsletter {
      width: 50%;
    }
  }

  // ! navigation links wrapper ========================================
  &__nav-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
    margin: 5rem 0;

    .hide-image {
      opacity: 0.2;
    }
  }

  &__nav-links-wrapper,
  &__policies-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__nav-links-wrapper {
    margin-bottom: 3rem;
  }

  &__nav-header {
    text-transform: uppercase;
    letter-spacing: 2px;
    border-bottom: 1px solid #fff;
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;
    font-size: 1.8rem;
  }

  &__nav-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__nav-item {
    color: #fff;
    margin-bottom: 0.75rem;
    cursor: pointer;
  }

  // ! nav links wrapper media queries ========================================
  @include big-tablet {
    padding: 8rem 8rem 4rem;

    &__wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;
      align-items: flex-start;
    }

    &__nav-container {
      margin-top: 0;
    }
  }

  @include big-desktop {
    &__nav-container {
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
    }
  }

  // ! social icons and numbers ========================================
  &__phone-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__social-link-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__social-icon-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 0 0.5rem;
    border-radius: 10%;

    i {
      color: #ffffff;
      font-size: 3rem;
    }
  }
}


.footer-chat-bot {
  position: fixed;
  width: 60px; /* Adjusted size for typical chat icon */
  height: 60px; /* Adjusted size for typical chat icon */
  background-color: #112d65; /* A more friendly chat color */
  z-index: 100000;
display: flex;
flex-direction: column;
align-items: center;
color: white;
font-size:2.3rem;
justify-content: center;
  border-radius: 50%; /* Circle shape */
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2); /* Soft shadow for floating effect */
  z-index: 100000;
  right: 10px; /* Adjust position as needed */
  bottom: 10px; /* Adjust position as needed */
  cursor: pointer; /* Indicate the link is clickable */
  transition: background-color 0.3;

}