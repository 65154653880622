.error404 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem;

  &__img {
    max-height: 65vh;
    width: 70%;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  &__error-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__header {
    font-size: 2.5rem;
    margin-top: 5rem;
  }

  &__subheader {
    margin: 3rem 0;
    font-size: 2rem;
  }

  &__return-home-button {
    @include cta-btn;
  }
}



// ! media queries ========================================================
@include tablet-portrait {
  .error404 {
    padding: 3rem 5rem;

    &__img {
      width: 50%;
    }
  }
}

@include desktop {
  .error404 {
    flex-direction: row;
    align-items: center;
    padding: 3rem 8rem;

    &__img {
      width: 30%;
    }

    &__error-text-wrapper {
      width: 50%;
    }
  }
}

// @media (max-width: 800px) {
//   header {
//     display: none;
//   }

//   .error404 {
//     flex-direction: column;
//     align-items: center;
//     width: 100%;

//     .error-text {
//       align-items: center;

//       h3 {
//         font-size: 3rem;
//         text-align: center;
//       }

//       p {
//         font-size: $p-font;
//         text-align: center;
//       }
//     }
//   }
// }