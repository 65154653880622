.roll-and-read {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__card-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 120px;
    height: 120px;
    // border: 2px solid #fff;
    border-radius: 7px;
    background-color: white;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.53);
    margin: 2px 4px;
    z-index: 10;

    &:hover {
      cursor: pointer;
    }

    &--img {
      width: 75px;
      height: 75px;
    }
  }

  @media screen and (max-width: 500px) {
    &__card {
      position: relative;
      width: 100px;
      height: 100px;
    }
  }
}
