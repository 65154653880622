
.canvas-wrapper {
  position: relative;
  width: 100%;
  height: 500px;
cursor: pointer;
  background-image: url(https://nft-image-pool.s3.us-east-2.amazonaws.com/worksheets/balloons-game-bg.png);
}
.rotate-pencil {
  background-color: red;
}
.cursor {
  position: absolute;
  height: 70px;
  transform: translate(-160px, -160px);
  pointer-events: none;
  z-index: 2;
  transition: 0.3s transform ease;
  transform-origin: 100% 100%;
}


.instructions-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-image: linear-gradient(to bottom right, rgb(52, 54, 71), rgb(9, 12, 71));
  color: white;
  text-align: center;
  padding: 20px 10px 10px;
  font-size: 20px;
  font-family: "Fellix-Bold";
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.instructions-banner.game-over {
  background-image: linear-gradient(to bottom right, #7c0303, #ac1008);
}

.instructions-banner__text {
  font-family: "Fellix-Bold";
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.1);
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.instructions-banner__text:hover {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}



.canvas {

  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.collision-canvas {
  opacity: 0;
}
.myclass {
  background-color: black;
}


.balloon--bg { margin:20px; background:hsl(70, 31%, 85%); text-align:center; }

.balloon {
    display:flex;
    align-items: center;
    justify-content: center;
     width:120px;
    height:145px;
    background:hsl(215,50%,65%);
    border-radius:80%;
    position:relative;
    box-shadow:inset -10px -10px 0 rgba(0,0,0,0.07);
    margin:20px 30px;
    transition:transform 0.5s ease;
    z-index:10;
    animation:balloons 4s ease-in-out infinite;
    transform-origin:bottom center;
    color: white;
    font-size: 3rem;
    font-weight: 700;

  }
  
  @keyframes balloons {
    0%,100%{ transform:translateY(0) rotate(-4deg); }
    50%{ transform:translateY(-25px) rotate(4deg); }
  }
  
  
  .balloon:before {
    content:"▲";
    font-size:20px;
    color:hsl(215,30%,50%);
    display:block;
    text-align:center;
    width:100%;
    position:absolute;
    bottom:-12px;
    z-index:-100;
  }
  
//   .balloon:after {
//    display:inline-block; top:153px;
//     position:absolute;
//     height:80px;
//     width: 2px;
//     margin:0 auto;
//     content:"";
//     background:rgba(0,0,0,0.2); 
//   }
  
  .balloon:nth-child(2){ background:hsl(245,40%,65%); animation-duration:3.5s; }
  .balloon:nth-child(2):before { color:hsl(245,40%,65%);  }
  
  .balloon:nth-child(3){ background:hsl(139,50%,60%); animation-duration:3s; }
  .balloon:nth-child(3):before { color:hsl(139,30%,50%);  }
  
  .balloon:nth-child(4){ background:hsl(59,50%,58%); animation-duration:4.5s; }
  .balloon:nth-child(4):before { color:hsl(59,30%,52%);  }
  
  .balloon:nth-child(5){ background:hsl(23,55%,57%); animation-duration:5s; }
  .balloon:nth-child(5):before { color:hsl(23,44%,46%);  }
  
  