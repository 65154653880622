.search-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
  margin-bottom: 1.2rem;
  width: 250px;
  background-color: white;
  font-family: 'Fellix' !important;
  border-radius: 4px;
  padding: 0px 12px;
  justify-content: flex-start;
  border: 2px solid black;
  border-radius: 4px;

  input {
    background-color: white;
    border: none;
    margin-left: 7px;
    padding: 10px 8px;
    font-family: 'Fellix' !important;
    font-size: 1.2rem;

    &:focus {
      outline: none;
      box-shadow: 0 5px 15px rgba(255, 255, 255, 0.4);
      padding: 12px 8px;

      font-family: 'Fellix' !important;
    }
  }
}
