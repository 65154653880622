// ! fonts ================================================
$font-family: 'DM Sans', sans-serif;
$primary-text-color: #1c1c1e;
$secondary-text-color: #ffffff;
$nav-link-color: #383838;

//! font sizes ================================================
$header-font-s: 1.4rem;
$header-font-m: 1.8rem;
$header-font-l: 2rem;

//! banner font sizes ================================================
$banner-font: 6rem;
$author-font: 1.6rem;

//! blog font sizes ================================================
$blog-title: 1.6rem;
$h2-font: 3rem;
$h3-font: 2.4rem;
$p-font: 1.8rem;

//! buttons font size ================================================
$button-font-size: 1.8rem;

// ! color scheme ========================================================
$dark-blue: #162947;
$main-blue: #133a88;
$main-pink: #dc659c;
$main-orange: #f18659;
$main-yellow: #f18558;
$card-bg: #fafeff;

// ! gradients ================================================================
// $main-gradient: linear-gradient(-90deg, #dc659c 0%, #f18659 100%);
$main-gradient: linear-gradient(-90deg, #133a88, #103c94, #133a88);
$reverse-gradient: linear-gradient(-90deg, #133a88, #103c94, #133a88);
$disabled-gradient: linear-gradient(90deg, hsla(210, 84%, 65%, 0.7) 0%, hsla(223, 63%, 63%, 0.7) 100%);

// ! grays ========================================================
$gray-a: #1c1c1e;
$gray-b: #868695;
$gray-c: #5d6169;
$gray-d: #5c626a;
$gray-e: #e4e7eb;
$gray-f: #f6f7f9;
$gray-g: #d8dadf;
$gray-h: #d7dae0;
$gray-i: #eff3f7;
$gray-icon: #efefef;

// ! success or error colour scheme ================================================
$success: #4f8a10;
$error: #ec2626;

// ! box shadow ========================================================
$box-shadow: 0 2px 10px 0 rgba(203, 219, 223, 43%);

// ! container width ================================================
$container-width: 84%;
