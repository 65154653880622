.canada-location-page,
.bc-location-page,
.ontario-location-page,
.usa-location-page,
.cali-location-page,
.florida-location-page {
  &__content-wrapper {
    padding: 4rem 2rem;
    position: relative;
  }

  &__content-img {
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 7px;
  }

  &__heading-content-wrapper {
    margin-bottom: 2rem;
  }

  &__heading {
    margin-bottom: 1rem;
  }

  &__content-text {
    line-height: 2.5rem;
    font-size: 1.5rem;
  }

  &__coming-soon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    margin: 5rem 0;
  }
}

// ! media queries ================================================
@include tablet-portrait {
  .canada-location-page,
  .bc-location-page,
  .ontario-location-page,
  .usa-location-page,
  .cali-location-page,
  .florida-location-page {
    &__content-wrapper {
      padding: 4rem;
    }

    &__content-img {
      width: 75%;
      display: block;
      margin: 0 auto 3rem;
    }
  }
}

@include big-tablet {
  .canada-location-page,
  .bc-location-page,
  .ontario-location-page,
  .usa-location-page,
  .cali-location-page,
  .florida-location-page {
    &__content-wrapper {
      padding: 5rem;
    }

    &__flex-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 4rem;

      &:nth-child(4) {
        flex-direction: row-reverse;
        margin-left: 0;
        margin-right: 2rem;
      }
    }
  }
}

@include desktop {
  .canada-location-page,
  .bc-location-page,
  .ontario-location-page,
  .usa-location-page,
  .cali-location-page,
  .florida-location-page {
    &__content-wrapper {
      padding: 5rem 14%;
    }

    &__content-img {
      width: 60%;
    }
  }
}

@include big-desktop {
  .canada-location-page,
  .bc-location-page,
  .ontario-location-page,
  .usa-location-page,
  .cali-location-page,
  .florida-location-page {
    // &__content-wrapper {
    //   padding: 10rem 18rem;
    // }

    &__content-img {
      width: 50%;
    }
  }
}

.location-subjects-links {
  background-color: white; /* Light grey background */
  padding: 60px 0;
  
  border-radius: 8px;
}

.location-subjects-links.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.subjects-title {
  color: #333;
  font-size: 24px;
  text-align: center;
  margin-bottom: 30px;
}

.subjects-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.subject-link {
  background-color: #162947; /* Primary blue */
  color: #ffffff;
  width: 184px;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;

  /* Adjustments for hover effect */
  &:hover {
    background-color: #071935; /* Darker blue on hover */
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.73);
  }
}

.location-cities-links {
  background-color: #f8f9fa; /* Light grey background */
  padding: 40px 0;
  margin-bottom: 20px;
  border-radius: 8px;
}

.location-cities-links.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;

}

.cities-title {
  color: #333;
  font-size: 24px;
  text-align: center;
  margin-bottom: 30px;
}

.cities-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.city-link {
  background-color: #162947; /* Primary blue */
  color: #ffffff;
  width: 160px;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;

  /* Adjustments for hover effect */
  &:hover {
    background-color: #071935; /* Darker blue on hover */
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.73);
  }
}

.location-online-review-widget {
  /* Custom styles for the review widget on Ontario page */
  background-color: #eff3f7;

  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 20px 0;
}
.city-online-review-widget {
  /* Custom styles for the review widget on Ontario page */
  background-color: #eff3f7;
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 0;
}

.city-location-page__flex-wrapper {
  padding: 10px 30px;
}


.city-location-page__section {
  margin: 3rem;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.city-location-page__section:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.city-location-page__header {
  margin-bottom: 1rem;
}

.city-location-page__heading {
  color: #333;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.city-location-page__article {
  color: #555;
}

.city-location-page__content {
  font-size: 16px;
  line-height: 1.6;
}
