// ! ======================== overlay =========================
input[type='radio'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #133a88;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #133a88;
  border-radius: 50%;
  transform: translateY(-0.035em);
  display: grid;
  place-content: center;
}
input[type='radio']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #133a88;
}
input[type='radio']:checked::before {
  transform: scale(1);
}

.main-container {
  position: relative;
}

.main-overlay,
.overlay {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-image: linear-gradient(to bottom right, rgb(54, 79, 101), rgb(79, 87, 93));
  z-index: 10000;
}

.main-overlay {
  opacity: 0.7;
  position: fixed;
  height: 200vh !important;
  transform: translateY(-300px);
  background-image: linear-gradient(to bottom right, rgb(54, 79, 101), rgb(79, 87, 93));
  z-index: 10000;
}

.overlay {
  opacity: 0.7;
  background-image: linear-gradient(to bottom right, rgb(54, 79, 101), rgb(79, 87, 93));
  z-index: 10000;
  min-height: 150vh;

  .reset {
    display: none !important;
  }
}

.transparent {
  opacity: 0;
  z-index: -10;
}

// ! modal =================================================
.modal {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 8px;
  position: fixed;
  padding: 3rem 1.5rem 3rem 3rem;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: $box-shadow;
  z-index: 1000000000;

  &--dark-bg {
    position: fixed;
    height: 100vh;
    width: 100vh;
    background-color: black;
    z-index: 10000;
  }

  &__warning-text {
    text-align: center;
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 1.5rem;
  }

  &__type-close {
    display: flex;
    flex-direction: row-reverse;

    position: relative;
    width: 100%;
    margin-bottom: 1rem;

    span {
      cursor: pointer;
      padding: 10px;
    }

    i {
      cursor: pointer;
    }
  }

  &__doc-type {
    color: $gray-b;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__heading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1rem 0;

    // ! non BEM nesting here because of the use of the share icon reusable component
    .sharing {
      padding: 0;
      margin: 0;

      .icon {
        height: 3rem;
        width: auto;
      }
    }
  }

  &__title {
    text-align: center;
    font-size: 1.75rem;
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__modal-image {
    width: 50%;
    border: 0.1rem solid $gray-e;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 2rem;
    pointer-events: none;

    img {
      width: 100%;
    }
  }

  &__description {
    margin-bottom: 2rem;
    line-height: 1.75rem;
    max-height: 7rem;
    overflow-y: scroll;
  }

  &__categories {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto 2rem;
  }

  &__download-btn-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  &__download {
    @include cta-btn;

    &--input {
      width: 22rem;
      border-radius: 4px;
      padding: 1.2rem 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 3rem 0 1rem;
    }

    &--download-document-button,
    &--document-page-button {
      font-size: 1.5rem;
      height: 4rem;
    }

    &--download-document-button {
      @include cta-btn;
      height: 5rem !important;
    }

    &--border-wrap {
      width: 22rem;
      height: 5rem;
      border-radius: 4px;
      padding: 1rem;
      position: relative;
      background: $reverse-gradient;
      padding: 3px;
    }

    &--document-page-button {
      position: absolute;
      top: 0.35rem;
      left: 0.35rem;
      background-color: #fff;
      width: 21.25rem;
      height: 4.25rem;
      border-radius: 4px;
      color: $main-orange;
      font-weight: 700;

      &:hover {
        background-color: transparent;
        color: #fff;
        transition: all 150ms linear;
      }
    }
  }

  &__newsletter-notice {
    margin-top: 2rem;
    font-style: italic;
    font-size: 1.3rem;
    text-align: center;
  }
}

//! modal media queries =================================================
@include tablet-portrait {
  .overlay {
    width: 100vw;
  }

  .modal {
    &__heading-wrapper {
      flex-direction: row;
      justify-content: space-between;

      .sharing {
        justify-content: unset;
        width: auto;
      }
    }

    &__title {
      font-size: 2.6rem;
      text-align: left;
    }

    &__content-container {
      flex-direction: row;
      align-items: flex-start;
      padding-top: 3rem;
    }

    &__modal-image {
      width: 50%;
      margin-right: 3rem;
      position: relative;

      .preview-blocker {
        width: 100%;
        position: absolute;
        bottom: -5px;
        z-index: 10;
      }
    }

    &__text-container {
      width: 50%;
    }

    &__description {
      line-height: 2.5rem;
      max-height: 10rem;
      font-size: 1.5rem;
    }

    &__categories {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0;
    }

    &__categories-subject,
    &__categories-grade {
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }

    &__download-btn-wrapper {
      justify-content: flex-start;
    }

    &__download {
      margin: 0;
    }
  }
}

@include tablet-landscape-sm {
  .modal {
    // height: 90vh;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

@include big-tablet {
  .modal {
    &--create-account {
      width: 60rem;
    }
  }
}

@include desktop {
  .modal {
    width: 50rem;

    &__heading-wrapper {
      .sharing {
        position: unset;
        flex-direction: row;
      }
    }

    &--create-account {
      width: 60rem;
    }
  }
}

// ! sign in modal =================================================
.sign-in-modal {
  padding: 3rem 3rem 5rem;
  width: 100vw;
  left: 0;
  margin: 0 auto;
  height: auto;

  &__type-close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
    margin-top: -20px;

    span {
      cursor: pointer;
      padding: 10px;
    }

    i {
      cursor: pointer;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__header {
    margin-bottom: 2rem;

    &--create-account {
      text-align: center;
    }
  }

  &__input {
    margin-bottom: 1rem;
    width: 100%;
    height: 5rem;
    border: 1px solid #d7dae0;
  }

  &__forgot-password {
    font-size: 1.4rem;
    text-decoration: underline;
    font-family: "Fellix-SemiBold";
    align-self: flex-start;
    margin-right: 0.75rem;
    color: #4d7bd7;
    cursor: pointer;
  }

  &__sign-in-btn {
    margin: 2rem 0;
    background-color: #133a88;
    font-weight: 700;
    width: 20.8rem;
    height: 5rem;
    min-height: 35px;
    border-radius: 4px;

    transition: 0.2s transform ease-in-out;
    box-shadow: 1px 2px 9px #868b95;
    &:hover {
      transform: translateY(-1px);
    }
  }

  &__create-account {
    font-size: 1.8rem;
    margin: 2rem 0 3rem;
  }

  &__create-account-input {
    padding: 2rem;
    font-family: 'DM Sans';
    font-weight: 800;

    &::placeholder {
      font-family: 'DM Sans';
      font-weight: 800;
    }
  }

  &__create-account-link {
    font-size: 1.8rem;
    color: #4d7bd7;
   font-family: "Fellix-Bold";
   text-decoration: underline;
    cursor: pointer;
  }

  &__separator-text {
    width: 100%;
    text-align: center;
    color: #e8eaee;
    border-bottom: 1px solid #e8eaee;
    line-height: 0.1rem;
    margin: 1rem 0 4rem;

    span {
      background: #fff;
      padding: 0 1rem;
    }
  }

  &__social-sign-in-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__facebook-login {
    height: 5rem;
    padding: 1.2rem;
    border-radius: 5px;
    background-color: #3c5997;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    margin-bottom: 2rem;

    i {
      margin-right: 1.2rem;
      font-size: 2rem;
    }
  }

  &__google-login {
    height: 5rem;
    padding: 1.2rem;
    border-radius: 5px;
    background-color: #4385f4;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;

    i {
      margin-right: 1.2rem;
      font-size: 2rem;
    }
  }

  // ! sign in modal media queries ==================================
  @include tablet-portrait {
    width: 100vw;
    margin: 0 auto;
    overflow-y: scroll;
    max-height: calc(100vh - 100px);

    &__social-sign-in-wrapper {
      flex-direction: row;
    }

    &__facebook-login {
      margin-bottom: 0;
      margin-right: 1.2rem;
    }

    &__form {
      padding: 0 2rem;
    }

    &__sign-in-btn {
      width: 26.8rem;
    }
  }

  @include big-tablet {
    &__form {
      padding: 0 4rem;
    }
  }

  @include desktop {
    width: 75vw;
    margin: 0 auto;
    left: calc(25vw / 2);
    padding: 3rem 5rem 5rem;

    &__header {
      font-size: 3rem;
    }
  }

  @include big-desktop {
    width: 40vw;
    left: 30vw;
  }

  // ! forgot password modal =================================================
  &__forgot-password-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__forgot-password-reset {
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }

  &__back-to-sign-in {
    margin-top: 1rem;
    font-style: italic;
    font-size: 1.5rem;
    cursor: pointer;
    color: #4d7bd7;

    &--create-account {
      margin-top: 0;
      margin-right: 1rem;
    }
  }

  // ! forgot password media queries =================================================
  @include tablet-portrait {
    &__forgot-password-header {
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  @include desktop {
    &__forgot-password-wrapper {
      padding: 0 4rem;
      justify-content: center;
    }
  }

  // ! create account modal ==========================================
  &__create-account-header {
    margin-bottom: 0;
    margin-top: 0;
  }

  &__create-account-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__create-account-input {
    margin-bottom: 1rem;
    border: 1px solid #d7dae0;
    width: 100%;

    &:focus {
      border: 1px solid #787878;
    }
  }

  &__checkbox-wrapper {
    margin: 1rem auto;

    text-align: center;
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    padding: 14px 20px;
    width: 300px;
    box-shadow: 0px 2px 9px #b0c0cd;
  }

  &__checkbox-options {
    margin-top: 12px;

    margin-right: -10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
  }

  &__radio-wrapper {
    display: flex;
    align-items: center;
    margin-right: 2rem;
    font-family: 'DM Sans';
    font-weight: 500;
    color: rgb(54, 79, 101);

    input {
      margin-right: 0.5rem;
    }
  }

  &__flex-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__sign-in-btn {
    &--create-account {
      align-self: center;
    }
  }

  &__already-member {
    font-size: 1.8rem;
    color: #868b95;
    margin-right: 0.5rem;
  }

  &__login-link {
    font-size: 1.6rem;
    color: #133a88;

    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  // ! create account media queries ==========================================
  @include big-tablet {
    &__create-account-form {
      padding: 0 3rem;
    }

    &__checkbox-wrapper {
      align-self: flex-start;
    }
  }

  @include desktop {
    &__login-link {
      margin-bottom: 0;
    }
  }
}
@include mobile {
  .modal {
    &__modal-image {
      .preview-blocker {
        width: 42.25%;
        position: absolute;
        top: 226px;
      }
    }
  }
}
