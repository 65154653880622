.guess {
    text-align: center;
    background-color: #121212;
    width: 100vw;
    height: 100vh;
    /* display: flex; */
    color: white;
  }
  
  .guess body {
    padding: 0%;
    margin: 0;
  }
  
  .guess nav {
    height: 60px;
    width: 100%;
    margin: 0;
    border-bottom: 1px solid grey;
    display: grid;
    place-items: center;
  }
  
  .guess nav h1 {
    margin: 0;
    font-family: Helvetica, Arial, sans-serif;
    color: white;
    font-size: 45px;
  }
  
  .word-not-found {
    background-color: white;
    position: absolute;
    top: 20%;
   width: 60%;
   left: 20%;
   padding: 40px 100px;
   padding-bottom: 30px;
   font-family: Helvetica, Arial, sans-serif;
   color: black;
    z-index: 2;
    transition-duration: 1.2s;
    transition: 0.7s ease-in-out;
    border-radius: 12px;
    cursor: pointer;
  
  }
  
  .word-not-found__button {
    border: none;
    background-color: #528d4e;
    padding: 20px 40px;
    margin-top: 30px;
    border-radius: 8px;
    color: white;
    font-weight: 700;
    cursor: pointer;
  }
  .word-not-found__button:hover {
    border: none;
    background-color: #528d4e;
    padding: 20px 40px;
    margin-top: 30px;
    border-radius: 8px;
    color: white;
    font-weight: 700;
  }
  
  .gameOver-refresh {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    border: 2px solid black;
    margin-top: 10px;
    font-family: "Fellix-Bold";
    cursor: pointer;
  }
  
  .gameOver-refresh:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  
  .game {
    width: 100vw;
    height: calc(100vh - 170px);
    display: flex;
    align-items: center;
    padding-top: 20px;
    flex-direction: column;
  }
  
  .board {
    width: 450px;
    height: 550px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
  }
  
  .row {
    flex: 33%;
    display: flex;
    flex-direction: row;
    margin: 5px;
  }
  
  .letter {
    flex: 33%;
    height: 100%;
    border: 1px solid grey;
    margin: 5px;
    display: grid;
    place-items: center;
    font-size: 30px;
    font-weight: bolder;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  #correct {
    background-color: #528d4e;
  }
  
  #almost {
    background-color: #b49f39;
  }
  
  #error {
    background-color: #3a393c;
  }
  
  .keyboard {
    width: 700px;
    height: 300px;
    margin-top: 20px;
  }
  
  .line1 {
    flex: 33%;
    display: flex;
    flex-direction: row;
    display: flex;
    justify-content: center;
    margin: 5px;
  }
  .line2 {
    flex: 33%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5px;
  }
  .line3 {
    flex: 33%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5px;
  }
  
  .key {
    width: 50px;
    height: 70px;
    margin: 5px;
    border-radius: 4px;
    display: grid;
    place-items: center;
    font-size: 20px;
    background-color: grey;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
  }
  
  .green {
    background-color: #528d4e;
  }
  
  #big {
    width: 100px;
  }
  #disabled {
    background-color: #3a393c;
  }
  
  @media only screen and (max-width: 600px) {
   .board {
    width: 100%;
   }
  
   .keyboard {
    width: 100%;
   }
   .key {
    margin: 2px;
    font-size: 16px;
   }
  
   .word-not-found {
    width: 70%;
    padding: 30px;
    left: 8%;
   }
  }

  /* Screen shake animation */
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
  20%, 40%, 60%, 80% { transform: translateX(5px); }
}

.shake {
  animation: shake 0.5s;
}

