.google-review {
  background-image: url('https://nft-image-pool.s3.us-east-2.amazonaws.com/banner/reviewBackground.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  padding: 4rem;
  padding-bottom: 4rem;

  &__review-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__quote-icon {
    margin: 1rem 0;
    font-size: 3.5rem;

    &--right {
      align-self: flex-end;
    }
  }

  &__quote {
    padding: 0 4rem;
    font-size: 2.3rem;
    line-height: 3rem;
  }

  &__review-info-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .google-review__icon {
      height: 5rem;
      margin-right: 2rem;

      img {
        height: 100%;
      }
    }
  }

  &__icon {
    height: 5rem;
    margin-right: 2rem;
  }

  &__info-header {
    font-size: 1.8rem;
  }

  &__info-reviewer {
    font-style: italic;
    font-size: 1.4rem;
    margin: 0.3rem 0;
  }

  &__rating-wrapper {
    display: flex;
    align-items: center;
  }

  &__rating {
    margin-right: 0.5rem;
    font-size: 1.5rem;
    color: #eab254;
  }

  &__rating-stars {
    height: 1rem;
  }
}

@include tablet-portrait {
  .google-review {
    padding: 6rem 5rem;

    &__review-wrapper {
      padding: 2rem 5rem;
    }

    &__review-info-wrapper {
      justify-content: flex-start;
    }

    &__icon {
      padding-left: 9rem;
    }
  }
}

@include tablet-landscape-sm {
  .google-review {

    // padding: 8rem 5rem;
    &__review-wrapper {
      width: 90%;
      margin: 0 auto;
    }
  }
}

@include desktop {
  .google-review {
    padding: 6rem 10rem 10rem 10rem;

    &__review-info-wrapper {
      padding-left: 7rem;
    }
  }
}

@include big-desktop {
  .google-review {
    padding: 6rem 20% 10rem 20%;
  }
}