// ! languages offered 
.languages-offered {
   padding: 6rem 3rem 0;
   display: flex;
   flex-direction: column;
   align-items: center;

   @include desktop {
      flex-direction: row;
      padding: 8rem 8rem 6rem;
   }

   &__img {
      width: 100%;
      margin-bottom: 5rem;

      img {
         width: 100%;
         border-radius: 7px;
      }

      @include tablet-portrait {
         width: 75%;
         margin-bottom: 0;
      }

      @include desktop {
         width: 50%;
         margin-right: 5rem;
      }
   }

   &__language-wrapper {
      @include desktop {
         width: 50%
      }
   }

   &__language {
      &:last-child {
         margin-bottom: -5rem;
      }
   }

   &__heading {
      @include sub-heading;
      font-size: 2.5rem;

      @include desktop {
         margin-top: 0;
      }
   }

   &__summary {
      @include heading-content;
      margin-bottom: 5rem;
   }
}