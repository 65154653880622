.question__canvas-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  margin: 5px;
  font-family: "Fellix-Bold";
}

.question__canvas-button:hover {
  background-color: #2980b9;
}

.question__canvas-button:active {
  background-color: #1f618d;
}




.question {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 2rem auto;
  padding: 3rem;
  background: linear-gradient(45deg, whitesmoke 0%, lightgray 100%);
  border-radius: 10px;
  box-shadow: 1px 5px 10px 0px rgb(78, 88, 125);

  &__whiteboard {
    box-shadow: 3px 5px 10px 0px rgb(78, 88, 125);
    border-radius: 8px;
    border: solid 3px black;
    width: 90%;
    margin: auto;
    margin-bottom: 30px;

  }

  &__title {
    font-weight: bold;
    font-size: 3rem;
    text-align: center;
    margin-top: 2rem;
    font-family: "Fellix-Bold";
  }

  &__color-palette {
    display: flex;
    gap: 10px;
    margin-left: 5%;
  }
  
  &__color-palette__color {
    width: 30px;
    height: 30px;
    border-radius: 8px;
    box-shadow: 0px 2px 5px 0px rgb(78, 88, 125);

    cursor: pointer;
  }
  
&__color-palette__selected {
    border: 2px solid white;
  }
  

  &__score {
   
    font-size: 2rem;

    color: white;

    background: linear-gradient(to right, #0e2052 0%, #11205c 100%);
    /* Use a rounded corner for the box */
    border-radius: 10px;
    /* Add some padding for better spacing */
    padding: 10px;
    width: 170px;
    /* Set font weight to bold */
    font-weight: bold;
    /* Align text to center */
    text-align: center;
    /* Add a box shadow to create depth */
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  }
  

  &__question {
    font-size: 2.3rem;
    font-weight: bold;
    text-align: center;
    max-width: 550px;
    line-height: 4rem;
    margin: auto;
    margin-bottom: 1rem;
    font-family: "Fellix-Bold";
  }
  &__canvas-controls {
    margin-left: 4.3%;
  }

  &__mc {
    text-align: center;
  }

  &__images {
    text-align: center;
    img {
      max-width: 75%;
      border-radius: 8px;
      max-height: 310px;

    }
  }

  &__answer {
    background: linear-gradient(-90deg, #0e2052,  #0e2052);
    box-shadow: 1px 2px 3px 0px rgb(78, 88, 125);
    border: 2px solid white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70px;
    width: 75%;
    margin: 1rem auto;
    padding: 1rem;
    color: white;
    font-weight: bold;
    font-family: "Fellix-Bold";
    font-size: 2.0rem;
    cursor: pointer;
    text-shadow: 2px 2px 2px #1a4953, -1px -1px 1px #193d64, -1px 1px 1px #0f2b5e, 1px -1px 1px #1b2c69;
    &--correct {
      cursor: unset;
      background: linear-gradient(90deg, rgb(63, 177, 118) 100%, rgb(63, 177, 118) 100%);
      
    }
    &--incorrect {
      cursor: unset;
      background: linear-gradient(90deg, rgba(224, 100, 102) 100%, rgb(224, 100, 102) 100%);
    }
    &--other {
      cursor: unset;
    }
  }

  &__results {
    &--answer {
      cursor: unset;
    }

    &--question {
      font-size: 1.9rem;
      border-top: 2px solid rgb(80, 80, 80);
      padding-top: 20px;
      text-align: center;
      margin-top: 2rem;
      font-family: "Fellix-Bold";
    }
  }

  &__next {
    position: absolute;
    right: 10px;
    top: 0px;
    display: flex;
align-items: center;
justify-content: center;
    cursor: pointer;
    background: linear-gradient(45deg, rgb(41, 82, 66) 0%, rgb(5, 81, 67) 100%);
    text-shadow: 2px 2px 2px #1a4953, -1px -1px 1px #193d64, -1px 1px 1px #0f2b5e, 1px -1px 1px #1b2c69;
    color:black;
    font-family: "Fellix-Bold";
    color: white;
    box-shadow: 1px 2px 3px 0px rgb(78, 88, 125);
    font-size: 1.5rem;
    font-weight: bold;
    width: 160px;
    height: 60px;
    text-align: center;
    padding: 8px 12px;
    border: 2px solid white;
    border-radius: 8px;
    margin: 1rem auto;
  }
}
