.back-arrow {
  height: 5rem;
  padding: 1rem 2rem;

  .back-to-page {
    height: 3rem;
    display: flex;
    align-items: center;

    img {
      height: 2rem;
      margin-right: 1.25rem;
    }

    span {
      color: black;
      font-weight: bold;
      font-size: 15px;
    }
  }
}
